import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allPlan = createAsyncThunk("/plan/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.plan.getPlan , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});


export const addPlan = createAsyncThunk("/plan/add" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.plan.addPlan , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const updatePlan = createAsyncThunk("/plan/update" , async (payload , Thunk) =>{
    try{
        const response = await axios.put(endpoints.plan.updatePlan + payload?.planId, payload?.dta)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const deletePlan = createAsyncThunk("/plan/delete" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.plan.deletePlan , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})
