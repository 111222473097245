import React from "react";
import { Button, Form, Pagination, Col, Container, Row } from "react-bootstrap";
import Fade from "react-reveal";

import { Link } from "react-router-dom";
import TableFilter from "./Component/TableFilter";
import TableData from "./Component/TableData";

const FanLoyaltyIndex = (props) => {
  return (
    <Fade>
      <section className="fanLoyalty position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardBox h-100 position-relative bg-white">
                <TableFilter {...props} />
                <TableData {...props} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default FanLoyaltyIndex;
