import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allTrophies = createAsyncThunk("trophies/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.trophies.getAllTrophies , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const deleteTrophy = createAsyncThunk("trophies/delete" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.trophies.deleteTrophy , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

