import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Fade from "react-reveal";
import FanBragsTab from "./Component/TabContent/FanBragsTab/Index";
import TrophiesIndex from "./Component/TabContent/Trophies/Index";

const FanBragsIndex = (props) => {
  return (
    <Fade>
      <section className="fanBrags position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardBox h-100 position-relative bg-white">
                <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
                  <div className="left d-flex align-items-center gap-10 flex-wrap">
                    <div className="">
                      <h2 className="m-0 fw-bold heading">Fan Trophies</h2>
                    </div>
                  </div>
                </div>
                <div className="p-lg-4 p-3 commonTabs">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Trophies"
                  >
                    {/* <Nav variant="pills" className="">
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="FanBrags"
                        >
                          Fan Brags
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="Trophies"
                        >
                          Trophies
                        </Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                    <div className="CardBody py-3">
                      <Tab.Content className="">
                        {/* <Tab.Pane eventKey="FanBrags">
                          <FanBragsTab />
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="Trophies">
                          <TrophiesIndex {...props} />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default FanBragsIndex;
