import { createSlice , current } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { allTrophies ,deleteTrophy } from "./thunk";

const initialState = {
    allTrophies : [] ,
    status : status.IDLE,
    showLoader : false ,
    showButtonLoader : false
}

const slice = createSlice({
    name : "auth",
    initialState : {...initialState} ,
    reducers : {
        remoeAllTrophies : (proxy , action) =>{
            return initialState
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allTrophies.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        });
        builder.addCase(allTrophies.fulfilled , (state,action) =>{
            state.allTrophies = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTrophies.rejected , (state , action) =>{
            state.allTrophies = initialState.allTrophies;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(deleteTrophy.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true;
        });
        builder.addCase(deleteTrophy.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(deleteTrophy.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })
    }
});

export default slice.reducer;
export const {remoeAllTrophies} = slice.actions;