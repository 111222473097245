import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = (props) => {
  const { totalMonthlyRevenue } = props;
  // const monthlyRevenue = totalMonthlyRevenue.sort((a,b) => console.log(a , "d"))
  // Initialize the revenue by month object with default values of 0
  const revenueByMonth = {
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
  };

  // Map the given month numbers to their respective keys in the object
  const monthMapping = {
    1: "jan",
    2: "feb",
    3: "mar",
    4: "apr",
    5: "may",
    6: "jun",
    7: "jul",
    8: "aug",
    9: "sep",
    10: "oct",
    11: "nov",
    12: "dec",
  };

  // Update the revenue values in the object

  if (totalMonthlyRevenue) {
    totalMonthlyRevenue?.forEach((item) => {
      const month = item.month;
      const revenue = item.totalRevenue;
      const monthKey = monthMapping[month];
      revenueByMonth[monthKey] = revenue;
    });
  }

  // Log the resulting object

  const state = {
    series: [
      {
        name: "",
        data: Object.values(revenueByMonth),
      },
    ],
    options: {
      // colors: ['green' , "blue" , "red"],
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 2,
                color: "green", // Color for bars with values 0 to 25
              },
              {
                from: 3,
                to: 8,
                color: "red", // Color for bars with values 26 to 50
              },
              {
                from: 9,
                to: 10,
                color: "#0000FF", // Color for bars with values 51 to 75
              },
              {
                from: 76,
                to: 100,
                color: "#FFFF00", // Color for bars with values 76 to 100
              },
            ],
          },
        },
      },

      toolbar: { show: false },
      animations: {
        enabled: false, // Disable animations
      },
      annotations: {
        points: [
          {
            x: "",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "red",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 200,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "70%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },

      grid: {
        show: false,
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: Object.keys(revenueByMonth),
        // tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.45,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [0, 50, 90],
        },
      },
    },
  };
  return (
    <div id="chart" style={{ padding: "0px !important" }}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={250}
      />
    </div>
  );
};

export default BarChart;
