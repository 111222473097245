import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  getBasicSettings,
  updateBasicSetting,
} from "../../../../redux/states/settings/thunk";
import ButtonLoader from "../../../../elements/ButtonLoader";
import { toast } from "react-toastify";

const TableFilter = (props) => {
  const { filter, setFilter } = props;
  const { basicSetting, showButtonLoader } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      Loyalty_Price: "",
    },
    onSubmit: async function (values, { resetForm }) {
      const data = {
        App_Settings: values,
      };
      dispatch(updateBasicSetting(data))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          dispatch(getBasicSettings());
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      Loyalty_Price: Yup.number().required("Loyality is required"),
    }),
  });

  useEffect(() => {
    formik.setFieldValue(
      "Loyalty_Price",
      basicSetting?.data?.[0]?.App_Settings?.Loyalty_Price
    );
  }, [basicSetting]);

  return (
    <>
      <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
        <div className="left d-flex align-items-center gap-10 flex-wrap">
          <div className="">
            <h2 className="m-0 fw-bold heading">Fan loyalty</h2>
            <p className="m-0 py-2 descp">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div>
        <div className="right">
          <label htmlFor="" className="formLabel fw m-0 pb-1">
            1 Loyalty Point
          </label>
          <div className="cstmShadow cstmInput pe-3 d-flex align-items-center justify-content-between gap-10">
            <div className="position-relative icon-with-text">
              <span className="icn position-absolute">$</span>
              <input
                type="number"
                placeholder="0.5"
                className="form-control h-100 border-0 ps-4"
                value={formik.values.Loyalty_Price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="Loyalty_Price"
                id="Loyalty_Price"
              />
             
            </div>
           

            {showButtonLoader ? (
              <Button className="border-0 " >
                <ButtonLoader color={"text-light"} />
              </Button>
            ) : (
              <Button className="border-0 " onClick={formik.handleSubmit}>
                Submit
              </Button>
            )}
          </div>
          {formik.touched.Loyalty_Price && formik.errors.Loyalty_Price && (
                <span className="text-danger">
                  {formik.errors.Loyalty_Price}
                </span>
              )}
        </div>
      </div>
    </>
  );
};

export default TableFilter;
