import React, { useState } from "react";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import Addsubmin from "./addsubmin";
import Addpermission from "./addpermission";

const Subadminindex = (props) => {
  const [addsubmin, setaddsubmin] = useState(true);
  const togglesubmin = () => {
    setaddsubmin((current) => !current);
  };

  return (
    <section className="Trasactionsindex_section py-3">
      <Container fluid>
        <Addsubmin togglesubmin={togglesubmin} {...props} />
      </Container>
    </section>
  );
};

export default Subadminindex;
