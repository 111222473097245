import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allProducts = createAsyncThunk("/products/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.products.getAllProducts , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const productDetails = createAsyncThunk("/products/getDetails" , async (slug , Thunk) =>{
    try{
        const response = await axios.get(endpoints.products.productDetails + slug)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});


export const addProduct = createAsyncThunk("/products/add" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.products.addProduct , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const updateProduct = createAsyncThunk("/products/update" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.products.updateProduct , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const deleteProduct = createAsyncThunk("/products/delete" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.products.deleteProduct , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const uploadImg = createAsyncThunk("/upload/image" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.upload.uploadImg , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})