import { createSlice } from "@reduxjs/toolkit";
import { allComments, allLikes, allShares, allTips, postDetails } from "./thunk";
import status from "../../../constants/status";

const initialState = {
    selectedPostDetails : {},
    showLoader : false ,
    status : status.IDLE ,
    allLikes : [] ,
    allComments : [] ,
    allShares : [] ,
    allTips : []
}

const slice = createSlice({
    name : "selectedPost",
    initialState : {...initialState},
    reducers : {
        removeSelectedPostsDetails : (state , action) => {
            state.showLoader = false;
            state.selectedPost = {};
        },
        removeAllLikes : (state , action) =>{
            state.showLoader = false;
            state.allLikes = [];
        },
        removeAllComments : (state , action) =>{
            state.showLoader = false;
            state.allComments = [];
        },
        removeAllTips : (state , action) =>{
            state.showLoader = false;
            state.allTips = [];
        },
        removeAllShares : (state , action) =>{
            state.showLoader = false;
            state.allShares = [];
        },
    },
    extraReducers : (builder) =>{
        builder.addCase(postDetails.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(postDetails.fulfilled , (state,action) =>{
            state.selectedPostDetails = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(postDetails.rejected , (state , action) =>{
            state.selectedPostDetails = initialState.selectedPostDetails;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        builder.addCase(allLikes.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allLikes.fulfilled , (state,action) =>{
            state.allLikes = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allLikes.rejected , (state , action) =>{
            state.allLikes = initialState.allLikes;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        builder.addCase(allComments.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allComments.fulfilled , (state,action) =>{
            state.allComments = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allComments.rejected , (state , action) =>{
            state.allComments = initialState.allComments;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        builder.addCase(allShares.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allShares.fulfilled , (state,action) =>{
            state.allShares = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allShares.rejected , (state , action) =>{
            state.allShares = initialState.allShares;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        builder.addCase(allTips.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allTips.fulfilled , (state,action) =>{
            state.allTips = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTips.rejected , (state , action) =>{
            state.allTips = initialState.allTips;
            state.status = status.FAILED;
            state.showLoader = false;
        })
    }
})  

export default slice.reducer;
export const {removeSelectedPostsDetails , removeAllComments , removeAllLikes , removeAllShares , removeAllTips} = slice.actions;