import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const fanLoyalityList = createAsyncThunk("fanLoyality/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.fanLoyality.getFanLoyalityList , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})


export const approveFanLoyality = createAsyncThunk("fanLoyality/approve" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.fanLoyality.updateLoyality , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})


export const declineFanLoyality = createAsyncThunk("fanLoyality/decline" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.fanLoyality.updateLoyality , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})


