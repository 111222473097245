import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const login = createAsyncThunk("auth/login" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.auth.adminLogin , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const forgetPassword = createAsyncThunk("auth/forget" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.auth.forgetPassword , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const resendOtp = createAsyncThunk("auth/resendOtp" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.auth.resendOtp , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const verifyOtp = createAsyncThunk("auth/verifyOtp" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.auth.verifyOtp , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const resetPassword = createAsyncThunk("auth/resetPassword" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.auth.resetPassword , payload);
        return response?.data;
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

