import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { addUser, deleteUser, updateUser, users } from "./thunk";

const tableData = [
    {
      Name: "Sierra Ferguson",
      userName: "@ferguerra",
      email: "test123@mail.com",
      phone: "(99) 436-46-15",
      followers: "10.k",
      RegisterAt: "Apr 24, 2022 ",
      status: "Blocked",
    },
    {
      Name: "Sierra Ferguson",
      userName: "@ferguerra",
      email: "test123@mail.com",
      phone: "(99) 436-46-15",
      followers: "10.k",
      RegisterAt: "Apr 24, 2022 ",
      status: "Blocked",
    },
    {
      Name: "Sierra Ferguson",
      userName: "@ferguerra",
      email: "test123@mail.com",
      phone: "(99) 436-46-15",
      followers: "10.k",
      RegisterAt: "Apr 24, 2022 ",
      status: "Blocked",
    },
    {
      Name: "Sierra Ferguson",
      userName: "@ferguerra",
      email: "test123@mail.com",
      phone: "(99) 436-46-15",
      followers: "10.k",
      RegisterAt: "Apr 24, 2022 ",
      status: "Blocked",
    },
  ];

const initialState = {
    allUsers : [] ,
    status : status.IDLE,
    showLoader : false ,
    selectedUser : {},
    isUpdate : false,
    showButtonLoader : false
}

const slice = createSlice({
    name : "users",
    initialState : {...initialState},
    reducers : {
        removeAllUsers : (proxy , action) =>{
            return initialState
        },
        editUser : (state , action) =>{
          state.selectedUser = action.payload ;
          state.isUpdate = true
        } ,
        clearSelected : (state , action) =>{
          state.selectedUser = initialState.selectedUser
          state.isUpdate = false
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(users.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(users.fulfilled , (state,action) =>{
            state.allUsers = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(users.rejected , (state , action) =>{
            state.allUsers = initialState.allUsers;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(addUser.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
        });
        builder.addCase(addUser.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
        });
    
        builder.addCase(updateUser.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
          state.isUpdate = true;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
          state.isUpdate = false;
          state.selectedUser = {};
        });
        builder.addCase(updateUser.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
          state.isUpdate = false;
          state.selectedUser = {};
        });
    
        builder.addCase(deleteUser.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
        });
    }
});

export default slice.reducer;
export const {removaAllUsers , editUser , clearSelected} = slice.actions;