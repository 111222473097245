import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import User from "../../../images/user.png";
import { Fade } from "react-reveal";
import ButtonLoader from "../../../elements/ButtonLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfile } from "../../../redux/states/profile/thunk";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { uploadImg } from "../../../redux/states/common/thunk";
import { InputGroup, Input, InputGroupText, FormGroup } from "reactstrap";
import ShowIcon from "../../../images/show.png";
import HideIcon from "../../../images/hide.png";

const ProfileComponent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((s) => s.auth);
  const [showPassword, setShowPassword] = useState(false);
  const userId = user?._id;
  const { showButtonLoader, profileDetailsDta } = useSelector(
    (s) => s?.profile
  );
  const formik = useFormik({
    initialValues: {
      userId: userId,
      mobileNumber: "",
      name: "",
      email: "",
      userName: "",
      countryCode: "",
      password: "",
      address: "",
      gender: "",
      profileImage: "",
      bio: "",
      websiteLink: "",
    },
    onSubmit: async function (values, { resetForm }) {
      console.log(values, "balues");
      dispatch(updateProfile(values))
        .unwrap()
        .then((res) => {
          toast.success("Profile updated successfully");
          getAdminProfile();
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required").email("Invalid email"),
      userName: Yup.string().required("User name is required"),
      mobileNumber: Yup.string().required("Mobile number is required"),
    }),
  });

  const getAdminProfile = () => {
    dispatch(getProfile(userId));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImg(formData))
      .unwrap()
      .then((res) => {
        formik.setFieldValue("profileImage", res?.data?.url);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  const handlePhoneNumberChange = (value, country) => {
    formik.setFieldValue("countryCode", "+" + country?.dialCode);
    formik.setFieldValue(
      "mobileNumber",
      value.substring(country?.dialCode.length)
    );
  };

  useEffect(() => {
    if (profileDetailsDta) {
      formik.setValues({
        mobileNumber: profileDetailsDta?.mobileNumber,
        name: profileDetailsDta?.name,
        email: profileDetailsDta?.email,
        userName: profileDetailsDta?.userName,
        countryCode: profileDetailsDta?.countryCode,
        password: "",
        userId: profileDetailsDta?._id,
        address: profileDetailsDta?.address,
        gender: profileDetailsDta?.gender,
        profileImage: profileDetailsDta?.profileImage,
        bio: profileDetailsDta?.bio,
        websiteLink: profileDetailsDta?.websiteLink,
      });
    }
  }, [profileDetailsDta]);

  return (
    <>
      <Fade>
        <section className="Trasactionsindex_section py-3">
          <Container fluid>
            <div className="my-2 col-lg-12">
              <div className="px-lg-5 px-md-4">
                <div className="sectionInner h-100 position-relative">
                  <div className="inner px-lg-5 p-3 px-md-4">
                    <Form>
                      <Row className="justify-content-between profileCont">
                        <Col lg="12" className="my-2">
                          <div className="upload position-relative">
                            <input
                              type="file"
                              onChange={(e) => handleFileUpload(e)}
                              className="position-absolute h-100 w-100"
                            />
                            <div className="ProfileImg position-relative mx-auto">
                              <img
                                src={formik?.values?.profileImage || User}
                                alt=""
                                className="img-fluid rounded-circle h-100 w-100"
                              />
                              <span className="icn position-absolute">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <circle cx="10" cy="10" r="10" fill="black" />
                                  <path
                                    d="M13.0417 8.71867L11.2708 6.96867L11.8542 6.38534C12.0139 6.22561 12.2101 6.14575 12.4429 6.14575C12.6757 6.14575 12.8718 6.22561 13.0312 6.38534L13.6146 6.96867C13.7743 7.12839 13.8576 7.32117 13.8646 7.547C13.8715 7.77284 13.7951 7.96547 13.6354 8.12492L13.0417 8.71867ZM12.4375 9.33325L8.02083 13.7499H6.25V11.9791L10.6667 7.56242L12.4375 9.33325Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">Full Name</label>
                          <input
                            type="text"
                            placeholder="Enter Full Name"
                            className="form-control px-0"
                            name="name"
                            value={formik.values?.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <span className="text-danger">
                              {formik.errors.name}
                            </span>
                          )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">User name</label>
                          <input
                            type="text"
                            placeholder="Enter User Name"
                            className="form-control px-0"
                            name="userName"
                            value={formik.values?.userName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.userName &&
                            formik.errors.userName && (
                              <span className="text-danger">
                                {formik.errors.userName}
                              </span>
                            )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">Email</label>
                          <input
                            type="email"
                            placeholder="Enter Email"
                            className="form-control px-0"
                            name="email"
                            value={formik.values?.email}
                            disabled={true}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <span className="text-danger">
                              {formik.errors.email}
                            </span>
                          )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label for="exampleEmail">Mobile No.</label>

                          <PhoneInput
                            country={"in"}
                            value={
                              formik.values.countryCode +
                              formik.values.mobileNumber
                            }
                            onChange={handlePhoneNumberChange}
                            placeholder="Enter Phone No."
                          />
                          {formik.touched.mobileNumber &&
                            formik.errors.mobileNumber && (
                              <span className="text-danger">
                                {formik.errors.mobileNumber}
                              </span>
                            )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">Enter Bio</label>
                          <input
                            type="text"
                            placeholder="Enter Bio"
                            className="form-control px-0"
                            name="bio"
                            value={formik.values?.bio}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.bio && formik.errors.bio && (
                            <span className="text-danger">
                              {formik.errors.bio}
                            </span>
                          )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label for="">
                            Gender <span className="text-danger">*</span>
                          </label>
                          <Form.Select
                            className="form-control"
                            aria-label="Default select example"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gender}
                            name="gender"
                          >
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="others">Others</option>
                          </Form.Select>
                          {formik.touched.gender && formik.errors.gender && (
                            <span className="text-danger">
                              {formik.errors.gender}
                            </span>
                          )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">Enter Website Link</label>
                          <input
                            type="text"
                            placeholder="Website URL"
                            className="form-control px-0"
                            name="websiteLink"
                            value={formik.values?.websiteLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.websiteLink &&
                            formik.errors.websiteLink && (
                              <span className="text-danger">
                                {formik.errors.websiteLink}
                              </span>
                            )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <label className="mb-1">Address </label>
                          <input
                            type="text"
                            placeholder="Enter address"
                            className="form-control px-0"
                            name="address"
                            value={formik.values?.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address && formik.errors.address && (
                            <span className="text-danger">
                              {formik.errors.address}
                            </span>
                          )}
                        </Col>
                        <Col lg="5" md="6" className="my-2">
                          <FormGroup>
                            <label for="exampleEmail">
                              Update Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <InputGroup>
                              <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                name="password"
                                id="password"
                              />
                              <InputGroupText
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <img src={HideIcon} />
                                ) : (
                                  <img src={ShowIcon} />
                                )}
                              </InputGroupText>
                            </InputGroup>

                            {formik.touched.password &&
                              formik.errors.password && (
                                <span className="text-danger">
                                  {formik.errors.password}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-4">
                        <Col lg="8" md="6" className="my-2">
                          {showButtonLoader ? (
                            <Button className="w-100 rounded bg-dark py-3 mt-5">
                              <ButtonLoader color={"text-light"} />
                            </Button>
                          ) : (
                            <Button
                              className="w-100 rounded bg-dark py-3 mt-5"
                              onClick={formik.handleSubmit}
                            >
                              Update
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </Fade>
    </>
  );
};

export default ProfileComponent;
