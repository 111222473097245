import React from "react";
import LoginIndex from "../../components/AuthComponent/Login";
import {useSelector} from "react-redux"

const Login = () => {


  return (
    <>
      <LoginIndex />
    </>
  );
};
export default Login;
