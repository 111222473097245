import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import Fade from "react-reveal";
import ButtonLoader from "../../../../elements/ButtonLoader";
// images
import Uploathumb from "../../../../images/upload_thunmnail.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addProduct, uploadImg } from "../../../../redux/states/products/thunk";
import { toast } from "react-toastify";

const Addproduct = () => {
  const navigate = useNavigate();
  const { showButtonLoader, isUpdate, imageLoader } = useSelector(
    (state) => state.products
  );
  const dispatch = useDispatch();
  const [selectedImgInput, setSelectedImgInput] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      category: "",
      size: "",
      discount: 0,
      type: "",
      price: 0,
      totalQuantity: 0,
      mainImgUrl: "",
      secondImgUrl: "",
      thirdImgUrl: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(addProduct(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          resetForm();
        })
        .catch((err) => toast.error(err.message));
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      category: Yup.string().required("Category is required"),
      size: Yup.string().required("Size is required"),
      type: Yup.string().required("Type is required"),
      price: Yup.number().required("Price is required"),
      totalQuantity: Yup.number().required("Total quantity is required"),
      mainImgUrl: Yup.string().required("Please select main image"),
      secondImgUrl: Yup.string().required("please select second image"),
      thirdImgUrl: Yup.string().required("Please select third image"),
    }),
  });

  const handleImage = (e, position) => {
    setSelectedImgInput(position);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files);
    dispatch(uploadImg(formData))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        formik.setFieldValue(position, res.data?.url);
        setSelectedImgInput("");
      })
      .catch((err) => {
        setSelectedImgInput("");
        toast.error(err.message);
      });
  };

  const handleSize = (size) => {
    formik.setFieldValue("size", size);
  };

  return (
    <Fade>
      <section className="Addproduct_section">
        <Container fluid>
          <div className="TableFilters d-flex align-items-center justify-content-start gap-10 p-3 p-lg-4 flex-wrap">
            <div className="left d-flex align-items-center gap-10 flex-wrap">
              <div className="d-flex gap-10">
                <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
                  <div className="px-2">
                    <Button
                      className="d-flex w-100 cstmShadow align-items-center justify-content-center rounded-pill gap-2"
                      onClick={() => navigate(-1)}
                    >
                      <span className="icn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                        >
                          <g id="tabler:arrow-left">
                            <path
                              id="Vector"
                              d="M4.375 10.5H16.625M4.375 10.5L9.625 15.75M4.375 10.5L9.625 5.25"
                              stroke="#9DA3BB"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>
                      </span>
                      Back
                    </Button>
                  </div>
                </div>
                <h2 className="m-0 fw-bold heading">Add Product</h2>
              </div>
            </div>
          </div>
          <Form>
            <Row>
              <Col lg={5} md={6} sm={12}>
                <div className="product_img-upload">
                  <div class="upload-btn-wrapper">
                    <button class="btnwrap">
                      {imageLoader && selectedImgInput === "mainImgUrl" ? (
                        <ButtonLoader />
                      ) : (
                        <img
                          src={
                            formik.values.mainImgUrl
                              ? formik.values.mainImgUrl
                              : Uploathumb
                          }
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={(e) => handleImage(e, "mainImgUrl")}
                      accept="image/png, image/gif, image/jpeg"
                      value=""
                    />
                  </div>
                  <br />
                  {formik.touched.mainImgUrl && formik.errors.mainImgUrl && (
                    <span className="text-danger">
                      {formik.errors.mainImgUrl}
                    </span>
                  )}

                  <ul className="sub_thumbnail">
                    <li>
                      <div className="thumbnails_box upload-btn-wrapper w-50 h-50">
                        <button class="btnwrap">
                          {imageLoader &&
                          selectedImgInput === "secondImgUrl" ? (
                            <ButtonLoader />
                          ) : (
                            <img
                              src={
                                formik.values.secondImgUrl
                                  ? formik.values.secondImgUrl
                                  : Uploathumb
                              }
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={(e) => handleImage(e, "secondImgUrl")}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <br />
                      {formik.touched.secondImgUrl &&
                        formik.errors.secondImgUrl && (
                          <span className="text-danger">
                            {formik.errors.secondImgUrl}
                          </span>
                        )}
                    </li>
                    <li>
                      <div className="thumbnails_box upload-btn-wrapper  w-50 h-50">
                        <button class="btnwrap">
                          {imageLoader && selectedImgInput === "thirdImgUrl" ? (
                            <ButtonLoader />
                          ) : (
                            <img
                              src={
                                formik.values.thirdImgUrl
                                  ? formik.values.thirdImgUrl
                                  : Uploathumb
                              }
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={(e) => handleImage(e, "thirdImgUrl")}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <br />
                      {formik.touched.thirdImgUrl &&
                        formik.errors.thirdImgUrl && (
                          <span className="text-danger">
                            {formik.errors.thirdImgUrl}
                          </span>
                        )}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={5} md={6} sm={12}>
                <div className="product_img_form">
                  <FormGroup>
                    <Label for="exampleEmail">Product Name</Label>
                    <Input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      name="name"
                      id="name"
                      placeholder="AKA CHIC Women's Women Pink Hand held Bag"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <span className="text-danger">{formik.errors.name}</span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleEmail">Product Category</Label>
                    <Input
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.category}
                      onBlur={formik.handleBlur}
                      name="category"
                      id="category"
                      placeholder="Topware"
                    />
                    {formik.touched.category && formik.errors.category && (
                      <span className="text-danger">
                        {formik.errors.category}
                      </span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleEmail">Product Description</Label>
                    <Input
                      type="textarea"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      name="description"
                      id="description"
                      placeholder="The mighty Wolverine is a beast to behold & once you have tamed this beauty, you are guaranteed to have a ride of your lifetime!"
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <span className="text-danger">
                          {formik.errors.description}
                        </span>
                      )}
                  </FormGroup>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Product Price</Label>
                        <Input
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.price}
                          onBlur={formik.handleBlur}
                          name="price"
                          id="price"
                          placeholder="$ 173.35"
                        />
                        {formik.touched.price && formik.errors.price && (
                          <span className="text-danger">
                            {formik.errors.price}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Product Discount</Label>
                        <Input
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.discount}
                          onBlur={formik.handleBlur}
                          name="discount"
                          id="discount"
                          placeholder="30%"
                        />
                        {formik.touched.discount && formik.errors.discount && (
                          <span className="text-danger">
                            {formik.errors.discount}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup tag="fieldset">
                        <legend>Discount Type</legend>
                        <div className="wrapping d-lg-flex gap-4 justify-content-start align-items-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio2"
                                onChange={() => {
                                  formik.setFieldValue("type", "flat");
                                }}
                                checked={formik.values.type === "flat"}
                              />
                              Flat
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio2"
                                onChange={() => {
                                  formik.setFieldValue("type", "amount");
                                }}
                                checked={formik.values.type === "amount"}
                              />
                              Amount
                            </Label>
                          </FormGroup>
                        </div>
                        {formik.touched.type && formik.errors.type && (
                          <span className="text-danger">
                            {formik.errors.type}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup tag="fieldset">
                        <legend>Size</legend>
                        <div className="wrapping d-lg-flex gap-4 justify-content-start align-items-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio1"
                                onChange={() => handleSize("S")}
                                checked={formik.values.size === "S"}
                              />
                              S
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio1"
                                onChange={() => handleSize("M")}
                                checked={formik.values.size === "M"}
                              />
                              M
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio1"
                                onChange={() => handleSize("L")}
                                checked={formik.values.size === "L"}
                              />
                              L
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="radio1"
                                onChange={() => handleSize("XL")}
                                checked={formik.values.size === "XL"}
                              />
                              XL
                            </Label>
                          </FormGroup>
                        </div>
                        {formik.touched.size && formik.errors.size && (
                          <span className="text-danger">
                            {formik.errors.size}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Quantity</Label>
                        <Input
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.totalQuantity}
                          onBlur={formik.handleBlur}
                          name="totalQuantity"
                          id="totalQuantity"
                          placeholder="5"
                        />
                        {formik.touched.totalQuantity === 0 &&
                          formik.errors.totalQuantity && (
                            <span className="text-danger">
                              {formik.errors.totalQuantity}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  {showButtonLoader ? (
                    <Button className="w-100 rounded bg-dark py-3 mt-1">
                      <ButtonLoader color={"text-light"} />
                    </Button>
                  ) : (
                    <Button
                      className="w-100 rounded bg-dark py-3 mt-1"
                      onClick={formik.handleSubmit}
                    >
                      {isUpdate ? "Update" : "Submit"}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </Fade>
  );
};

export default Addproduct;
