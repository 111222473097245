
import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {allLikePost , allCommentPost , allSharePost , allWatchListPost  , allUsersWatchList , removeLikePost ,removeCommentPost , removeSharePost , removeWatchListPost ,  removeUserWatchListPost } from "./thunk";

const initialState = {
    allLikePost : [],
    allCommentPost : [] ,
    allSharePost : [] ,
    allUsersWatchList : [] ,
    allWatchListPost : [] ,
    status : status.IDLE,
    showLoader : false ,
    selectedTab : 'like-post'
}

const slice = createSlice({
    name : "fanAnalytics" ,
    initialState : {...initialState} ,
    reducers : {
        removeAllLikePost : (state,action) =>{
            state.allLikePost = []
            state.showLoader = false ;
        },
        removeAllCommentPost : (state ,action) =>{
            state.allCommentPost = [];
            state.showLoader = false ;
        },
        updateFanAnalyticsTab : (state , action) =>{
            state.selectedTab = action.payload;
        }
    },
    extraReducers : (builder) =>{
        builder.addCase(allLikePost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allLikePost.fulfilled , (state,action) =>{
            state.allLikePost = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allLikePost.rejected , (state , action) =>{
            state.allLikePost = initialState.allLikePost;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        builder.addCase(allCommentPost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allCommentPost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
            state.allCommentPost = action.payload.data;
        })
        builder.addCase(allCommentPost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
            state.allCommentPost = initialState.allCommentPost;
        })
        builder.addCase(allSharePost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(allSharePost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
            state.allSharePost = action.payload.data;
        })
        builder.addCase(allSharePost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
            state.allSharePost = initialState.allSharePost;
        })
        builder.addCase(allWatchListPost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(allWatchListPost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
            state.allSharePost = action.payload.data;
        })
        builder.addCase(allWatchListPost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
            state.allWatchListPost = initialState.allWatchListPost;
        })
        builder.addCase(allUsersWatchList.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(allUsersWatchList.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
            state.allUsersWatchList = action.payload.data;
        })
        builder.addCase(allUsersWatchList.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
            state.allUsersWatchList = initialState.allWatchListPost;
        })

        // remove builder.addcase;
        builder.addCase(removeLikePost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(removeLikePost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
        })
        builder.addCase(removeLikePost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
        })

        builder.addCase(removeCommentPost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(removeCommentPost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
        })
        builder.addCase(removeCommentPost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
        })

        builder.addCase(removeSharePost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(removeSharePost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
        })
        builder.addCase(removeSharePost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
        })

        builder.addCase(removeWatchListPost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(removeWatchListPost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
        })
        builder.addCase(removeWatchListPost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
        })

        builder.addCase(removeUserWatchListPost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true;
        })
        builder.addCase(removeUserWatchListPost.fulfilled , (state , action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false ;
        })
        builder.addCase(removeUserWatchListPost.rejected  , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false ;
        })
    }
});

export default slice.reducer;
export const {removeAllCommentPost , removeAllLikePost , updateFanAnalyticsTab} = slice.actions;