import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { allTransactions } from "./thunk";



const initialState = {
    allTransactions : [] ,
    status : status.IDLE,
    showLoader : false ,
}

const slice = createSlice({
    name : "users",
    initialState : {...initialState},
    reducers : {
        removeAllTranactions : (proxy , action) =>{
            return initialState
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allTransactions.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allTransactions.fulfilled , (state,action) =>{
            state.allTransactions = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTransactions.rejected , (state , action) =>{
            state.allTransactions = initialState.allTransactions;
            state.status = status.FAILED;
            state.showLoader = false;
        })
    }
});

export default slice.reducer;
export const {removeAllTranactions} = slice.actions;