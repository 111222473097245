import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { allProducts , addProduct , updateProduct , deleteProduct ,productDetails , uploadImg } from "./thunk";

const initialState = {
    allProducts : [] ,
    status : status.IDLE,
    showLoader : false ,
    isUpdate : false,
    showButtonLoader : false ,
    productDetails : {} ,
    imageLoader : false
}

const slice = createSlice({
    name : "product",
    initialState : {...initialState},
    reducers : {
        removeAllProducts : (proxy , action) =>{
            return initialState
        },
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allProducts.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allProducts.fulfilled , (state,action) =>{
            state.allProducts = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allProducts.rejected , (state , action) =>{
            state.allProducts = initialState.allProducts;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(addProduct.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(addProduct.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(addProduct.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })

        builder.addCase(updateProduct.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(updateProduct.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
            state.isUpdate = false;
        })
        builder.addCase(updateProduct.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
            state.isUpdate = false;
        })

        builder.addCase(deleteProduct.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(deleteProduct.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(deleteProduct.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })

        builder.addCase(productDetails.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(productDetails.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
            state.productDetails = action.payload.data;
        })
        builder.addCase(productDetails.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
            state.productDetails = initialState.productDetails;
        })

        builder.addCase(uploadImg.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.imageLoader = true ;
        });
        builder.addCase(uploadImg.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.imageLoader = false;
        })
        builder.addCase(uploadImg.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.imageLoader = false;
        })

    }
});


export default slice.reducer;
export const {removeAllProducts } = slice.actions;