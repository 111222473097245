import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const allSubAdmin = createAsyncThunk(
  "/subAdmin/getList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.subAdmin.getSubAdminList,
        payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const subAdminDetails = createAsyncThunk(
  "/subAdmin/details",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(
        endpoints.subAdmin.getSubAdminDetails + payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const addSubAdmin = createAsyncThunk(
  "/subAdmin/add",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.subAdmin.addSubAdmin,
        payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateSubAdmin = createAsyncThunk(
  "/subAdmin/update",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.subAdmin.updateSubAdmin,
        payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  "/subAdmin/delete",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.subAdmin.deleteSubAdmin,
        payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
