import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allTickets = createAsyncThunk("/tickets/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.tickets.getAllTickets , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const addTicket = createAsyncThunk("/tickets/add" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.tickets.addTicket , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const updateTicket = createAsyncThunk("/tickets/update" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.tickets.updateTicket , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const deleteTicket = createAsyncThunk("/tickets/delete" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.tickets.deleteTicket , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const ticketDetails = createAsyncThunk("/tickets/details" , async (slug , Thunk) =>{
    try{
        const response = await axios.get(endpoints.tickets.ticketDetails + slug)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

