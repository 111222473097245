import React from "react";
import { Col, Container, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import Fade from "react-reveal";
import TopFansIndex from "./Component/TabContent/TopFans/Index";
import TopArtistsIndex from "./Component/TabContent/TopArtists/Index";
import CounterCards from "./Component/CounterCards";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedTab } from "../../../redux/states/dashboard/slice";
import BarChart from "./Component/charts/BarChart";
import PieChart from "./Component/charts/PieChart";

const DashboardIndex = (props) => {
  const data = useSelector((state) => state.dashboard)?.dashboardData;
  const { setFilter, tab, setQuery } = props;
  const { totalRevenueMonthly } = data;
  const dispatch = useDispatch();

  const handleTabSelectedtion = (tabs) => {
    setQuery({
      tab: tab,
      page: 1,
      limit: 10,
    });
    dispatch(updateSelectedTab(tabs));
  };

  return (
    <>
      <section className="position-relative dashboard">
        <div className="px-lg-5 px-md-3">
          <CounterCards data={data} />
          <Container fluid>
            <Row>
              <Col md="6" className="my-2">
                <div className="cardBg h-100 position-relative">
                  <div className="top d-flex align-items-center justify-content-between gap-10 px-3 py-3">
                    <div className="left">
                      <h6 className="m-0 fw-bold">User</h6>
                      <p className="m-0">Customers that buy our products</p>
                    </div>
                    <div className="right">
                      <Dropdown className="moreOption">
                        <Dropdown.Toggle
                          variant="transparent"
                          className=" border-0 p-0"
                          id="dropdown-basic"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="3"
                            viewBox="0 0 15 3"
                            fill="none"
                          >
                            <circle cx="1.5" cy="1.5" r="1.5" fill="#8F95B2" />
                            <circle cx="7.5" cy="1.5" r="1.5" fill="#8F95B2" />
                            <circle cx="13.5" cy="1.5" r="1.5" fill="#8F95B2" />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border-0">
                          <Dropdown.Item className="bg-transparent py-2 d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                  fill="#8F95B2"
                                />
                                <path
                                  d="M17.4035 8.80875C16.7419 7.09743 15.5934 5.61747 14.0998 4.5518C12.6063 3.48612 10.8332 2.88145 8.99976 2.8125C7.1663 2.88145 5.39323 3.48612 3.89969 4.5518C2.40614 5.61747 1.2576 7.09743 0.596011 8.80875C0.55133 8.93234 0.55133 9.06766 0.596011 9.19125C1.2576 10.9026 2.40614 12.3825 3.89969 13.4482C5.39323 14.5139 7.1663 15.1186 8.99976 15.1875C10.8332 15.1186 12.6063 14.5139 14.0998 13.4482C15.5934 12.3825 16.7419 10.9026 17.4035 9.19125C17.4482 9.06766 17.4482 8.93234 17.4035 8.80875V8.80875ZM8.99976 12.6562C8.27662 12.6562 7.56973 12.4418 6.96846 12.0401C6.36719 11.6383 5.89856 11.0673 5.62183 10.3992C5.34509 9.73109 5.27269 8.99594 5.41377 8.2867C5.55484 7.57746 5.90307 6.92598 6.4144 6.41464C6.92574 5.9033 7.57722 5.55508 8.28646 5.414C8.99571 5.27293 9.73086 5.34533 10.3989 5.62207C11.067 5.8988 11.6381 6.36743 12.0398 6.9687C12.4416 7.56996 12.656 8.27686 12.656 9C12.6545 9.96924 12.2688 10.8984 11.5835 11.5837C10.8981 12.2691 9.969 12.6548 8.99976 12.6562V12.6562Z"
                                  fill="#8F95B2"
                                />
                              </svg>
                            </span>
                            View
                          </Dropdown.Item>
                          <Dropdown.Item className="bg-transparent py-2 d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.25 12H9.75V5.25H12L9 1.5L6 5.25H8.25V12Z"
                                  fill="#8F95B2"
                                />
                                <path
                                  d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V8.25C15.75 7.42275 15.0773 6.75 14.25 6.75H11.25V8.25H14.25V15H3.75V8.25H6.75V6.75H3.75C2.92275 6.75 2.25 7.42275 2.25 8.25V15C2.25 15.8273 2.92275 16.5 3.75 16.5Z"
                                  fill="#8F95B2"
                                />
                              </svg>
                            </span>
                            Export
                          </Dropdown.Item>
                          <Dropdown.Item className="bg-transparent py-2 dangerText d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M2.25 4.5H15.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72065 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.5 8.25V12.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 8.25V12.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            Remove
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="CardBody p-3">
                    {/* <img
                    src="../assets/images/g1.png"
                    alt=""
                    className="img-fluid"
                  /> */}
                    <PieChart data={data} />
                  </div>
                </div>
              </Col>
              <Col md="6" className="my-2">
                <div className="cardBg h-100 position-relative">
                  <div className="top d-flex align-items-center justify-content-between gap-10 px-3 py-3">
                    <div className="left">
                      <h6 className="m-0 fw-bold">Fans Revenue</h6>
                      <p className="m-0">Customers that buy our products</p>
                    </div>
                    <div className="right">
                      <Dropdown className="moreOption">
                        <Dropdown.Toggle
                          variant="transparent"
                          className=" border-0 p-0"
                          id="dropdown-basic"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="3"
                            viewBox="0 0 15 3"
                            fill="none"
                          >
                            <circle cx="1.5" cy="1.5" r="1.5" fill="#8F95B2" />
                            <circle cx="7.5" cy="1.5" r="1.5" fill="#8F95B2" />
                            <circle cx="13.5" cy="1.5" r="1.5" fill="#8F95B2" />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border-0">
                          <Dropdown.Item className="bg-transparent py-2 d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                  fill="#8F95B2"
                                />
                                <path
                                  d="M17.4035 8.80875C16.7419 7.09743 15.5934 5.61747 14.0998 4.5518C12.6063 3.48612 10.8332 2.88145 8.99976 2.8125C7.1663 2.88145 5.39323 3.48612 3.89969 4.5518C2.40614 5.61747 1.2576 7.09743 0.596011 8.80875C0.55133 8.93234 0.55133 9.06766 0.596011 9.19125C1.2576 10.9026 2.40614 12.3825 3.89969 13.4482C5.39323 14.5139 7.1663 15.1186 8.99976 15.1875C10.8332 15.1186 12.6063 14.5139 14.0998 13.4482C15.5934 12.3825 16.7419 10.9026 17.4035 9.19125C17.4482 9.06766 17.4482 8.93234 17.4035 8.80875V8.80875ZM8.99976 12.6562C8.27662 12.6562 7.56973 12.4418 6.96846 12.0401C6.36719 11.6383 5.89856 11.0673 5.62183 10.3992C5.34509 9.73109 5.27269 8.99594 5.41377 8.2867C5.55484 7.57746 5.90307 6.92598 6.4144 6.41464C6.92574 5.9033 7.57722 5.55508 8.28646 5.414C8.99571 5.27293 9.73086 5.34533 10.3989 5.62207C11.067 5.8988 11.6381 6.36743 12.0398 6.9687C12.4416 7.56996 12.656 8.27686 12.656 9C12.6545 9.96924 12.2688 10.8984 11.5835 11.5837C10.8981 12.2691 9.969 12.6548 8.99976 12.6562V12.6562Z"
                                  fill="#8F95B2"
                                />
                              </svg>
                            </span>
                            View
                          </Dropdown.Item>
                          <Dropdown.Item className="bg-transparent py-2 d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.25 12H9.75V5.25H12L9 1.5L6 5.25H8.25V12Z"
                                  fill="#8F95B2"
                                />
                                <path
                                  d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V8.25C15.75 7.42275 15.0773 6.75 14.25 6.75H11.25V8.25H14.25V15H3.75V8.25H6.75V6.75H3.75C2.92275 6.75 2.25 7.42275 2.25 8.25V15C2.25 15.8273 2.92275 16.5 3.75 16.5Z"
                                  fill="#8F95B2"
                                />
                              </svg>
                            </span>
                            Export
                          </Dropdown.Item>
                          <Dropdown.Item className="bg-transparent py-2 dangerText d-flex align-items-center justify-content-start gap-10">
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M2.25 4.5H15.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72065 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.5 8.25V12.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 8.25V12.75"
                                  stroke="#FF754C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            Remove
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="CardBody py-3 ">
                    {/* <img
                    src="../assets/images/g2.png"
                    alt=""
                    className="img-fluid"
                  /> */}
                    <BarChart totalMonthlyRevenue={totalRevenueMonthly} />
                  </div>
                </div>
              </Col>
              <Col lg="12" className="my-2">
                <div className=" h-100 position-relative commonTabs TextTabs">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="TopFans"
                  >
                    <Nav variant="pills" className="pt-3 px-3 bg-transparent">
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 fw-bold px-2 position-relative"
                          eventKey="TopFans"
                          onClick={() => handleTabSelectedtion("top-fans")}
                          active={tab === "top-fans"}
                        >
                          Top Viewers
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 fw-bold px-2 position-relative"
                          eventKey="TopArtists"
                          onClick={() => handleTabSelectedtion("top-artists")}
                          active={tab === "top-artists"}
                        >
                          Top Entertainers
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="CardBody p-3">
                      <Tab.Content className="">
                        <Tab.Pane
                          eventKey="TopFans"
                          active={tab === "top-fans"}
                        >
                          <TopFansIndex {...props} />
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="TopArtists"
                          active={tab === "top-artists"}
                        >
                          <TopArtistsIndex {...props} />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default DashboardIndex;
