import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import AddArtists from "../../../Common/Modals/AddArtists";
import { useDispatch } from "react-redux";
import { clearSelected } from "../../../../redux/states/artists/slice";

const TableFilter = (props) => {
  const { filter, setFilter } = props;
  const { showAddArtistModel, setShowAddArtistModel } = props;
  const dispatch = useDispatch();

  const onHide = () => {
    setShowAddArtistModel(false);
    dispatch(clearSelected());
  };

  const handleStatus = (e) => {
    const value = e.target.value;
    setFilter({ status: value });
  };

  return (
    <>
      <AddArtists show={showAddArtistModel} onHide={onHide} {...props} />
      <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
        <div className="left d-flex align-items-center gap-10 flex-wrap">
          <h2 className="m-0 fw-bold heading">Entertainer </h2>
          <div className="searchForm position-relative icon-with-text ml-4">
            <input
              type="text"
              placeholder="Search by Name, email"
              className="form-control rounded-pill cstmShadow "
              value={filter.search}
              onChange={(e) => setFilter({ search: e.target.value })}
            />
            <span className="position-absolute icn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.75 13.75L12.5 12.5"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
          <div className="px-2 d-flex">
            <Form.Select
              aria-label=""
              className="activeSelect mx-3"
              onChange={(e) => handleStatus(e)}
            >
              <option value=""> Status</option>
              <option value="active">Active</option>
              <option value="blocked">Blocked</option>
            </Form.Select>
          </div>
          <div className="px-2">
            <Button
              onClick={() => setShowAddArtistModel(true)}
              className="d-flex w-100 cstmShadow align-items-center justify-content-center rounded-pill "
            >
              <span className="me-2 icn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                    fill="#9DA3BB"
                  />
                  <path
                    d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                    fill="#9DA3BB"
                  />
                </svg>
              </span>{" "}
              Add Entertainer
            </Button>
          </div>
          <div className="px-2">
            {/* <Button className="d-flex w-100 cstmShadow align-items-center justify-content-center rounded-pill ">
              Filter
              <span className="ms-2 icn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 5.83333C14.1421 5.83333 17.5 5.08714 17.5 4.16667C17.5 3.24619 14.1421 2.5 10 2.5C5.85786 2.5 2.5 3.24619 2.5 4.16667C2.5 5.08714 5.85786 5.83333 10 5.83333Z"
                    stroke="#9DA3BB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 4.16663C2.5 6.02496 5.72583 9.72829 7.38 11.5041C7.9868 12.1473 8.32743 12.9965 8.33333 13.8808V18.3333L11.6667 16.6666V13.8808C11.6667 12.9966 12.0175 12.1516 12.62 11.5041C14.275 9.72829 17.5 6.02579 17.5 4.16663"
                    stroke="#9DA3BB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableFilter;
