import React, { useEffect, useState } from 'react';
import AuthLayout from '../../layout/authLayout';
import { Button, Form , Row , Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../../elements/ButtonLoader';
import ReactPinField from "react-pin-field"
import { resendOtp, verifyOtp } from '../../redux/states/auth/thunk';
import {toast} from "react-toastify";

const Otp = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [event , setEvent] = useState("verifyOtp");

  const { showButtonLoader , selectedEmail } = useSelector((state) => state.auth);

    const formik = useFormik({
      initialValues: {
        email: "",
        OTP: "",
      },
      onSubmit: async function (values) {
        setEvent("verifyOtp");
        dispatch(verifyOtp(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          navigate("/reset-password");
        })
        .catch((err) => toast.error(err.message));
      },

      validationSchema: Yup.object({
        OTP: Yup.string("OTP must be a string")
          .required("OTP is required").min(6 , "Invalid OTP").max(6 , "Invalid OTP")
      }),
    });

    const handleOtpChange =(val) =>{
      formik.setFieldValue("OTP" , val)
    }

    useEffect(() =>{
      if(selectedEmail){
      formik.setFieldValue("email" , selectedEmail)
      }
    },[selectedEmail]);

    const handleResendOtp = () =>{
      setEvent("resendOtp")
      const data  = {
        "email": selectedEmail
      }
      dispatch(resendOtp(data))
      .unwrap()
        .then((res) => {
          toast.success(res.message);
        })
        .catch((err) => toast.error(err.message));
    }

  return (
    <AuthLayout>
      <div className="accountForm  my-3">
        <h5 className="text-light">Enter OTP</h5>
        <Form className="mt-4 text-start">
          <Col lg="12" className="mt-2">
            <div className="cstm-otp d-flex align-items-center justify-content-center">
              <ReactPinField
                style={{ width: "50px", height: "40px"  , margin : "0px 10px"}}
                class="pin-field"
                validate="0123456789"
                length="6"
                onChange={handleOtpChange}
              />
            </div>
            {formik.touched.OTP && formik.errors.OTP && (
              <span className="text-danger mt-3">{formik.errors.OTP}</span>
            )}
          </Col>

          <div className="py-2">
          {(showButtonLoader && event === "verifyOtp") ? (
              <div className="btn-wrp mt-3">
                <Link
                  className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                >
                  <ButtonLoader />
                </Link>
              </div>
            ) : (
              <div className="btn-wrp mt-3">
                <Link
                  className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                  onClick={formik.handleSubmit}
                >
                  Verify OTP
                </Link>
              </div>
            )}
          </div>
          <div className="py-0">
              <p className="m-0 form-text text-start">
                <Link to="" className="form-link text-white">
                  Not Receive OTP?
                </Link>
              </p>
            </div>
          <div className="py-2">
          {(showButtonLoader && event === "resendOtp") ? (
              <div className="btn-wrp mt-3">
                <Link
                  className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                >
                  <ButtonLoader />
                </Link>
              </div>
            ) : (
              <div className="btn-wrp mt-3">
                <Link
                  className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </Link>
              </div>
            )}
          </div>
          <div className="py-1">
            <p className="m-0 form-text text-end">
              <Link to="/" className="form-link text-white">
                Go To Login
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
}

export default Otp