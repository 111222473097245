import React, { useEffect, useState } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import FanLoyaltyIndex from "../../../components/SideTab/FanLoaylty/Index";
import { useDispatch } from "react-redux";
import {
  approveFanLoyality,
  declineFanLoyality,
  fanLoyalityList,
} from "../../../redux/states/fanLoyality/thunk";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";


const FanLoyalty = () => {

  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage, setTotalPage] = useState(1);


  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: q.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: q.get("page") + 1 });
    }
  };

  const getFanLoyalityList = (search, pageNo, limit) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
    };

    dispatch(fanLoyalityList(data))
    .then((response) => {
      const totalCount = response?.payload?.totalcount;
      if (totalCount > 10) {
        const totalpage = totalCount / filter.limit;
        setTotalPage(Math.ceil(totalpage));
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  };

  const approveLoyality = (dta) => {
    const data = {
      fanLoyaltyId: dta?._id,
      status: "approved",
    };
    dispatch(approveFanLoyality(data))
      .unwrap()
      .then((response) => {
        getFanLoyalityList();
      });
  };

  const declineLoyality = (dta) => {
    const data = {
      fanLoyaltyId: dta?._id,
      status: "approved",
    };
    dispatch(declineFanLoyality(data))
      .unwrap()
      .then((response) => {
        getFanLoyalityList();
      });
  };

  useEffect(() => {
    getFanLoyalityList(filter.search, filter.page, filter.limit);
  }, [filter]);

  return (
    <>
      {/* <NonAuthLayout> */}
        <FanLoyaltyIndex
          filter={filter}
          setFilter={setFilter}
          totalPage={totalPage}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
          approveLoyality={approveLoyality}
          declineLoyality={declineLoyality}
        />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default FanLoyalty;
