import React, { useState } from 'react';

const TextTruncation = ({ text, maxLength }) => {
  const [showFullData, setShowFullData] = useState(false);

  const handleClick = () => {
    setShowFullData(!showFullData);
  };

  return (
    <div>
  {showFullData ? (
    <div className="fullData">
      {text}
      <p className='cursor-pointer text-danger' onClick={handleClick}>Show Less ..</p>
    </div>
  ) : (
    <div
      className={`ellipsis ${text?.length > maxLength ? 'clickable' : ''}`}
      onClick={text?.length > maxLength ? handleClick : undefined}
    >
      {text?.length > maxLength ? (
        <>
          {`${text?.substring(0, maxLength)}`}
          <p className='cursor-pointer text-danger' onClick={handleClick}>Show More...</p>
        </>
      ) : (
        text
      )}
    </div>
  )}
</div>
  );
};

export default TextTruncation;

