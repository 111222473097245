import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const users = createAsyncThunk("/users/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.user.getList , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const addUser =  createAsyncThunk("/user/add" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.user.addUser , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const updateUser =  createAsyncThunk("/user/update" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.user.updateUser , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const deleteUser =  createAsyncThunk("/user/delete" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.user.deleteUser , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})
