import React from "react";
import { Form, Pagination, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { deleteUser, updateUser } from "../../../../redux/states/users/thunk";
import { editUser } from "../../../../redux/states/users/slice";
import { toast } from "react-toastify";
import { useState } from "react";
import ButtonLoader from "../../../../elements/ButtonLoader";
import ConfirmDelete from "../../../Common/Modals/ConfirmDelete";
import { AiOutlineEye } from "react-icons/ai";
import { generatePath, useNavigate } from "react-router-dom";
import CustomPagination from "../../../CustomPagination";

const TableData = (props) => {
  const tableData = useSelector((state) => state.viewerRequest)?.list;
  const { showButtonLoader } = useSelector((state) => state.viewerRequest);
  const { filter, setFilter, setShowAddUserModel, getUsersList, totalPage } =
    props;
  const { handleNextClick, handlePreviousClick } = props;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedDtaForDelete, setSelectedDtaForDelete] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState("");

  const handleEditUser = (dta) => {
    dispatch(editUser(dta));
    setShowAddUserModel(true);
  };

  const deleteSelectedUser = (dta) => {
    setSelectedDtaForDelete(dta);
    setShowConfirmModal(true);
  };

  console.log(tableData, "taleDtata here");

  const confirmDelete = () => {
    const data = {
      userId: selectedDtaForDelete?._id,
    };
    dispatch(deleteUser(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getUsersList();
        setShowConfirmModal(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleBlock = (dta) => {
    setSelectedId(dta?._id);
    const data = {
      userId: dta?._id,
      status: "blocked",
      role: "USER",
    };
    dispatch(updateUser(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getUsersList();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleActivate = (dta) => {
    setSelectedId(dta?._id);
    const data = {
      userId: dta?._id,
      status: "active",
      role: "USER",
    };
    dispatch(updateUser(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getUsersList();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleUserDetails = (dta) => {
    const path = generatePath("/viewer-request/details/:id", { id: dta?._id });
    navigate(path);
  };

  return (
    <>
      <div className="CommonTable">
        <div className="table-responsive">
          <table className="table">
            <thead className="">
              <tr>
                <th className="border-0">S.No.</th>
                <th className="border-0">Name</th>
                <th className="border-0">Username</th>
                <th className="border-0">Email</th>
                <th className="border-0">Mobile</th>
                <th className="border-0">Registered At</th>
                <th className="border-0">Action</th>
                <th className="border-0">Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((data, index) => (
                <tr key={index}>
                  <td className="border-0">
                    <td className="border-0">
                      {filter.page > 1
                        ? filter?.limit * (filter?.page - 1) + index + 1
                        : index + 1}
                    </td>
                  </td>
                  <td className="border-0">
                    <b>
                      {data.firstName
                        ? data.firstName + " " + (data.lastName || " ")
                        : data.name}
                    </b>
                  </td>
                  <td className="border-0">{data?.userName}</td>
                  <td className="border-0">{data?.email}</td>
                  <td className="border-0">{data?.mobileNumber}</td>
                  {/* <td className="border-0">{data.followerCount}</td> */}
                  <td className="border-0">
                    {moment(data?.createdAt).format("MM/DD/YYYY")}
                  </td>
                  <td
                    className="border-0 d-flex justify-content-center pointer align-items-center "
                    style={{ marginTop: "21px" }}
                    onClick={() => handleUserDetails(data)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="22"
                      viewBox="0 0 23 22"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_0_1826)">
                        <path
                          d="M21.3845 10.7662C20.5759 8.67463 19.1721 6.8658 17.3467 5.56331C15.5212 4.26082 13.3541 3.52177 11.1132 3.4375C8.87234 3.52177 6.70525 4.26082 4.87981 5.56331C3.05437 6.8658 1.65059 8.67463 0.841983 10.7662C0.787373 10.9173 0.787373 11.0827 0.841983 11.2338C1.65059 13.3254 3.05437 15.1342 4.87981 16.4367C6.70525 17.7392 8.87234 18.4782 11.1132 18.5625C13.3541 18.4782 15.5212 17.7392 17.3467 16.4367C19.1721 15.1342 20.5759 13.3254 21.3845 11.2338C21.4391 11.0827 21.4391 10.9173 21.3845 10.7662ZM11.1132 17.1875C7.46948 17.1875 3.61948 14.4856 2.22386 11C3.61948 7.51437 7.46948 4.8125 11.1132 4.8125C14.757 4.8125 18.607 7.51437 20.0026 11C18.607 14.4856 14.757 17.1875 11.1132 17.1875Z"
                          fill="black"
                        ></path>
                        <path
                          d="M11.1133 6.875C10.2974 6.875 9.49991 7.11693 8.82156 7.57019C8.1432 8.02345 7.61449 8.66769 7.30228 9.42143C6.99007 10.1752 6.90838 11.0046 7.06754 11.8047C7.22671 12.6049 7.61958 13.3399 8.19647 13.9168C8.77336 14.4937 9.50836 14.8866 10.3085 15.0457C11.1087 15.2049 11.9381 15.1232 12.6919 14.811C13.4456 14.4988 14.0898 13.9701 14.5431 13.2917C14.9964 12.6134 15.2383 11.8158 15.2383 11C15.2383 9.90598 14.8037 8.85677 14.0301 8.08318C13.2565 7.3096 12.2073 6.875 11.1133 6.875ZM11.1133 13.75C10.5694 13.75 10.0377 13.5887 9.58546 13.2865C9.13323 12.9844 8.78075 12.5549 8.57261 12.0524C8.36447 11.5499 8.31001 10.9969 8.41612 10.4635C8.52223 9.93005 8.78414 9.44005 9.16874 9.05546C9.55333 8.67086 10.0433 8.40895 10.5768 8.30284C11.1102 8.19673 11.6632 8.25119 12.1657 8.45933C12.6682 8.66747 13.0977 9.01995 13.3998 9.47218C13.702 9.92442 13.8633 10.4561 13.8633 11C13.8633 11.7293 13.5736 12.4288 13.0578 12.9445C12.5421 13.4603 11.8426 13.75 11.1133 13.75Z"
                          fill="black"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_0_1826">
                          <rect
                            width="22"
                            height="22"
                            fill="white"
                            transform="translate(0.113281)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                  <td className="border-0">{data?.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap paginationWrpper mt-2 bg-white pt-3">
          <div className="left">
            <div className="showResult d-flex align-items-center gap-10">
              <label htmlFor="" className="form-label m-0">
                Show Result:
              </label>
              <Form.Select
                aria-label="Default select example"
                value={filter.limit}
                onChange={(e) => setFilter({ limit: e.target.value })}
              >
                {[10, 20, 30, 50]?.map((itm) => (
                  <option value={itm}>{itm}</option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="right ">
            <Pagination>
              <Pagination.Prev onClick={handlePreviousClick} />
              {Array.from(Array(totalPage).keys())?.map((page, index) => {
                var pageNo = page + 1;
                return (
                  <Pagination.Item
                    active={pageNo == filter.page}
                    onClick={() => setFilter({ page: pageNo })}
                  >
                    {pageNo}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next onClick={handleNextClick} />
            </Pagination>
          </div>
        </div> */}
        <CustomPagination
          totalPage={totalPage}
          setFilter={setFilter}
          filter={filter}
        />
        <ConfirmDelete
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          confirmDelete={confirmDelete}
          loading={showButtonLoader}
        />
      </div>
    </>
  );
};

export default TableData;
