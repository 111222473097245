import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {
  allFaqs,
  addFaq,
  updateFaq,
  deleteFaq,
  allContent,
  addContent,
  updateContent,
  deleteContent,
  getBasicSettings,
  updateBasicSetting,
  getBasicConfig,
  updateSmsConfig,
  updateMailConfig,
  allEmailTemplate,
  updateEmailTemplate,
  updateCRMConfig,
} from "./thunk";

const initialState = {
  status: status.IDLE,
  showLoader: false,
  selectedTab: "basic-setting",
  allFaqs: [],
  allContent: [],
  allEmailTemplate: [],
  basicSetting: {},
  basicConfig: {},
  showButtonLoader: false,
  isContentUpdate: false,
  isFaqUpdate: false,
  selectedFaq: {},
  selectedContent: {},
};

const slice = createSlice({
  name: "fanAnalytics",
  initialState: { ...initialState },
  reducers: {
    updateSettingsTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    editFaq: (state, action) => {
      state.selectedFaq = action.payload;
      state.isFaqUpdate = true;
    },
    editContent: (state, action) => {
      state.selectedContent = action.payload;
      state.isContentUpdate = true;
    },
    editEmailTemplate: (state, action) => {
      state.selectedEmailTemplate = action.payload;
      state.isEmailTemplateUpdate = true;
    },
    clearSelected: (state, action) => {
      state.isFaqUpdate = false;
      state.isContentUpdate = false;
      state.selectedFaq = initialState.selectedFaq;
      state.selectedContent = initialState.selectedContent;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allFaqs.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(allFaqs.fulfilled, (state, action) => {
      state.allFaqs = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(allFaqs.rejected, (state, action) => {
      state.allFaqs = initialState.allFaqs;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addFaq.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(addFaq.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(addFaq.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateFaq.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
      state.isFaqUpdate = true;
    });
    builder.addCase(updateFaq.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.isFaqUpdate = false;
    });
    builder.addCase(updateFaq.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.isFaqUpdate = false;
    });

    builder.addCase(deleteFaq.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(deleteFaq.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(deleteFaq.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(allContent.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(allContent.fulfilled, (state, action) => {
      state.allContent = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(allContent.rejected, (state, action) => {
      state.allContent = initialState.allContent;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addContent.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(addContent.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(addContent.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateContent.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
      state.isContentUpdate = true;
    });
    builder.addCase(updateContent.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.isContentUpdate = false;
    });
    builder.addCase(updateContent.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.isContentUpdate = false;
    });

    builder.addCase(deleteContent.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(deleteContent.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(deleteContent.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    // basic setting;

    builder.addCase(getBasicSettings.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(getBasicSettings.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showLoader = false;
      state.showButtonLoader = false;
      state.basicSetting = action.payload.data;
    });
    builder.addCase(getBasicSettings.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showLoader = false;
      state.showButtonLoader = false;
      state.basicSetting = initialState.basicSetting;
    });

    builder.addCase(getBasicConfig.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(getBasicConfig.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showLoader = false;
      state.showButtonLoader = false;

      state.basicConfig = action.payload.data;
    });
    builder.addCase(getBasicConfig.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showLoader = false;
      state.showButtonLoader = false;
      state.basicConfig = initialState.basicConfig;
    });

    builder.addCase(updateBasicSetting.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(updateBasicSetting.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(updateBasicSetting.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    // baisics configs;

    builder.addCase(updateSmsConfig.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(updateSmsConfig.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(updateSmsConfig.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateMailConfig.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(updateMailConfig.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(updateMailConfig.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(allEmailTemplate.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(allEmailTemplate.fulfilled, (state, action) => {
      state.allEmailTemplate = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(allEmailTemplate.rejected, (state, action) => {
      state.allEmailTemplate = initialState.allEmailTemplate;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(updateEmailTemplate.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
      state.showLoader = true;
    });
    builder.addCase(updateEmailTemplate.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.showLoader = false;
    });
    builder.addCase(updateEmailTemplate.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.showLoader = false;
    });

    builder.addCase(updateCRMConfig.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
      state.showLoader = true;
    });
    builder.addCase(updateCRMConfig.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.showLoader = false;
    });
    builder.addCase(updateCRMConfig.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.showLoader = false;
    });
  },
});

export default slice.reducer;
export const {
  updateSettingsTab,
  editFaq,
  editContent,
  editEmailTemplate,
  clearSelected,
} = slice.actions;
