import React, { useEffect } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Fade from "react-reveal";
import CommentPost from "./Component/TabContent/CommentPost/CommentPost";
import LikePost from "./Component/TabContent/LikePost/LikePost";
import SharingPost from "./Component/TabContent/SharingPost/SharingPost";
import PostWatchlist from "./Component/TabContent/PostWatchlist/PostWatchlist";
import UserWatchList from "./Component/TabContent/UserWatchlist/UserWatchlist";
import { useDispatch } from "react-redux";
import { updateFanAnalyticsTab } from "../../../redux/states/fanAnalytics/slice";

const FanAnalyticsIndex = (props) => {
  const { setFilter, tab, setQuery } = props;

  const dispatch = useDispatch();

  const handleTabSelectedtion = (tabs) => {
    setQuery({
      tab: tab,
      page: 1,
      limit: 10,
    });
    dispatch(updateFanAnalyticsTab(tabs));
  };

  return (
    <Fade>
      <section className="position-relative fan-analytics py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardBox h-100 position-relative bg-white">
                <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
                  <div className="left d-flex align-items-center gap-10 flex-wrap">
                    <h2 className="m-0 fw-bold heading">
                      Mostly Active (User) Listing
                    </h2>
                  </div>
                </div>
                <div className="p-lg-4 p-3 commonTabs">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="LikePosts"
                  >
                    <Nav variant="pills" className="">
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="LikePosts"
                          onClick={() => handleTabSelectedtion("like-post")}
                          active={tab === "like-post"}
                        >
                          Like Posts
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="CommentPost"
                          onClick={() => handleTabSelectedtion("comment-post")}
                          active={tab === "comment-post"}
                        >
                          Comment Post
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="SharingPost"
                          onClick={() => handleTabSelectedtion("sharing-post")}
                          active={tab === "sharing-post"}
                        >
                          Sharing Post
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="WatchList"
                          onClick={() =>
                            handleTabSelectedtion("watchlist-post")
                          }
                          active={tab === "watchlist-post"}
                        >
                          Video/Post Watchlist
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="UserWatchlist"
                          onClick={() =>
                            handleTabSelectedtion("userwatchlist-post")
                          }
                          active={tab === "userwatchlist-post"}
                        >
                          Top 10 Users Watchlists
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="CardBody py-3">
                      <Tab.Content className="">
                        <Tab.Pane
                          eventKey="LikePosts"
                          active={tab === "like-post"}
                        >
                          <LikePost {...props} />
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="CommentPost"
                          active={tab === "comment-post"}
                        >
                          <CommentPost {...props} />
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="SharingPost"
                          active={tab === "sharing-post"}
                        >
                          <SharingPost {...props} />
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="WatchList"
                          active={tab === "watchlist-post"}
                        >
                          <PostWatchlist {...props} />
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="UserWatchlist"
                          active={tab === "userwatchlist-post"}
                        >
                          <UserWatchList {...props} />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default FanAnalyticsIndex;
