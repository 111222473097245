export const endpoints = {
  auth: {
    adminLogin: "/admin/adminLogin",
    forgetPassword: "/admin/forgotpassword",
    verifyOtp: "/admin/verifyotp",
    resetPassword: "/admin/resetpassword",
    resendOtp: "/admin/resendotp",
  },
  user: {
    getList: "/admin/getUsersWithFilter",
    addUser: "/admin/addUser",
    updateUser: "/admin/editUser",
    deleteUser: "/admin/removeUser",
  },
  artist: {
    getList: "/admin/getArtistWithFilter",
    updateArtist: "/admin/editUser",
    deleteArtist: "/admin/removeUser",
    addArtist: "/admin/addUser",
  },
  announcements: {
    getList: "/announcement/getWithFilters",
    updateAnnouncements: "/announcement/",
    deleteAnnouncements: "/announcement/",
    addAnnouncements: "/announcement/add",
  },
  posts: {
    getList: "/adminSearch/getPostsWithFilter",
    getPostById: "/post/detail/",
    getLikesList: "/adminSearch/getPostLikesWithFilter",
    getCommentsList: "/adminSearch/getPostCommentsWithFilter",
    getShareList: "/adminSearch/getPostSharesWithFilter",
    getTipList: "/adminSearch/getPostTipsWithFilter",
    deletePost: "/post/remove",
  },
  fanAnalytics: {
    getLikePost: "/adminSearch/getPostLikesWithFilter",
    getSharePost: "/adminSearch/getPostViewersWithFilter",
    getWatchListPost: "/adminSearch/getPostSharesWithFilter",
    getUserWatchList: "/adminSearch/getPostSharesWithFilter",
    getCommentPost: "/adminSearch/getPostCommentsWithFilter",
    removeLikePost: "/post/removeLike",
    removeCommentPost: "/post/remove/comment",
    removeSharePost: "/post/remove/postShare",
    removeWatchListPost: "/post/removeViewer",
    removeUserWatchListPost: "/adminSearch/getPostSharesWithFilter",
  },
  transactions: {
    getAllTranctions: "/adminSearch/getUserTransactionsWithFilter",
  },
  earnings: {
    getAllEarnings: "",
  },
  fanLoyality: {
    getFanLoyalityList: "fanLoyalty/getAllFanLoyaltys",
    updateLoyality: "/fanLoyalty/editFanLoyalty",
  },
  subAdmin: {
    getSubAdminList: "/admin/getAllAdminList",
    addSubAdmin: "/admin/addAdmin",
    updateSubAdmin: "/admin/editAdmin",
    deleteSubAdmin: "/admin/removeAdmin",
    getSubAdminDetails: "/admin/getUserById/",
  },
  trophies: {
    getAllTrophies: "/userTrophie/getAllUserTrophies",
    deleteTrophy: "/userTrophie/deleteUserTrophie",
  },
  products: {
    getAllProducts: "/product/getWithFilters",
    addProduct: "/product/add",
    updateProduct: "/product/update",
    deleteProduct: "/product/remove",
    productDetails: "/product/detail/",
  },
  productCategory: {
    addCategory: "/category/create",
    updateCategory: "/category/update/",
    getCategory: "/category/getWithFilters",
    deleteCategory: "/category/delete/",
  },

  upload: {
    uploadImg: "/adminSetting/uploadFile",
  },
  tickets: {
    getAllTickets: "/event/getWithFilters",
    addTicket: "/event/add",
    deleteTicket: "/event/remove",
    updateTicket: "/event/update",
    ticketDetails: "/event/detail/",
  },
  dashboard: {
    getDashboardData: "/adminDashboard/getDashboardList",
    getTopFan: "/admin/getUsersWithFilter",
    getTopArtists: "/admin/getArtistWithFilter",
  },
  setting: {
    getAllFaq: "/admin/getAllFaqs",
    addFaq: "/admin/addFaq",
    updateFaq: "/admin/editFaq",
    deleteFaq: "/admin/deleteFaq",
    getAllContent: "/admin/contents",
    addContent: "/admin/contents/add",
    updateContent: "/admin/contents/update",
    deleteContent: "/admin/contents/remove",
    getBasicSetting: "/adminSetting/getBasicSettingInfo",
    updateBasicSetting: "/adminSetting/basicAppSetting",
    getBasicConfig: "/adminSetting/getConfigInfo",
    udpateSms: "/adminSetting/twilio/update",
    udpateMail: "/adminSetting/mailgun/update",
    allEmail: "/adminSetting/mailTemplate/all",
    updateEmail: "/adminSetting/mailTemplate/edit",
    udpateCRM: "/adminSetting/crm/update",
  },
  plan: {
    getPlan: "/subscription/getWithFilters",
    addPlan: "/subscription/create",
    updatePlan: "/subscription/update/",
    deletePlan: "subscription/delete?",
  },
  profile: {
    getProfile: "/admin/getUserById/",
    updateProfile: "/admin/editUser",
  },
  viewerRequest: {
    viewerRequestList: "/admin/creatorlisting",
    approveViewer: "/admin/usertoentertainer",
    rejectViewerRequest: "/admin/rejectedcreator",
  },
};
