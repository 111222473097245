import {
  announcements,
  addAnnouncements,
  updateAnnouncements,
  deleteAnnouncements,
} from "./thunk";
import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";

const initialState = {
  allAnnouncements: [],
  showLoader: false,
  status: status.IDLE,
  isUpdate: false,
  showButtonLoader: false,
  selectedAnnouncements: {},
};

const slice = createSlice({
  name: "announcements",
  initialState: { ...initialState },
  reducers: {
    removeAllAnnouncements: (proxy, action) => {
      return initialState;
    },
    editAnnouncements: (state, action) => {
      state.selectedAnnouncements = action.payload;
      state.isUpdate = true;
    },
    clearSelected: (state, action) => {
      state.selectedAnnouncements = initialState.selectedAnnouncements;
      state.isUpdate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(announcements.pending, (state, action) => {
      state.showLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(announcements.fulfilled, (state, action) => {
      state.allAnnouncements = action.payload.data.result;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(announcements.rejected, (state, action) => {
      state.allAnnouncements = initialState.allAnnouncements;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addAnnouncements.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(addAnnouncements.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(addAnnouncements.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateAnnouncements.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
      state.isUpdate = true;
    });
    builder.addCase(updateAnnouncements.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.isUpdate = false;
      state.selectedAnnouncements = {};
    });
    builder.addCase(updateAnnouncements.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.isUpdate = false;
      state.selectedAnnouncements = {};
    });

    builder.addCase(deleteAnnouncements.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(deleteAnnouncements.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(deleteAnnouncements.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });
  },
});

export default slice.reducer;
export const { removeAllAnnouncements, editAnnouncements, clearSelected } =
  slice.actions;
