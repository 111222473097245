import React from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Row } from "react-bootstrap";

const TotalFans = () => {
  const state = {
    series: [44, 55],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      legend: { show: false },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        width={"115%"}
      />
    </div>
  );
};

const DailyFans = () => {
  const state = {
    series: [17, 15],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      legend: { show: false },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        width={"140%"}
      />
    </div>
  );
};

const WeeklyFans = () => {
  const state = {
    series: [55, 41],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      legend: { show: false },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        width={"140%"}
      />
    </div>
  );
};

const PieChart = ({ data }) => {
  const { totalUsers, weeklyUsers, dailyUsers } = data;
  return (
    <Row className=" h-100 align-items-c">
      <Col lg="7">
        <div
          style={{
            width: "250px",
            height: "250px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          className="rounded-circle position-relative borderColor-primary d-flex justify-content-center align-items-center"
        >
          <TotalFans />
          <div
            className="position-absolute rounded-circle   d-flex flex-column justify-content-center align-items-center"
            style={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              height: "35%",
              width: "35%",
            }}
          >
            <h6 className="text-primary" style={{ fontWeight: "800" }}>
              {totalUsers}%
            </h6>
            <span className="text-primary">Total</span>
          </div>
        </div>
      </Col>
      <Col
        lg="5"
        className=" h-100 "
        // style={{ width: "60%" }}
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          className="rounded-circle mx-auto position-relative my-2 borderColor-primary d-flex justify-content-center align-items-center"
        >
          <DailyFans />
          <div
            className="position-absolute rounded-circle   d-flex flex-column justify-content-center align-items-center"
            style={{
              height: "35%",
              width: "35%",
            }}
          >
            <h6 className="text-primary mb-0" style={{ fontWeight: "800" }}>
              {dailyUsers}%
            </h6>
            <span className="text-primary">Daily</span>
          </div>
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          className="rounded-circle mx-auto position-relative my-2 borderColor-primary d-flex justify-content-center align-items-center"
        >
          <WeeklyFans />
          <div
            className="position-absolute rounded-circle   d-flex flex-column justify-content-center align-items-center"
            style={{
              height: "35%",
              width: "35%",
            }}
          >
            <h6 className="text-primary mb-0" style={{ fontWeight: "800" }}>
              {weeklyUsers}%
            </h6>
            <span className="text-primary">Weekly</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PieChart;
