import React from "react";
import { Player , ControlBar } from "video-react";

const PostHandler = ({data}) => {
  return (
    <div className="mediaPost">
      {data.mediaType === "image" ? (
        <img src={data.mediaUrl} alt="" className="img-fluid rounded-circle" />
      ) : (
        <>
          <Player autoPlay src={data.mediaUrl}>
            <ControlBar autoHide={true} className="my-class" />
          </Player>
        </>
      )}
    </div>
  );
};

export default PostHandler;
