import React , {useState , useEffect} from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import FanBragsIndex from "../../../components/SideTab/FanBrags/Index";
import { useDispatch } from "react-redux";
import { allTrophies } from "../../../redux/states/trophies/thunk";
import {toast} from "react-toastify";
import { useSearchParams } from "react-router-dom";


const FanBrags = () => {

  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage , setTotalPage] = useState(1)

  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params={
      ...filter,
      ...args
    }
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page : q.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({page: q.get("page") + 1 });
    }
  };

  const getAllTrophiesList = (search, pageNo, limit) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
    };

    dispatch(allTrophies(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getAllTrophiesList(filter.search, filter.page, filter.limit);
  }, [filter]);

  return (
    <>
      {/* <NonAuthLayout> */}
        <FanBragsIndex 
         filter={filter}
         totalPage={totalPage}
         setFilter={setFilter}
         handlePreviousClick={handlePreviousClick}
         handleNextClick={handleNextClick}
         getAllTrophiesList={getAllTrophiesList}
         />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default FanBrags;
