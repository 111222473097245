import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../endpoints";

export const allFaqs = createAsyncThunk(
  "/setting/getFaq",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.getAllFaq, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const addFaq = createAsyncThunk(
  "/setting/addFaq",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.addFaq, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateFaq = createAsyncThunk(
  "/setting/updateFaq",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.updateFaq, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const deleteFaq = createAsyncThunk(
  "/setting/deleteFaq",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.deleteFaq, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const allContent = createAsyncThunk(
  "/setting/getContent",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.setting.getAllContent,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const addContent = createAsyncThunk(
  "/setting/addContent",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.addContent, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateContent = createAsyncThunk(
  "/setting/updateContent",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.setting.updateContent,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const deleteContent = createAsyncThunk(
  "/setting/deleteContent",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.setting.deleteContent,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

// basic setting part;
export const getBasicSettings = createAsyncThunk(
  "/setting/getBasic",
  async (Thunk) => {
    try {
      const response = await axios.get(endpoints.setting.getBasicSetting);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateBasicSetting = createAsyncThunk(
  "/setting/updateBasic",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.setting.updateBasicSetting,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getBasicConfig = createAsyncThunk(
  "/setting/getBasicConfig",
  async (Thunk) => {
    try {
      const response = await axios.get(endpoints.setting.getBasicConfig);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateSmsConfig = createAsyncThunk(
  "/setting/updateSmsConfig",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.udpateSms, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateMailConfig = createAsyncThunk(
  "/setting/updateMailConfig",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.udpateMail, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateCRMConfig = createAsyncThunk(
  "/setting/updateCRMConfig",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.udpateCRM, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const allEmailTemplate = createAsyncThunk(
  "/setting/allEmailTemplate",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.setting.allEmail, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  "/setting/updateEmailTemplate",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.setting.updateEmail, payload);
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
