import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { getProfile, updateProfile, uploadImg } from "./thunk";

const initialState = {
  loader: false,
  status: status.IDLE,
  profileDetailsDta: {},
  showButtonLoader: false,
};

const slice = createSlice({
  name: "profile",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.status = status.LOADING;
      state.loader = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.loader = false;
      state.profileDetailsDta = action.payload?.data;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.status = status.FAILED;
      state.loader = false;
      state.profileDetailsDta = initialState.profileDetailsDta;
    });
    builder.addCase(updateProfile.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });
  },
});

export default slice.reducer;
