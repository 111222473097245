import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Fade from "react-reveal";
import Basicsetting from "./Component/Basicsetting/Basicsetting";
import Contentpage from "./Component/Contentpage/Contentpage";
import FAQ from "./Component/FAQ/FAQ";
import { updateSettingsTab } from "../../../redux/states/settings/slice";
import { useDispatch } from "react-redux";
import Installation from "./Component/Installation/Installation";
import Payment from "./Component/Payment/Payment";
import EmailTemplates from "./Component/EmailTemplates";

const Settingsindex = (props) => {
  const { setFilter, tab, setQuery } = props;

  const dispatch = useDispatch();

  const handleTabSelectedtion = (tabs) => {
    setQuery({
      tab: tab,
      page: 1,
      limit: 10,
    });
    dispatch(updateSettingsTab(tabs));
  };

  return (
    <Fade>
      <section className="position-relative fan-analytics py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="px-lg-5 px-md-3">
                <div className="sectionInner h-100 position-relative">
                  <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
                    <div className="left d-flex align-items-center gap-10 flex-wrap">
                      <h2 className="m-0 fw-bold heading">Settings</h2>
                    </div>
                  </div>
                  <div className="commonTabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="basicsetting"
                    >
                      <Nav variant="pills" className="">
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="basicsetting"
                            onClick={() =>
                              handleTabSelectedtion("basic-setting")
                            }
                            active={tab === "basic-setting"}
                          >
                            Basic Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="basicsetting"
                            onClick={() =>
                              handleTabSelectedtion("installation-setting")
                            }
                            active={tab === "installation-setting"}
                          >
                            Installation Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="basicsetting"
                            onClick={() =>
                              handleTabSelectedtion("payment-setting")
                            }
                            active={tab === "payment-setting"}
                          >
                            Payment Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="contentpages"
                            onClick={() =>
                              handleTabSelectedtion("content-pages")
                            }
                            active={tab === "content-pages"}
                          >
                            Content Pages
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="faq"
                            onClick={() => handleTabSelectedtion("faq")}
                            active={tab === "faq"}
                          >
                            FAQ
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="email-templates"
                            onClick={() =>
                              handleTabSelectedtion("email-templates")
                            }
                            active={tab === "email-templates"}
                          >
                            Email Templates
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="productsetting"
                            onClick={() =>
                              handleTabSelectedtion("product-setting")
                            }
                            active={tab === "product-setting"}
                          >
                            Product Settings
                          </Nav.Link>
                        </Nav.Item> */}

                        {/* <Nav.Item className="px-2">
                          <Nav.Link
                            className="bg-transparent border-0 py-3 px-2 position-relative fw-sbold"
                            eventKey="referralsetting"
                            onClick={() =>
                              handleTabSelectedtion("referral-setting")
                            }
                            active={tab === "referral-setting"}
                          >
                            Referral Setting
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <div className="CardBody py-3">
                        <Tab.Content className="ps-lg-5 ps-md-3">
                          <Tab.Pane
                            eventKey="basicsetting"
                            active={tab === "basic-setting"}
                          >
                            <Basicsetting {...props} />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="installationsetting"
                            active={tab === "installation-setting"}
                          >
                            <Installation {...props} />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="paymentsetting"
                            active={tab === "payment-setting"}
                          >
                            <Payment {...props} />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="contentpages"
                            active={tab === "content-pages"}
                          >
                            <Contentpage {...props} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="faq" active={tab === "faq"}>
                            <FAQ {...props} />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="email-templates"
                            active={tab === "email-templates"}
                          >
                            <EmailTemplates {...props} />
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Settingsindex;
