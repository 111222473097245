import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routes from "./pages/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/style.css";
import "./css/responsive.css";
import MyRoutes from "./routers/router";
import ScrollToTop from "./ScrollToTop";
import runAxiosSetup from "./helpers/run-axios-setup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'video-react/dist/video-react.css';


function App() {
  
  const { user, isAuthenticated } = useSelector((state) => state.auth) ?? {};
  const token = user?.token;
  const [setup, setSetup] = useState({});

  useEffect(() => {
    runAxiosSetup({
      token : user?.token,
      adminId : user?._id,
      headers: {
        token: user?.token,
      },
    });
    setSetup((prev) => ({ ...prev, axios: true }));
  }, [user]);

  if (!setup.axios) {
    return null;
  }

  return (
    <>
      <BrowserRouter>
        <MyRoutes />
        <ScrollToTop />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
