import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const getViewerRequestList = createAsyncThunk(
  "getViewerRequestList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.viewerRequest.viewerRequestList,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const approveViewerForEntertainer = createAsyncThunk(
  "approveViewerForEntertainer",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.viewerRequest.approveViewer,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);

export const rejectViewerToBeEntertainer = createAsyncThunk(
  "rejectViewerToBeEntertainer",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.viewerRequest.rejectViewerRequest,
        payload
      );
      return response?.data;
    } catch (err) {
      return Thunk.rejectWithValue(err);
    }
  }
);
