import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const allCategory = createAsyncThunk("/category/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.productCategory.getCategory , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const addCategory =  createAsyncThunk("/category/add" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.productCategory.addCategory , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const updateCategory =  createAsyncThunk("/category/update" , async (payload , Thunk) =>{
    try {
        const response = await axios.put(endpoints.productCategory.updateCategory + payload?.id , payload?.value);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const deleteCategory =  createAsyncThunk("/category/delete" , async (payload , Thunk) =>{
    try {
        const response = await axios.delete(endpoints.productCategory.deleteCategory + payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})