
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allTransactions } from "../../../redux/states/transactions/thunk";
import { toast } from "react-toastify";
import EarningsIndex from "../../../components/SideTab/Earnings";
import { useSearchParams } from "react-router-dom";
import { allEarnings } from "../../../redux/states/earnings/thunk";

const Earnings = () => {

  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage, setTotalPage] = useState(1);
  const [transactionsAmount, setTransactionsAmount] = useState(0);

  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: q.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: q.get("page") + 1 });
    }
  };

  const getEarningsList = (search, pageNo, limit) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
    };

    dispatch(allEarnings(data))
      .then((response) => {
        const totalAmount = response?.payload?.totalAmount;
        setTransactionsAmount(totalAmount);
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getEarningsList(filter.search, filter.page, filter.limit);
  }, [filter]);

  useEffect(() => {
    setQ({ search: filter.search, page: filter.page, limit: filter.limit });
  }, []);

  return (
    <>
      <EarningsIndex
        filter={filter}
        totalPage={totalPage}
        setFilter={setFilter}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        transactionsAmount={transactionsAmount}
      />
     
    </>
  );
};

export default Earnings;
