import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Pagination, Row } from "react-bootstrap";
import Fade from "react-reveal";
import TableFilter from "./Component/TableFilter";
import TableData from "./Component/TableData";

const RequestedViewerIndex = (props) => {
  return (
    <Fade>
      <section className="users position-relative py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="px-lg-5 px-md-3">
                <div className="sectionInner h-100 position-relative">
                  <TableFilter {...props} />
                  <TableData {...props} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default RequestedViewerIndex;
