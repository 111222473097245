import React, { useState } from "react";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";
import Fade from "react-reveal";
import Addtiketmod from "../../Common/Modals/addtiketmod";
import { Link, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dummy from "../../../images/dummy.jpg";
import { toast } from "react-toastify";
import User from "../../../images/user.png";
import CustomPagination from "../../CustomPagination";

const Ticketsindex = (props) => {
  const [addproductmod, setaddproductmod] = useState(false);

  const tableData = useSelector((state) => state.tickets)?.allTickets;
  const { filter, setFilter, totalPage, getTicketList } = props;
  const dispatch = useDispatch();
  const { handleNextClick, handlePreviousClick } = props;

  // console.log(userDetails?.firstName, "");
  return (
    <Fade>
      <Addtiketmod
        show={addproductmod}
        onHide={() => setaddproductmod(false)}
        {...props}
      />
      <section className="Tiketindex_section py-3">
        <Container fluid>
          <Row>
            <div className="my-2 col-lg-12">
              <div className="cardBox h-100 position-relative bg-white">
                <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
                  <div className="left d-flex align-items-center gap-10 flex-wrap">
                    <div className="inventory_heading">
                      <h2 className="m-0 fw-bold heading">Tickets</h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                  </div>
                  <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
                    <Button
                      className="d-flex align-items-center justify-content-center gap-2 rounded-pill cstmShadow"
                      onClick={() => setaddproductmod(true)}
                    >
                      <span className="ms-2 icn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g id="ant-design:plus-outlined">
                            <path
                              id="Vector"
                              d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z"
                              fill="#9DA3BB"
                            />
                            <path
                              id="Vector_2"
                              d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z"
                              fill="#9DA3BB"
                            />
                          </g>
                        </svg>
                      </span>
                      Add Ticket
                    </Button>
                  </div>
                </div>

                <div className="Trasaction_table CommonTable p-lg-4 p-3">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="">
                        <tr>
                          <th className="border-0">Transaction ID</th>
                          <th className="border-0">Artist</th>
                          <th className="border-0">Event</th>
                          {/* <th className="border-0">Customer</th> */}
                          <th className="border-0">Amount</th>
                          <th className="border-0">Quantity</th>
                          <th className="border-0">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, index) => {
                          const userDetails = data?.userDetails;

                          console.log(userDetails, "ggvdf");
                          return (
                            <tr key={index}>
                              <td className="fw-bold">{data?.transactionId}</td>
                              <td className="border-0">
                                <div className="d-flex align-items-center gap-10 tableProfile">
                                  <div className="imgWrp">
                                    <img
                                      src={
                                        userDetails?.profileImage
                                          ? userDetails?.profileImage
                                          : User
                                      }
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6 className="m-0 fw-bold">
                                      {userDetails?.name}
                                    </h6>
                                    <p className="m-0">{userDetails?.email}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="border-0">{data?.name}</td>
                              {/* <td className="border-0">
                              <div className="d-flex align-items-center gap-10 tableProfile">
                                <div className="imgWrp">
                                  <img
                                    src="https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg"
                                    alt=""
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                                <div className="content">
                                  <h6 className="m-0 fw-bold">Jane Cooper</h6>
                                  <p className="m-0">suffescom@gmail.com</p>
                                </div>
                              </div>
                            </td> */}
                              <td className="border-0">
                                {data?.currencySymbol}{" "}
                                {Number(data?.ticketPrice).toFixed(2)}
                              </td>
                              <td className="border-0">{data?.seat}</td>
                              <td className="border-0">
                                <span className="statusLabel text-center orangeBg orangeText">
                                  {data?.status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap mt-3 paginationWrpper mt-2 bg-white pt-3">
                    <div className="left">
                      <div className="showResult d-flex align-items-center gap-10">
                        <label htmlFor="" className="form-label m-0">
                          Show Result:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          value={filter.limit}
                          onChange={(e) => setFilter({ limit: e.target.value })}
                        >
                          {[10, 20, 30, 50]?.map((itm) => (
                            <option value={itm}>{itm}</option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="right ">
                      <Pagination>
                        <Pagination.Prev onClick={handlePreviousClick} />
                        {Array.from(Array(totalPage).keys())?.map(
                          (page, index) => {
                            var pageNo = page + 1;
                            return (
                              <Pagination.Item
                                active={pageNo == filter.page}
                                onClick={() => setFilter({ page: pageNo })}
                              >
                                {pageNo}
                              </Pagination.Item>
                            );
                          }
                        )}
                        <Pagination.Next onClick={handleNextClick} />
                      </Pagination>
                    </div>
                  </div> */}

                  <CustomPagination
                    totalPage={totalPage}
                    setFilter={setFilter}
                    filter={filter}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Ticketsindex;
