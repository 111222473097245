import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {
  approveViewerForEntertainer,
  getViewerRequestList,
  rejectViewerToBeEntertainer,
} from "./thunk";

const initialState = {
  list: [],
  status: status.IDLE,
  loader: false,
  buttonLoader: false,
};

const slice = createSlice({
  initialState: { ...initialState },
  name: "viewerRequest",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getViewerRequestList.pending, (state, action) => {
      state.loader = true;
      state.status = status.LOADING;
    });
    builder.addCase(getViewerRequestList.fulfilled, (state, action) => {
      state.loader = false;
      state.status = status.SUCCEDED;
      state.list = action.payload?.data;
    });
    builder.addCase(getViewerRequestList.rejected, (state, action) => {
      state.loader = false;
      state.status = status.FAILED;
      state.list = initialState.list;
    });
    builder.addCase(approveViewerForEntertainer.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(approveViewerForEntertainer.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCEDED;
    });
    builder.addCase(approveViewerForEntertainer.rejected, (state, action) => {
      state.buttonLoader = false;
      state.status = status.FAILED;
    });
    builder.addCase(rejectViewerToBeEntertainer.pending, (state, action) => {
      state.buttonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(rejectViewerToBeEntertainer.fulfilled, (state, action) => {
      state.buttonLoader = false;
      state.status = status.SUCCEDED;
    });
    builder.addCase(rejectViewerToBeEntertainer.rejected, (state, action) => {
      state.buttonLoader = false;
      state.status = status.FAILED;
    });
  },
});

export default slice.reducer;
