import React, { useState } from "react";
import AuthLayout from "../../../layout/authLayout";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/states/auth/thunk";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLoader from "../../../elements/ButtonLoader";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ShowIcon from "../../../images/showw.png";
import HideIcon from "../../../images/hidew.png";

const LoginIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showLoader } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: async function (values) {
      const data = {
        email: values.userName,
        password: values.password,
      };
      dispatch(login(data))
        .unwrap()
        .then((res) => {
          navigate("/dashboard");
          toast.success(res.message);
        })
        .catch((err) => toast.error(err.message));
    },
    validationSchema: Yup.object({
      userName: Yup.string("E-mail must be a string")
        .email("Invalid E-mail")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(3, "Password must be greater than 3 character")
        .max(10, "Password must be less than 10 character"),
    }),
  });

  return (
    <>
      <AuthLayout>
        <div className="accountForm  my-3">
          <Form className="mt-4 text-start">
            <div className="py-2">
              <div className="position-relative icon-with-text">
                <span className="icn position-absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16699 4.16663H15.0003C15.6634 4.16663 16.2993 4.43002 16.7681 4.89886C17.2369 5.3677 17.5003 6.00358 17.5003 6.66663V14.1666C17.5003 14.8297 17.2369 15.4656 16.7681 15.9344C16.2993 16.4032 15.6634 16.6666 15.0003 16.6666H4.16699C3.50395 16.6666 2.86807 16.4032 2.39923 15.9344C1.93038 15.4656 1.66699 14.8297 1.66699 14.1666V6.66663C1.66699 6.00358 1.93038 5.3677 2.39923 4.89886C2.86807 4.43002 3.50395 4.16663 4.16699 4.16663ZM4.16699 4.99996C3.75033 4.99996 3.38366 5.14163 3.10033 5.39163L9.58366 9.58329L16.067 5.39163C15.7837 5.14163 15.417 4.99996 15.0003 4.99996H4.16699ZM9.58366 10.5916L2.60866 6.06663C2.54199 6.24996 2.50033 6.45829 2.50033 6.66663V14.1666C2.50033 14.6087 2.67592 15.0326 2.98848 15.3451C3.30104 15.6577 3.72496 15.8333 4.16699 15.8333H15.0003C15.4424 15.8333 15.8663 15.6577 16.1788 15.3451C16.4914 15.0326 16.667 14.6087 16.667 14.1666V6.66663C16.667 6.45829 16.6253 6.24996 16.5587 6.06663L9.58366 10.5916Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <input
                  type="email"
                  placeholder="ENTER EMAIL"
                  className="form-control bg-transparent ps-5"
                  onChange={formik.handleChange}
                  value={formik.values.userName}
                  onBlur={formik.handleBlur}
                  name="userName"
                  id="userName"
                />
              </div>
              {formik.touched.userName && formik.errors.userName && (
                <span className="text-danger">{formik.errors.userName}</span>
              )}
            </div>
            <div className="py-2">
              <div className="position-relative authPassInput icon-with-text d-flex align-items-center bg-transparent form-control">
                <span className="position-absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.8333 9.16663H4.16667C3.24619 9.16663 2.5 9.91282 2.5 10.8333V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V10.8333C17.5 9.91282 16.7538 9.16663 15.8333 9.16663Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.83301 9.16663V5.83329C5.83301 4.72822 6.27199 3.66842 7.0534 2.88701C7.8348 2.10561 8.89461 1.66663 9.99967 1.66663C11.1047 1.66663 12.1646 2.10561 12.946 2.88701C13.7274 3.66842 14.1663 4.72822 14.1663 5.83329V9.16663"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="ENTER PASSWORD"
                  className=" border-0 "
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  name="password"
                  id="password"
                />
                {showPassword ? (
                  <img
                    src={ShowIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <img
                    src={HideIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
              {formik.touched.password && formik.errors.password && (
                <span className="text-danger">{formik.errors.password}</span>
              )}
            </div>
            <div className="py-2">
              {showLoader ? (
                <div className="btn-wrp mt-3">
                  <Link className="SubmitBtn d-flex align-items-center justify-content-center  w-100">
                    <ButtonLoader />
                  </Link>
                </div>
              ) : (
                <div className="btn-wrp mt-3">
                  <Link
                    className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                    onClick={formik.handleSubmit}
                  >
                    LOGIN
                  </Link>
                </div>
              )}
            </div>
            <div className="py-1">
              <p className="m-0 form-text text-end">
                <Link to="/forget-password" className="form-link text-white">
                  Forgot Password?
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </AuthLayout>
    </>
  );
};
export default LoginIndex;
