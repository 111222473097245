import React, { useEffect, useState } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import PostDetailIndex from "../../../components/SideTab/PostDetail/Index";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  postDetails,
  allLikes,
  allComments,
  allShares,
  allTips,
} from "../../../redux/states/posts/selectedPost/thunk";

const PostDetail = () => {
  const [search, setSearch] = useState({
    like: "",
    share: "",
    comment: "",
    tip: "",
  });

  const dispatch = useDispatch();

  const { slug, postId } = useParams();
  const selectedPostDetails = useSelector(
    (state) => state.selectedPostDetails
  )?.selectedPostDetails;

  const allLikesList = useSelector(
    (state) => state.selectedPostDetails
  )?.allLikes;
  const allCommentsList = useSelector(
    (state) => state.selectedPostDetails
  )?.allComments;
  const allShareList = useSelector(
    (state) => state.selectedPostDetails
  )?.allShares;
  const allTipsList = useSelector(
    (state) => state.selectedPostDetails
  )?.allTips;

  const getPostDetails = () => {
    dispatch(postDetails(slug));
  };

  const getAllLikesList = () => {
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: 1,
      page: 1,
      search: search.like,
      postId: postId,
    };
    dispatch(allLikes(data));
  };
  const getAllCommentsList = () => {
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: 1,
      page: 1,
      search: search.comment,
      postId: postId,
    };
    dispatch(allComments(data));
  };
  const getAllSharesList = () => {
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: 1,
      page: 1,
      search: search.share,
      postId: postId,
    };
    dispatch(allShares(data));
  };
  const getAllTipsList = () => {
    const data = {
      limit: 10,
      orderBy: "createdAt",
      order: 1,
      page: 1,
      search: search.tip,
      postId: postId,
    };
    dispatch(allTips(data));
  };

  useEffect(() => {
    if (slug) {
      getPostDetails();
      getAllLikesList();
      getAllCommentsList();
      getAllSharesList();
      getAllTipsList();
    }
  }, []);

  useEffect(() => {
    if (slug) {
      getAllLikesList();
    }
  }, [search.like]);

  useEffect(() => {
    if (slug) {
      getAllCommentsList();
    }
  }, [search.comment]);

  useEffect(() => {
    if (slug) {
      getAllTipsList();
    }
  }, [search.tip]);

  useEffect(() => {
    if (slug) {
      getAllSharesList();
    }
  }, [search.share]);


  return (
    <>
      {/* <NonAuthLayout> */}
      <PostDetailIndex
        data={selectedPostDetails}
        search={search}
        setSearch={setSearch}
        allLikesList={allLikesList}
        allCommentsList={allCommentsList}
        allShareList={allShareList}
        allTipsList={allTipsList}
      />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default PostDetail;
