import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import {
  Button,
  // Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addFaq, updateFaq } from "../../../redux/states/settings/thunk";
import ButtonLoader from "../../../elements/ButtonLoader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addUser, updateUser } from "../../../redux/states/users/thunk";
import ShowIcon from "../../../images/show.png";
import HideIcon from "../../../images/hide.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddUser = (props) => {
  const { getUsersList } = props;
  const { isUpdate, showButtonLoader, selectedUser } = useSelector(
    (state) => state.users
  );

  const [showPassword, setShowPassword] = useState(false);

  const onHide = () => {
    props.onHide();
    formik.resetForm();
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNumber: "",
      countryCode: "+91",
      password: "",
      gender: "",
      userName: "",
      role: "USER",
    },
    onSubmit: async function (values, { resetForm }) {
      if (isUpdate) {
        const { password, ...newValues } = values;
        const data =
          values.password !== ""
            ? { ...values, userId: selectedUser?._id }
            : { ...newValues, userId: selectedUser?._id };
        dispatch(updateUser(data))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            props.onHide();
            getUsersList();
            resetForm();
          })
          .catch((err) => {
            // onHide()
            toast.error(err.message);
          });
      } else {
        dispatch(addUser(values))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            props.onHide();
            getUsersList();
            resetForm();
          })
          .catch((err) => {
            // onHide()
            toast.error(err.message);
          });
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required").email("Invalid E-mail"),
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .min(10, "Mobile No. must be of 10 digit")
        .max(10, "Mobile No. must be of 10 digit"),
      password: isUpdate
        ? Yup.string()
        : Yup.string()
            .required("Password is required")
            .min(3, "Password must be greater than 3 character")
            .max(10, "Password must be less than 10 character"),
      userName: Yup.string().required("Username is required"),
      gender: Yup.string().required("Gender is required"),
    }),
  });

  const updateInputValue = () => {
    formik.setValues({
      name: selectedUser?.name || "",
      email: selectedUser?.email || "",
      mobileNumber: selectedUser?.mobileNumber || "",
      countryCode: selectedUser?.countryCode,
      password: "",
      userName: selectedUser?.userName || "",
      role: "USER",
      gender: selectedUser?.gender,
    });
  };

  useEffect(() => {
    if (isUpdate) {
      updateInputValue();
    }
  }, [isUpdate]);

  const handlePhoneNumberChange = (value, country) => {
    formik.setFieldValue("countryCode", "+" + country?.dialCode);
    formik.setFieldValue(
      "mobileNumber",
      value.substring(country?.dialCode.length)
    );
  };

  return (
    <Modal {...props} backdrop="static" keyboard={false} centered size="md">
      <Modal.Header className="border-0 position-relative">
        <Modal.Title className="w-100 text-black fs-6 text-start fw-bold py-1 px-3">
          {isUpdate ? "Update" : "Add"} Viewer
        </Modal.Title>
        <a href="javascript:void(0)" onClick={onHide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <g id="material-symbols:cancel-outline">
              <path
                id="Vector"
                d="M9.79967 19.8333L13.9997 15.6333L18.1997 19.8333L19.833 18.2L15.633 14L19.833 9.80001L18.1997 8.16668L13.9997 12.3667L9.79967 8.16668L8.16634 9.80001L12.3663 14L8.16634 18.2L9.79967 19.8333ZM13.9997 25.6667C12.3858 25.6667 10.8691 25.3602 9.44967 24.7473C8.03023 24.1345 6.79551 23.3034 5.74551 22.2542C4.69551 21.2042 3.86445 19.9695 3.25234 18.55C2.64023 17.1306 2.33379 15.6139 2.33301 14C2.33301 12.3861 2.63945 10.8695 3.25234 9.45001C3.86523 8.03057 4.69629 6.79584 5.74551 5.74584C6.79551 4.69584 8.03023 3.86479 9.44967 3.25268C10.8691 2.64057 12.3858 2.33412 13.9997 2.33334C15.6136 2.33334 17.1302 2.63979 18.5497 3.25268C19.9691 3.86557 21.2038 4.69662 22.2538 5.74584C23.3038 6.79584 24.1353 8.03057 24.7482 9.45001C25.3611 10.8695 25.6671 12.3861 25.6663 14C25.6663 15.6139 25.3599 17.1306 24.747 18.55C24.1341 19.9695 23.3031 21.2042 22.2538 22.2542C21.2038 23.3042 19.9691 24.1356 18.5497 24.7485C17.1302 25.3614 15.6136 25.6675 13.9997 25.6667ZM13.9997 23.3333C16.6052 23.3333 18.8122 22.4292 20.6205 20.6208C22.4288 18.8125 23.333 16.6056 23.333 14C23.333 11.3945 22.4288 9.18751 20.6205 7.37918C18.8122 5.57084 16.6052 4.66668 13.9997 4.66668C11.3941 4.66668 9.18717 5.57084 7.37884 7.37918C5.57051 9.18751 4.66634 11.3945 4.66634 14C4.66634 16.6056 5.57051 18.8125 7.37884 20.6208C9.18717 22.4292 11.3941 23.3333 13.9997 23.3333Z"
                fill="#202020"
              ></path>
            </g>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Form className="py-1 px-3 add_faqform">
          <FormGroup>
            <Label for="exampleEmail">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              name="name"
              id="name"
              placeholder="Enter Name"
            />
            {formik.touched.name && formik.errors.name && (
              <span className="text-danger">{formik.errors.name}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">
              UserName <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              onChange={formik.handleChange}
              value={formik.values.userName}
              onBlur={formik.handleBlur}
              name="userName"
              id="userName"
              placeholder="Enter Username"
            />
            {formik.touched.userName && formik.errors.userName && (
              <span className="text-danger">{formik.errors.userName}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">
              Password <span className="text-danger">*</span>
            </Label>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                name="password"
                id="password"
              />
              <InputGroupText onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <img src={HideIcon} /> : <img src={ShowIcon} />}
              </InputGroupText>
            </InputGroup>

            {formik.touched.password && formik.errors.password && (
              <span className="text-danger">{formik.errors.password}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              name="email"
              id="email"
              placeholder="Enter E-mail"
            />
            {formik.touched.email && formik.errors.email && (
              <span className="text-danger">{formik.errors.email}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Mobile No.</Label>
            {/* <Input
              type="number"
              onChange={formik.handleChange}
              value={formik.values.mobileNumber}
              onBlur={formik.handleBlur}
              name="mobileNumber"
              id="mobileNumber"
              placeholder="Enter Mobile No."
            /> */}
            <PhoneInput
              country={"in"}
              value={formik.values.countryCode + formik.values.mobileNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter Phone No."
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <span className="text-danger">{formik.errors.mobileNumber}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="">
              Gender <span className="text-danger">*</span>
            </Label>
            <Form.Select
              className="form-control"
              aria-label="Default select example"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gender}
              name="gender"
            >
              <option value="">Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="others">Others</option>
            </Form.Select>
            {formik.touched.gender && formik.errors.gender && (
              <span className="text-danger">{formik.errors.gender}</span>
            )}
          </FormGroup>

          {showButtonLoader ? (
            <Button className="bg-dark border-dark rounded py-2 text-white w-100 mt-4">
              <ButtonLoader color={"text-light"} />
            </Button>
          ) : (
            <Button
              onClick={formik.handleSubmit}
              className="bg-dark border-dark rounded py-2 text-white w-100 mt-4"
            >
              {isUpdate ? "Update" : "Submit"}
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;
