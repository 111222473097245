import React from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Productindex from "../../../components/SideTab/Products";
import Addproduct from "../../../components/SideTab/Inventory/Component/Addproduct";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";

const addProducts = () => {
  return (
    <>
      {/* <NonAuthLayout> */}
      <section className="Prodcut-section py-3">
        <Container fluid>
          <Addproduct />
        </Container>
      </section>
      {/* </NonAuthLayout> */}
    </>
  );
};

export default addProducts;
