import React from "react";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";
import Fade from "react-reveal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomPagination from "../../CustomPagination";

const EarningsIndex = (props) => {
  const tableData = useSelector((state) => state.earnings)?.allEarningsList;
  const { filter, setFilter, totalPage, transactionsAmount } = props;
  const { handleNextClick, handlePreviousClick } = props;

  return (
    <Fade>
      <section className="Trasactionsindex_section py-3">
        <Container fluid>
          <Row>
            <div className="my-2 col-lg-12">
              <div className="px-lg-5 px-md-3">
                <div className="sectionInner h-100 position-relative">
                  <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
                    <div className="left d-flex align-items-center gap-10 flex-wrap">
                      <div className="inventory_heading">
                        <h2 className="m-0 fw-bold heading">Earnings</h2>
                      </div>
                    </div>
                    <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
                      <div className="Total_Earning text-center btn d-flex gap-10 w-100 cstmShadow align-items-center justify-content-center rounded-pill">
                        <p>Total Earning</p>
                        <h3>$ {Number(transactionsAmount).toFixed(2)}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="Trasaction_table CommonTable">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="">
                          <tr>
                            <th className="border-0">S.No</th>
                            <th className="border-0">Title</th>
                            <th className="border-0">Amount</th>
                            <th className="border-0">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, idx) => {
                            return (
                              <tr>
                                <td className="border-0">
                                  {" "}
                                  {filter.page > 1
                                    ? filter?.limit * (filter?.page - 1) +
                                      idx +
                                      1
                                    : idx + 1}
                                </td>
                                <td className="border-0">{data?.text}</td>
                                <td className="border-0">
                                  {data?.currencySymbol}{" "}
                                  {Number(data?.amount)?.toFixed(2)}
                                </td>
                                <td className="border-0">
                                  {moment(data?.createdAt)
                                    .utcOffset("+00:00")
                                    .format("MMMM D, YYYY, hh:mm A")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap mt-3 paginationWrpper mt-2 bg-white pt-3">
                      <div className="left">
                        <div className="showResult d-flex align-items-center gap-10">
                          <label htmlFor="" className="form-label m-0">
                            Show Result:
                          </label>
                          <Form.Select
                            aria-label="Default select example"
                            value={filter.limit}
                            onChange={(e) =>
                              setFilter({ limit: e.target.value })
                            }
                          >
                            {[10, 20, 30, 50]?.map((itm) => (
                              <option value={itm}>{itm}</option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="right ">
                        <Pagination>
                          <Pagination.Prev onClick={handlePreviousClick} />
                          {Array.from(Array(totalPage).keys())?.map(
                            (page, index) => {
                              var pageNo = page + 1;
                              return (
                                <Pagination.Item
                                  active={pageNo == filter.page}
                                  onClick={() => setFilter({ page: pageNo })}
                                >
                                  {pageNo}
                                </Pagination.Item>
                              );
                            }
                          )}

                          <Pagination.Next onClick={handleNextClick} />
                        </Pagination>
                      </div>
                    </div> */}

                    <CustomPagination
                      totalPage={totalPage}
                      setFilter={setFilter}
                      filter={filter}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default EarningsIndex;
