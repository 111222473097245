import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";

const CustomPagination = ({ totalPage, setFilter, filter }) => {
  //   const [filter, setFilter] = useState({ page: 1, limit: 10 });

  const handlePageChange = (pageNo) => {
    setFilter({ ...filter, page: pageNo });
  };

  const handleLimitChange = (e) => {
    setFilter({ ...filter, limit: parseInt(e.target.value), page: 1 }); // Reset to page 1 when limit changes
  };

  return (
    <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap paginationWrapper mt-2 bg-white pt-3">
      {/* Left Section for Limit */}
      <div className="left">
        <div className="showResult d-flex align-items-center gap-10">
          <label htmlFor="resultSelect" className="form-label m-0">
            Show Result:
          </label>
          <Form.Select
            id="resultSelect"
            aria-label="Select number of results"
            value={filter.limit}
            onChange={handleLimitChange}
          >
            {[10, 20, 30, 50].map((itm) => (
              <option key={itm} value={itm}>
                {itm}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>

      {/* Right Section for Pagination */}
      <div className="right">
        <Pagination>
          {/* First Page Button */}
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={filter.page === 1}
          />
          {/* Previous Page Button */}
          <Pagination.Prev
            onClick={() => handlePageChange(Math.max(filter.page - 1, 1))}
            disabled={filter.page === 1}
          />

          {/* Current Page Display */}
          <Pagination.Item active>{filter.page}</Pagination.Item>

          {/* Next Page Button */}
          <Pagination.Next
            onClick={() =>
              handlePageChange(Math.min(filter.page + 1, totalPage))
            }
            disabled={filter.page === totalPage}
          />
          {/* Last Page Button */}
          <Pagination.Last
            onClick={() => handlePageChange(totalPage)}
            disabled={filter.page === totalPage}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default CustomPagination;
