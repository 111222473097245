import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { addCategory, allCategory, deleteCategory, updateCategory } from "./thunk";


const initialState = {
    allCategory : [] ,
    status : status.IDLE,
    showLoader : false ,
    selectedCategory : {},
    isUpdate : false,
    showButtonLoader : false
}

const slice = createSlice({
    name : "category",
    initialState : {...initialState},
    reducers : {
        removeAllCategory : (proxy , action) =>{
            return initialState
        },
        editCategory : (state , action) =>{
          state.selectedCategory = action.payload ;
          state.isUpdate = true
        } ,
        clearSelected : (state , action) =>{
          state.selectedCategory = initialState.selectedCategory
          state.isUpdate = false
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allCategory.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allCategory.fulfilled , (state,action) =>{
            state.allCategory = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allCategory.rejected , (state , action) =>{
            state.allCategory = initialState.allCategory;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(addCategory.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
        });
        builder.addCase(addCategory.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
        });
        builder.addCase(addCategory.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
        });
    
        builder.addCase(updateCategory.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
          state.isUpdate = true;
        });
        builder.addCase(updateCategory.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
          state.isUpdate = false;
          state.selectedCategory = {};
        });
        builder.addCase(updateCategory.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
          state.isUpdate = false;
          state.selectedCategory = {};
        });
    
        builder.addCase(deleteCategory.pending, (state, action) => {
          state.showButtonLoader = true;
          state.status = status.LOADING;
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
          state.status = status.SUCCEDED;
          state.showButtonLoader = false;
        });
        builder.addCase(deleteCategory.rejected, (state, action) => {
          state.status = status.FAILED;
          state.showButtonLoader = false;
        });
    }
});

export default slice.reducer;
export const {removeAllCategory , editCategory , clearSelected} = slice.actions;