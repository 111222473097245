import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const uploadImg = createAsyncThunk(
  "/upload/image",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(endpoints.upload.uploadImg, payload);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
