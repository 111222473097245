import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
// images
import Uploathumb from "../../../../src/images/upload_thunmnail.png";
import Player from "../../../../src/images/player.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import ButtonLoader from "../../../elements/ButtonLoader";
import { uploadImg } from "../../../redux/states/common/thunk";
import { toast } from "react-toastify";
import { addTicket } from "../../../redux/states/tickets/thunk";

const Addtiketmod = (props) => {
  const dispatch = useDispatch();
  const [selectedImgInput, setSelectedImgInput] = useState("");
  const { imageLoader } = useSelector((state) => state.common);
  const user = useSelector((state) => state.auth)?.user;
  const { showButtonLoader } = useSelector((state) => state.tickets);
  const artistList = useSelector((state) => state.artists)?.allArtists;
  const currentDate = new Date().toISOString().split("T")[0];

  const [artisanName, setArtisanName] = useState("");

  const formik = useFormik({
    initialValues: {
      userId: "",
      name: "",
      eventDate: "",
      eventStartTime: "",
      eventEndTime: "",
      category: "",
      eventAddress: "",
      lat: "15.234",
      lng: "32.4234",
      description: "",
      ticketPrice: "",
      seat: "",
      timezone: "",
      mainImgUrl: "",
      secondImgUrl: "",
      thirdImgUrl: "",
    },
    onSubmit: async function (values, { resetForm }) {
      const { eventStartTime, eventEndTime, eventDate, ...newValues } = values;
      const data = {
        ...newValues,
        eventStartTime: eventDate + " " + eventStartTime,
        eventEndTime: eventDate + " " + eventEndTime,
      };
      dispatch(addTicket(data))
        .unwrap()
        .then((response) => {
          toast.success(response?.message);
          resetForm();
          props.getTicketList();
        })
        .catch((err) => toast(err.message));
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      userId: Yup.string().required("Artisan Name is required"),
      eventDate: Yup.string().required("Date is required"),
      eventStartTime: Yup.string().required("Start time is required"),
      eventEndTime: Yup.string().required("End time is required"),
      category: Yup.string().required("Category is required"),
      eventAddress: Yup.string().required("Location is required"),
      description: Yup.string().required("Description is required"),
      ticketPrice: Yup.number()
        .required("Ticket Price is required")
        .positive("Price cannot be negative"),
      seat: Yup.number()
        .required("Seat is required")
        .positive("Seat cannot be negative"),
      timezone: Yup.string().required("Timezone is required"),
      mainImgUrl: Yup.string().required("Please upload image"),
      secondImgUrl: Yup.string().required("Please upload image"),
      thirdImgUrl: Yup.string().required("Please upload image"),
    }),
  });

  console.log(formik.values);

  const handleImage = (e, position) => {
    setSelectedImgInput(position);
    const files = e.target.files[0];
    if (files) {
      const formData = new FormData();
      formData.append("file", files);
      dispatch(uploadImg(formData))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          formik.setFieldValue(position, res.data?.url);
          setSelectedImgInput("");
        })
        .catch((err) => {
          setSelectedImgInput("");
          toast.error(err.message);
        });
    }
  };

  const getTimeZone = async () => {
    const timeZone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
    formik.setFieldValue("timezone", timeZone);
  };

  const handleArtisaChange = (name) => {
    const selectedArtistId = artistList.find((itm) => itm?.userName === name);
    setArtisanName(name);
    formik.setFieldValue("userId", selectedArtistId?._id);
  };

  useEffect(() => {
    getTimeZone();
  }, []);

  return (
    <section className="addtiketmod_sec">
      <Offcanvas
        {...props}
        show={props.show}
        onHide={props.onHide}
        placement="end"
        className="tiketcanvas"
      >
        <Offcanvas.Header className="position-relative">
          <Offcanvas.Title>Add Tickets</Offcanvas.Title>
          <a href="javascript:void(0)" onClick={props.onHide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g id="material-symbols:cancel-outline">
                <path
                  id="Vector"
                  d="M9.79967 19.8333L13.9997 15.6333L18.1997 19.8333L19.833 18.2L15.633 14L19.833 9.80001L18.1997 8.16668L13.9997 12.3667L9.79967 8.16668L8.16634 9.80001L12.3663 14L8.16634 18.2L9.79967 19.8333ZM13.9997 25.6667C12.3858 25.6667 10.8691 25.3602 9.44967 24.7473C8.03023 24.1345 6.79551 23.3034 5.74551 22.2542C4.69551 21.2042 3.86445 19.9695 3.25234 18.55C2.64023 17.1306 2.33379 15.6139 2.33301 14C2.33301 12.3861 2.63945 10.8695 3.25234 9.45001C3.86523 8.03057 4.69629 6.79584 5.74551 5.74584C6.79551 4.69584 8.03023 3.86479 9.44967 3.25268C10.8691 2.64057 12.3858 2.33412 13.9997 2.33334C15.6136 2.33334 17.1302 2.63979 18.5497 3.25268C19.9691 3.86557 21.2038 4.69662 22.2538 5.74584C23.3038 6.79584 24.1353 8.03057 24.7482 9.45001C25.3611 10.8695 25.6671 12.3861 25.6663 14C25.6663 15.6139 25.3599 17.1306 24.747 18.55C24.1341 19.9695 23.3031 21.2042 22.2538 22.2542C21.2038 23.3042 19.9691 24.1356 18.5497 24.7485C17.1302 25.3614 15.6136 25.6675 13.9997 25.6667ZM13.9997 23.3333C16.6052 23.3333 18.8122 22.4292 20.6205 20.6208C22.4288 18.8125 23.333 16.6056 23.333 14C23.333 11.3945 22.4288 9.18751 20.6205 7.37918C18.8122 5.57084 16.6052 4.66668 13.9997 4.66668C11.3941 4.66668 9.18717 5.57084 7.37884 7.37918C5.57051 9.18751 4.66634 11.3945 4.66634 14C4.66634 16.6056 5.57051 18.8125 7.37884 20.6208C9.18717 22.4292 11.3941 23.3333 13.9997 23.3333Z"
                  fill="#202020"
                />
              </g>
            </svg>
          </a>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="titket_Add_form">
            <Form>
              <FormGroup>
                <Label for="exampleEmail">
                  Artisan name<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="select"
                  onChange={(e) => handleArtisaChange(e.target.value)}
                  value={artisanName}
                  onBlur={formik.handleBlur}
                  name="artisanName"
                  id="artisanName"
                  placeholder="Enter Name"
                >
                  <option>Select Artist</option>
                  {artistList?.map((artist, index) => {
                    return (
                      <option key={index} value={artist?.userName}>
                        {artist.userName}
                      </option>
                    );
                  })}
                </Input>
                {formik.touched.userId && formik.errors.userId && (
                  <span className="text-danger">{formik.errors.userId}</span>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">
                  Event Name<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  name="name"
                  id="name"
                  placeholder="Enter event name"
                />
                {formik.touched.name && formik.errors.name && (
                  <span className="text-danger">{formik.errors.name}</span>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">
                  Event Date<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.eventDate}
                  onBlur={formik.handleBlur}
                  name="eventDate"
                  id="eventDate"
                  min={currentDate}
                  placeholder="choose date"
                />
                {formik.touched.eventDate && formik.errors.eventDate && (
                  <span className="text-danger">{formik.errors.eventDate}</span>
                )}
              </FormGroup>

              <Row>
                <Label for="exampleEmail">
                  Event Time<span className="cumplusry">*</span>
                </Label>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="exampleEmail">From</Label>
                    <Input
                      type="time"
                      onChange={formik.handleChange}
                      value={formik.values.eventStartTime}
                      onBlur={formik.handleBlur}
                      name="eventStartTime"
                      id="eventStartTime"
                    />
                    {formik.touched.eventStartTime &&
                      formik.errors.eventStartTime && (
                        <span className="text-danger">
                          {formik.errors.eventStartTime}
                        </span>
                      )}
                  </FormGroup>
                </Col>

                <Col lg={6}>
                  <FormGroup>
                    <Label for="exampleEmail">To</Label>
                    <Input
                      type="time"
                      onChange={formik.handleChange}
                      value={formik.values.eventEndTime}
                      onBlur={formik.handleBlur}
                      name="eventEndTime"
                      id="eventEndTime"
                    />
                    {formik.touched.eventEndTime &&
                      formik.errors.eventEndTime && (
                        <span className="text-danger">
                          {formik.errors.eventEndTime}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="exampleEmail">
                  Category<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="select"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  onBlur={formik.handleBlur}
                  name="category"
                  id="category"
                >
                  <option value="sports & fitness">Sports & Fitness</option>
                  <option value="Original Research">Original Research</option>
                </Input>
                {formik.touched.category && formik.errors.category && (
                  <span className="text-danger">{formik.errors.category}</span>
                )}
              </FormGroup>

              <FormGroup className="">
                <Label for="exampleEmail">
                  Location<span className="cumplusry">*</span>
                </Label>
                <div className="position-relative icon-with-text">
                  <Input
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.eventAddress}
                    onBlur={formik.handleBlur}
                    name="eventAddress"
                    id="eventAddress"
                    className="position-relative"
                    placeholder="Enter Location"
                  />

                  <div className="location_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="23"
                      viewBox="0 0 17 23"
                      fill="none"
                    >
                      <path
                        id="Mask"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.548828 8.37308C0.548828 12.752 5.78503 22.2889 8.54883 22.2889C11.3126 22.2889 16.5488 12.752 16.5488 8.37308C16.5488 3.84501 12.9723 0.165085 8.54883 0.165085C4.12536 0.165085 0.548828 3.84501 0.548828 8.37308ZM11.9262 15.8337C10.523 18.3894 8.94799 20.2776 8.54883 20.2776C8.14966 20.2776 6.57462 18.3894 5.17147 15.8337C3.61241 12.9942 2.54883 10.0195 2.54883 8.37308C2.54883 4.94563 5.24031 2.17634 8.54883 2.17634C11.8573 2.17634 14.5488 4.94563 14.5488 8.37308C14.5488 10.0195 13.4852 12.9942 11.9262 15.8337ZM8.54883 12.2326C6.33969 12.2326 4.54883 10.4317 4.54883 8.2101C4.54883 5.98853 6.33969 4.18759 8.54883 4.18759C10.758 4.18759 12.5488 5.98853 12.5488 8.2101C12.5488 10.4317 10.758 12.2326 8.54883 12.2326ZM10.5488 8.2101C10.5488 9.32089 9.6534 10.2214 8.54883 10.2214C7.44426 10.2214 6.54883 9.32089 6.54883 8.2101C6.54883 7.09932 7.44426 6.19885 8.54883 6.19885C9.6534 6.19885 10.5488 7.09932 10.5488 8.2101Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {formik.touched.eventAddress && formik.errors.eventAddress && (
                  <span className="text-danger">
                    {formik.errors.eventAddress}
                  </span>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">Event Details</Label>
                <Input
                  type="textarea"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  name="description"
                  id="description"
                  placeholder="Enter the event details."
                />
                {formik.touched.description && formik.errors.description && (
                  <span className="text-danger">
                    {formik.errors.description}
                  </span>
                )}
              </FormGroup>

              <Col lg={8}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Ticket Price<span className="cumplusry">*</span>
                  </Label>
                  <Input
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.ticketPrice}
                    onBlur={formik.handleBlur}
                    name="ticketPrice"
                    id="ticketPrice"
                    placeholder="Enter the amount"
                  />
                  {formik.touched.ticketPrice && formik.errors.ticketPrice && (
                    <span className="text-danger">
                      {formik.errors.ticketPrice}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg={8}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Number of seats<span className="cumplusry">*</span>
                  </Label>
                  <Input
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.seat}
                    onBlur={formik.handleBlur}
                    name="seat"
                    id="seat"
                    placeholder="Enter available seat"
                  />
                  {formik.touched.seat && formik.errors.seat && (
                    <span className="text-danger">{formik.errors.seat}</span>
                  )}
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup>
                  <Label for="exampleEmail">Upload Media</Label>

                  <div className="canva_upload_media d-flex align-items-center justify-content-start gap-5">
                    <div className="product_img-upload">
                      <div class="upload-btn-wrapper">
                        <button class="btnwrap">
                          {imageLoader && selectedImgInput === "mainImgUrl" ? (
                            <ButtonLoader />
                          ) : (
                            <img
                              src={
                                formik.values.mainImgUrl
                                  ? formik.values.mainImgUrl
                                  : Uploathumb
                              }
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={(e) => handleImage(e, "mainImgUrl")}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      {formik.touched.mainImgUrl &&
                        formik.errors.mainImgUrl && (
                          <span className="text-danger">
                            {formik.errors.mainImgUrl}
                          </span>
                        )}
                    </div>

                    <ul className="uploadded_imges p-0 m-0 d-flex gap-5">
                      <li>
                        <div className="canva_upload_media d-flex align-items-center justify-content-start gap-5">
                          <div className="product_img-upload">
                            <div class="upload-btn-wrapper">
                              <button class="btnwrap">
                                {imageLoader &&
                                selectedImgInput === "secondImgUrl" ? (
                                  <ButtonLoader />
                                ) : (
                                  <img
                                    src={
                                      formik.values.secondImgUrl
                                        ? formik.values.secondImgUrl
                                        : Uploathumb
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => handleImage(e, "secondImgUrl")}
                                accept="image/png, image/gif, image/jpeg"
                              />
                            </div>
                            {formik.touched.secondImgUrl &&
                              formik.errors.secondImgUrl && (
                                <span className="text-danger">
                                  {formik.errors.secondImgUrl}
                                </span>
                              )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="canva_upload_media d-flex align-items-center justify-content-start gap-5">
                          <div className="product_img-upload">
                            <div class="upload-btn-wrapper">
                              <button class="btnwrap">
                                {imageLoader &&
                                selectedImgInput === "thirdImgUrl" ? (
                                  <ButtonLoader />
                                ) : (
                                  <img
                                    src={
                                      formik.values.thirdImgUrl
                                        ? formik.values.thirdImgUrl
                                        : Uploathumb
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => handleImage(e, "thirdImgUrl")}
                                accept="image/png, image/gif, image/jpeg"
                              />
                            </div>
                            {formik.touched.thirdImgUrl &&
                              formik.errors.thirdImgUrl && (
                                <span className="text-danger">
                                  {formik.errors.thirdImgUrl}
                                </span>
                              )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </FormGroup>
              </Col>

              <div className="Button_group d-flex align-items-center justify-content-between gap-3 my-4">
                <Button
                  onClick={formik.resetForm}
                  className="rounded w-50 border-dark bg-white py-3 text-dark fw-bold"
                >
                  Reset
                </Button>

                {showButtonLoader ? (
                  <Button className="rounded bg-dark w-50 py-3 border-dark text-white fw-bold">
                    <ButtonLoader color={"text-light"} />
                  </Button>
                ) : (
                  <Button
                    onClick={formik.handleSubmit}
                    className="rounded bg-dark w-50 py-3 border-dark text-white fw-bold"
                  >
                    Create
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Addtiketmod;
