import React, { useEffect , useState } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import DashboardIndex from "../../../components/SideTab/Dashboard/Index";
import { useDispatch, useSelector } from "react-redux";
import { allDashboardData, allTopArtists, allTopFans } from "../../../redux/actions";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const Dashboard = () => {

  const dispatch = useDispatch();
  const [totalPage, setTotalPage] = useState(1);
  const [query, setQuery] = useSearchParams();

  const {selectedTab} = useSelector((state) => state.dashboard);

  const filter = {
    tab: selectedTab,
    page: query.get("page") || 1,
    limit: query.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQuery({
      tab: params.tab,
      page: params.page || 1,
      limit: params.limit || 10,
    });
  };

  const getDashboardData = () =>{
    const val = {}
    dispatch(allDashboardData(val))
    .unwrap()
    .then((res) => {})
    .catch((err) => toast.error(err.message))
  }

  const getTopArtistList = () =>{
    const data ={
      "limit": query.get("limit"),
      "orderBy": "followerCount",
      "order": -1,
      "page": query.get("page")
  }
    dispatch(allTopArtists(data))
    .then((response) => {
      const totalCount = response?.payload?.totalcount;
      if (totalCount > 10) {
        const totalpage = totalCount / filter.limit;
        setTotalPage(Math.ceil(totalpage));
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  }

  const getTopFansList = () =>{
    const data ={
      "limit": query.get("limit"),
      "orderBy": "followerCount",
      "order": -1,
      "page": query.get("page")
  }
    dispatch(allTopFans(data))
    .then((response) => {
      const totalCount = response?.payload?.totalcount;
      if (totalCount > 10) {
        const totalpage = totalCount / filter.limit;
        setTotalPage(Math.ceil(totalpage));
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  }

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: query.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: query.get("page") + 1 });
    }
  };

  useEffect(() =>{
    getDashboardData()
  },[])

  useEffect(() =>{
    getTopFansList()
    getTopArtistList()
  }, [query.get("page"), query.get("limit"), selectedTab]);


  return (
    <>
      {/* <NonAuthLayout> */}
        <DashboardIndex 
          tab={selectedTab}
          filter={filter}
          setFilter={setFilter}
          setQuery={setQuery}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          getTopArtistList={getTopArtistList}
          getTopFansList={getTopFansList}
        />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Dashboard;
