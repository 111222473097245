import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TableFilter = () => {

  const navigate = useNavigate()
  return (
    <>
      <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
        <div className="left d-flex align-items-center gap-10 flex-wrap">
          <div className="btnWrpper">
            <Button className="d-flex cstmShadow align-items-center justify-content-center rounded-pill " onClick={() => navigate(-1)}>
              <span className="me-2 icn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M4.375 10.5H16.625M4.375 10.5L9.625 15.75M4.375 10.5L9.625 5.25"
                    stroke="#9DA3BB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              Back
            </Button>
          </div>
          <h2 className="m-0 fw-bold heading">Posts Management</h2>
          {/* <div className="searchForm position-relative icon-with-text">
            <input
              type="text"
              placeholder="Search by Name, email"
              className="form-control rounded-pill cstmShadow"
            />
            <span className="position-absolute icn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.75 13.75L12.5 12.5"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TableFilter;
