import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import Addsubmin from "./addsubmin";
import Addpermission from "./addpermission";
import Fade from "react-reveal";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup,
  InputGroupText,
} from "reactstrap";
// images
import Preview from "../../../images/preview.png";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  addSubAdmin,
  subAdminDetails,
  updateSubAdmin,
} from "../../../redux/states/subAdmin/thunk";
import { toast } from "react-toastify";
import ButtonLoader from "../../../elements/ButtonLoader";
import { useParams, useSearchParams } from "react-router-dom";
import Edit from "../../../images/edit.png";
import ShowIcon from "../../../images/show.png";
import HideIcon from "../../../images/hide.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";

const EditSubAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subAdminDetailsdta, isUpdate, showButtonLoader } = useSelector(
    (state) => state.subAdmin
  );

  const subAdminDta = subAdminDetailsdta;

  const [query, setQuery] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const { subAdminId } = useParams();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      mobileNumber: "",
      role: "SUBADMIN",
      countryCode: "",
      name: "",
      status: "inactive",
      permissions: [],
    },
    onSubmit: async function (values, { resetForm }) {
      const { password, ...newValues } = values;
      const data =
        values.password !== ""
          ? { ...values, userId: subAdminId }
          : { ...newValues, userId: subAdminId };

      if (values.permissions.length !== 0) {
        dispatch(updateSubAdmin(data))
          .unwrap()
          .then((response) => {
            navigate("/sub-admin");
            toast.success(response.message);
            resetForm();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        toast.warning("Please select permission");
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      mobileNumber: Yup.string()
        .matches(/^\d{10}$/, "Mobile number must be 10 digits")
        .required("Mobile number is required"),
      email: Yup.string()
        .email("Invalid E-mail")
        .required("E-mail is required"),
      password: Yup.string(),
    }),
  });

  const permission = formik?.values?.permissions || [];

  const handlePermissions = (arg) => {
    if (permission.includes(arg)) {
      const actualValue = permission.filter((itm) => itm !== arg);
      formik.setFieldValue("permissions", actualValue);
    } else {
      formik.setFieldValue("permissions", [...permission, arg]);
    }
  };

  const handlePhoneNumberChange = (value, country) => {
    formik.setFieldValue("countryCode", "+" + country?.dialCode);
    formik.setFieldValue(
      "mobileNumber",
      value.substring(country?.dialCode.length)
    );
  };

  const handleCloseModal = () => {
    navigate("/sub-admin");
  };

  const getSubAdminDetailddd = () => {
    dispatch(subAdminDetails(subAdminId));
  };

  useEffect(() => {
    getSubAdminDetailddd();
  }, []);

  useEffect(() => {
    if (subAdminDta) {
      formik.setValues({
        email: subAdminDta?.email,
        password: "",
        mobileNumber: subAdminDta?.mobileNumber,
        countryCode: subAdminDta?.countryCode,
        role: "SUBADMIN",
        name: subAdminDta?.name,
        status: subAdminDta?.status,
        permissions: subAdminDta?.permissions,
      });
    }
  }, [subAdminDetailsdta]);

  return (
    <section className="Trasactionsindex_section py-3">
      <Container fluid>
        <Fade>
          <section className="Addproduct_section pt-3 pb-5">
            <Container fluid>
              <Form>
                <Row>
                  <div className="my-2 col-lg-12">
                    <div className="px-lg-5 px-md-3">
                      <div className="sectionInner h-100 position-relative">
                        <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4 flex-wrap mb-2">
                          <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
                            <div className="px-2 d-flex align-items-center justify-content-start gap-3">
                              <Button
                                className="d-flex cstmShadow align-items-center justify-content-center rounded-pill gap-2"
                                onClick={handleCloseModal}
                              >
                                <span className="icn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                  >
                                    <g id="tabler:arrow-left">
                                      <path
                                        id="Vector"
                                        d="M4.375 10.5H16.625M4.375 10.5L9.625 15.75M4.375 10.5L9.625 5.25"
                                        stroke="#9DA3BB"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                Back
                              </Button>

                              <div className="">
                                <h2 className="m-0 fw-bold heading">
                                  Sub Admins
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="left d-flex align-items-center gap-10 flex-wrap">
                            <h6 className="m-0">Status</h6>

                            <div className="switch-btn">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  checked={formik.values.status === "active"}
                                  onChange={() =>
                                    formik.setFieldValue(
                                      "status",
                                      formik.values.status === "inactive"
                                        ? "active"
                                        : "inactive"
                                    )
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="submin_form p-3 p-lg-4">
                          <Row>
                            <Col md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">Name</Label>
                                <Input
                                  type="text"
                                  onChange={formik.handleChange}
                                  value={formik.values.name}
                                  onBlur={formik.handleBlur}
                                  name="name"
                                  id="name"
                                />
                                {formik.touched.name && formik.errors.name && (
                                  <span className="text-danger">
                                    {formik.errors.name}
                                  </span>
                                )}
                              </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">Mobile Number</Label>
                                {/* <Input
                            type="tel"
                            onChange={formik.handleChange}
                            value={formik.values.mobileNumber}
                            onBlur={formik.handleBlur}
                            name="mobileNumber"
                            id="mobileNumber"
                          /> */}
                                <PhoneInput
                                  country={"in"}
                                  value={
                                    formik.values.countryCode +
                                    formik.values.mobileNumber
                                  }
                                  onChange={handlePhoneNumberChange}
                                  placeholder="Enter Phone No."
                                />
                                {formik.touched.mobileNumber &&
                                  formik.errors.mobileNumber && (
                                    <span className="text-danger">
                                      {formik.errors.mobileNumber}
                                    </span>
                                  )}
                              </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">Email</Label>
                                <Input
                                  type="email"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                  onBlur={formik.handleBlur}
                                  name="email"
                                  id="email"
                                />
                                {formik.touched.email &&
                                  formik.errors.email && (
                                    <span className="text-danger">
                                      {formik.errors.email}
                                    </span>
                                  )}
                              </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">Password</Label>
                                <InputGroup>
                                  <Input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    onBlur={formik.handleBlur}
                                    name="password"
                                    id="password"
                                  />
                                  <InputGroupText
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <img src={HideIcon} />
                                    ) : (
                                      <img src={ShowIcon} />
                                    )}
                                  </InputGroupText>
                                </InputGroup>
                                {formik.touched.password &&
                                  formik.errors.password && (
                                    <span className="text-danger">
                                      {formik.errors.password}
                                    </span>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="permissson_check mt-4">
                            <Row className="align-items-center justify-content-between">
                              <Col lg={6} md={12} sm={12}>
                                <h4 className="fw-bold">Permissions</h4>
                                <div className="d-flex permisionCont border rounded">
                                  <div className="checks_enable bg-transparent">
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("dashboard")
                                          }
                                          checked={permission?.includes(
                                            "dashboard"
                                          )}
                                        />{" "}
                                        Dashboard
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("viewer")
                                          }
                                          checked={permission?.includes(
                                            "viewer"
                                          )}
                                        />{" "}
                                        Viewer
                                      </Label>
                                    </FormGroup>

                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("posts")
                                          }
                                          checked={permission?.includes(
                                            "posts"
                                          )}
                                        />{" "}
                                        Posts
                                      </Label>
                                    </FormGroup>

                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("entertainer")
                                          }
                                          checked={permission?.includes(
                                            "entertainer"
                                          )}
                                        />{" "}
                                        Entertainer
                                      </Label>
                                    </FormGroup>

                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("subscriptions")
                                          }
                                          checked={permission?.includes(
                                            "subscriptions"
                                          )}
                                        />{" "}
                                        Subscriptions
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="checks_enable bg-transparent">
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("transactions")
                                          }
                                          checked={permission?.includes(
                                            "transactions"
                                          )}
                                        />{" "}
                                        Transactions
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("categories")
                                          }
                                          checked={permission?.includes(
                                            "categories"
                                          )}
                                        />{" "}
                                        Categories
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("subadmin")
                                          }
                                          checked={permission?.includes(
                                            "subadmin"
                                          )}
                                        />{" "}
                                        SubAdmin
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissions("settings")
                                          }
                                          checked={permission?.includes(
                                            "settings"
                                          )}
                                        />{" "}
                                        Settings
                                      </Label>
                                    </FormGroup>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg={6}
                                md={6}
                                sm={12}
                                className="align-self-end"
                              >
                                {showButtonLoader ? (
                                  <Button className="w-100 rounded bg-dark py-3 mt-5">
                                    <ButtonLoader color={"text-light"} />
                                  </Button>
                                ) : (
                                  <Button
                                    className="w-100 rounded bg-dark py-3 mt-5"
                                    onClick={formik.handleSubmit}
                                  >
                                    {isUpdate ? "Update" : "Submit"}
                                  </Button>
                                )}
                              </Col>
                            </Row>

                            {/* <Row className="align-items-center justify-content-between">
                      <Col lg={3} md={6} sm={12}>
                        <h4 className="fw-bold">Permissions</h4>

                        <div className="checks_enable bg-transparent">
                          <FormGroup check>
                            <Label check className="fw-bold border-bottom pb-2">
                              <Input type="checkbox" /> User
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> view
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Create
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Delete
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <h4 className="fw-bold">Permissions</h4>

                        <div className="checks_enable bg-transparent">
                          <FormGroup check>
                            <Label check className="fw-bold border-bottom pb-2">
                              <Input type="checkbox" /> Artist
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> view
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Create
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Delete
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <h4 className="fw-bold">Permissions</h4>

                        <div className="checks_enable bg-transparent">
                          <FormGroup check>
                            <Label check className="fw-bold border-bottom pb-2">
                              <Input type="checkbox" /> Posts
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> view
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Create
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Delete
                            </Label>
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Update
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Button className="w-100 rounded bg-dark py-3 mt-5">
                          Submit
                        </Button>
                      </Col>
                    </Row> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Form>
            </Container>
          </section>
        </Fade>
      </Container>
    </section>
  );
};

export default EditSubAdmin;
