import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const getProfile = createAsyncThunk(
  "/profile/get",
  async (payload, Thunk) => {
    try {
      const response = await axios.get(endpoints.profile.getProfile + payload);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
export const updateProfile = createAsyncThunk(
  "/profile/update",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.profile.updateProfile,
        payload
      );
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
