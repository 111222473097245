import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { allEarnings } from "./thunk";


const initialState = {
    allEarningsList : [] ,
    status : status.IDLE,
    showLoader : false ,
}

const slice = createSlice({
    name : "users",
    initialState : {...initialState},
    reducers : {
        removeallEarnings : (proxy , action) =>{
            return initialState
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allEarnings.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allEarnings.fulfilled , (state,action) =>{
            state.allEarningsList = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allEarnings.rejected , (state , action) =>{
            state.allEarningsList = initialState.allEarningsList;
            state.status = status.FAILED;
            state.showLoader = false;
        })
    }
});

export default slice.reducer;
export const {removeallEarnings} = slice.actions;