import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ButtonLoader from "../../../../../elements/ButtonLoader";
import { useDispatch } from "react-redux";
import { updateBasicSetting } from "../../../../../redux/states/settings/thunk";
import { toast } from "react-toastify";

const Basicsetting = (props) => {
  const { basicSetting, showButtonLoader } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();
  const { getBasicSettingData } = props;
  const [value, setValue] = useState({
    SMS_Notification: false,
    Email_Notification: false,
  });

  useEffect(() => {
    const notification = basicSetting?.data?.[0]?.Notifi_Settings;
    setValue({
      SMS_Notification: notification?.SMS_Notification,
      Email_Notification: notification?.Email_Notification,
    });
  }, []);

  const handSubmit = () => {
    const data = {
      Notifi_Settings: { ...value },
    };
    dispatch(updateBasicSetting(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getBasicSettingData();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <section className="basic_setting mt-4">
      <Container fluid>
        <Form>
          <div className="site-options">
            <div className="site_heading">
              <h3>Site Options</h3>
              <p>
                Configure your site settings and control access to your site.{" "}
                <a href="javascript:void(0)">Learn More</a>
              </p>
            </div>

            <ul>
              <li>
                <div className="option_box my-2 py-2 border-bottom">
                  <div className="option-left">
                    <h6>Sms Configuration</h6>
                    <p className="m-0">
                      Disallow vendor and customers from accessing the wp-admin
                      dashboard area.
                    </p>
                  </div>
                  <div className="optipn-toggle">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={value.SMS_Notification}
                        onChange={() =>
                          setValue((rest) => ({
                            ...rest,
                            SMS_Notification: !rest.SMS_Notification,
                          }))
                        }
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </li>

              <li>
                <div className="option_box my-2 py-2 border-bottom">
                  <div className="option-left">
                    <h6>Mail Configuration</h6>
                    <p className="m-0">
                      Disable welcome wizard for newly registered vendors.
                    </p>
                  </div>
                  <div className="optipn-toggle">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={value.Email_Notification}
                        onChange={() =>
                          setValue((rest) => ({
                            ...rest,
                            Email_Notification: !rest.Email_Notification,
                          }))
                        }
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </li>

              {/*  <li>
                <div className="option_box my-2 py-2 border-bottom">
                  <div className="option-left">
                    <h6>Mail Configuration</h6>
                    <p className="m-0">
                      Log all ShipStation API interactions.{" "}
                      <a href="javascript:void(0)">Learn more.</a>
                    </p>
                  </div>
                  <div className="optipn-toggle">
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </li>

              <li>
                <div className="option_box my-2 py-2 border-bottom">
                  <div className="option-left">
                    <h6>Data clear</h6>
                    <p className="m-0 text-danger">
                      Delete all data and tables related to Dokan and Dokan Pro
                      while deleting the Dokan plugin.{" "}
                      <a href="javascript:void(0)">Learn more.</a>
                    </p>
                  </div>
                  <div className="optipn-toggle">
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </li>

              <li>
                <div className="option_box my-2 py-2">
                  <div className="option-left">
                    <h6>Vendor store URL</h6>
                    <p className="m-0">
                      Define vedor store name in URL <br />
                      <a href="javascript:void(0)">
                        (http://localhost/wordpress/[this-text]/[vendor-name])
                      </a>
                    </p>
                  </div>
                  <div className="optipn-toggle">
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="store name"
                      />
                    </FormGroup>
                  </div>
                </div>
              </li> */}
              <div className=" d-flex justify-content-end align-items-center">
                {showButtonLoader ? (
                  <div className="py-2 px-3 bg-primary border-primary opacity-75 fw-bold rounded">
                    <ButtonLoader color={"text-light"} />{" "}
                  </div>
                ) : (
                  <div
                    className="py-2 px-3 blackBtn fw-bold rounded text-light pointer"
                    onClick={handSubmit}
                  >
                    {" "}
                    Save Change
                  </div>
                )}
              </div>
            </ul>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default Basicsetting;
