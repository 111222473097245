import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/AuthPages/Login";
import Artists from "../pages/SideTabs/Artists/Artists";
import Dashboard from "../pages/SideTabs/Dashboard/Dashboard";
import FanAnalytics from "../pages/SideTabs/FanAnalytics/FanAnalytics";
import FanBrags from "../pages/SideTabs/FanBrags/FanBrags";
import FanLoyalty from "../pages/SideTabs/FanLoyalty/FanLoyalty";
import PostDetail from "../pages/SideTabs/Posts/PostDetail";
import Posts from "../pages/SideTabs/Posts/Posts";
import Users from "../pages/SideTabs/Users/Users";
import Products from "../pages/SideTabs/Products/Products";
import AddProduct from "../pages/SideTabs/Products/AddProducts";
import Inventory from "../pages/SideTabs/Inventory/Inventory";
import Trasactions from "../pages/SideTabs/Trasactions/Trasactions";
import Subadmin from "../pages/SideTabs/Subadmin/Subadmin";
import Tickets from "../pages/SideTabs/Tickets/Tickets";
import Settings from "../pages/SideTabs/Settings/Settings";
import Subscriptionplan from "../pages/SideTabs/Subscriptionplan/Subscriptionplan";
import PrivateRoute from "./PrivateRoute";
import EditProducts from "../pages/SideTabs/Products/EditProducts";
import ProductDetails from "../pages/SideTabs/Products/ProductDetail.jsx";
import Forget from "../pages/AuthPages/Forget";
import Reset from "../pages/AuthPages/Reset";
import Otp from "../pages/AuthPages/Otp";
import NonAuthLayout from "../layout/NonAuthLayout";
import ProductCategory from "../pages/SideTabs/Products/ProductCategory";
import AddsubAdmin from "../components/SideTab/Subadmin/AddsubAdmin";
import EditSubAdmin from "../components/SideTab/Subadmin/EditSubAdmin";
import ProfileDetail from "../pages/SideTabs/Profile/Profile";
import RequestedViewer from "../pages/SideTabs/RequestedViewer/RequestedViewer";
import ViewUserDetail from "../pages/SideTabs/Users/View/Index";
import Earnings from "../pages/SideTabs/Earnings/Earnings";
import Announcements from "../pages/SideTabs/Announcements/Announcements.jsx";

const routes = [
  { path: "/", component: <Login />, type: "public" },
  { path: "/dashboard", component: <Dashboard />, type: "public" },
  {
    path: "/users",
    component: <Users />,
    type: "public",
    link: ({ id }) => `/users/${id}`,
  },
  { path: "/artist", component: <Artists />, type: "private" },
  { path: "/posts", component: <Posts />, type: "private" },
  { path: "/posts/post-detail", component: <PostDetail />, type: "private" },
  { path: "/fan-analytics", component: <FanAnalytics />, type: "private" },
  { path: "/fan-loyalty", component: <FanLoyalty />, type: "private" },
  { path: "/fan-brags", component: <FanBrags />, type: "private" },
  { path: "/products", component: <Products />, type: "private" },
  { path: "/inventory", component: <Inventory />, type: "private" },
  { path: "/transactions", component: <Trasactions />, type: "private" },
  { path: "/sub-admin", component: <Subadmin />, type: "private" },
  { path: "/tickets", component: <Tickets />, type: "private" },
  { path: "/settings", component: <Settings />, type: "private" },
  { path: "/subscription", component: <Subscriptionplan />, type: "private" },
];

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/forget-password", component: <Forget /> },
  { path: "/reset-password", component: <Reset /> },
  { path: "/otp", component: <Otp /> },
];

const privateRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/users", component: <Users /> },

  { path: "/artist", component: <Artists /> },
  { path: "/posts", component: <Posts /> },
  { path: "/posts/post-details/:postId/:slug", component: <PostDetail /> },
  { path: "/fan-analytics", component: <FanAnalytics /> },
  { path: "/fan-loyalty", component: <FanLoyalty /> },
  { path: "/profile", component: <ProfileDetail /> },
  { path: "/fan-brags", component: <FanBrags /> },
  { path: "/products", component: <Products /> },
  { path: "/products/add", component: <AddProduct /> },
  { path: "/category", component: <ProductCategory /> },
  { path: "/products/:productId/:slug/edit", component: <EditProducts /> },
  { path: "/productDetails/:productId/:slug", component: <ProductDetails /> },
  { path: "/inventory", component: <Inventory /> },
  { path: "/transactions", component: <Trasactions /> },
  { path: "/earnings", component: <Earnings /> },
  { path: "/sub-admin", component: <Subadmin /> },
  { path: "/sub-admin/add", component: <AddsubAdmin /> },
  { path: "/sub-admin/edit/:subAdminId", component: <EditSubAdmin /> },
  { path: "/announcements", component: <Announcements /> },
  { path: "/tickets", component: <Tickets /> },
  { path: "/settings", component: <Settings /> },
  { path: "/subscription", component: <Subscriptionplan /> },
  { path: "/viewer-request", component: <RequestedViewer /> },
  { path: "/viewer-request/details/:id", component: <ViewUserDetail /> },
];

const MyRoutes = () => {
  return (
    <Routes>
      {publicRoutes?.map((item, index) => (
        <Route path={item.path} element={item.component} key={index} />
      ))}
      <Route path="/" element={<NonAuthLayout />}>
        {privateRoutes?.map((item, index) => (
          <Route
            path={item.path}
            element={<PrivateRoute>{item.component}</PrivateRoute>}
            key={index}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default MyRoutes;
