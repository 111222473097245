import React, { useState, useEffect } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import FanAnalyticsIndex from "../../../components/SideTab/FanAnalytics/Index";
import { useSearchParams, generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  allLikePost,
  allCommentPost,
  allSharePost,
  allWatchListPost,
  allUsersWatchList,
} from "../../../redux/states/fanAnalytics/thunk";
import { toast } from "react-toastify";

const FanAnalytics = () => {
  const [query, setQuery] = useSearchParams();

  const tab = useSelector((state) => state?.fanAnalytics)?.selectedTab;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(1);

  const filter = {
    tab: tab,
    page: query.get("page") || 1,
    limit: query.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQuery({
      tab: params.tab,
      page: params.page || 1,
      limit: params.limit || 10,
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: query.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: query.get("page") + 1 });
    }
  };

  useEffect(() => {
    setFilter({ tab: tab });
  }, []);

  const getLikePostList = () => {
    const data = {
      limit: query.get("limit") || 10,
      orderBy: "createdAt",
      order: 1,
      page: query.get("page" || 1),
      search: "",
    };
    dispatch(allLikePost(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getCommentPostList = () => {
    const data = {
      limit: query.get("limit") || 10,
      orderBy: "createdAt",
      order: 1,
      page: query.get("page" || 1),
      search: "",
    };
    dispatch(allCommentPost(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getSharePostList = () => {
    const data = {
      limit: query.get("limit") || 10,
      orderBy: "createdAt",
      order: 1,
      page: query.get("page" || 1),
      search: "",
    };
    dispatch(allSharePost(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getAllWatchListPost = () => {
    const data = {
      limit: query.get("limit") || 10,
      orderBy: "createdAt",
      order: 1,
      page: query.get("page" || 1),
      search: "",
    };
    dispatch(allWatchListPost(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getAllUserWatchList = () => {
    const data = {
      limit: query.get("limit") || 10,
      orderBy: "createdAt",
      order: 1,
      page: query.get("page" || 1),
      search: "",
    };
    dispatch(allUsersWatchList(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getLikePostList();
    getCommentPostList();
    getCommentPostList();
    getAllWatchListPost();
    getAllUserWatchList();
  }, [query.get("page"), query.get("limit"), tab]);

  const handlePostDetails = (dta) => {
    const path = generatePath("/posts/post-details/:postId/:slug", {
      postId: dta?._id,
      slug: dta?.slug,
    });
    navigate(path);
  };

  return (
    <>
      {/* <NonAuthLayout> */}
      <FanAnalyticsIndex
        handlePostDetails={handlePostDetails}
        tab={tab}
        filter={filter}
        setFilter={setFilter}
        setQuery={setQuery}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        getLikePostList={getLikePostList}
        getCommentPostList={getCommentPostList}
        getSharePostList={getSharePostList}
        getAllWatchListPost={getAllWatchListPost}
        getAllUserWatchList={getAllUserWatchList}
        totalPage={totalPage}
      />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default FanAnalytics;
