import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Container, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  updateCRMConfig,
  updateMailConfig,
  updateSmsConfig,
} from "../../../../../redux/states/settings/thunk";
import { toast } from "react-toastify";
import ButtonLoader from "../../../../../elements/ButtonLoader";

const Installation = (props) => {
  const { getBasicConfigData } = props;
  const dispatch = useDispatch();
  const { basicConfig, showButtonLoader } = useSelector(
    (state) => state.settings
  );

  const smsFormik = useFormik({
    initialValues: {
      accountSid: "",
      authToken: "",
      twilioFrom: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateSmsConfig(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getBasicConfigData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      accountSid: Yup.string().required("Accounts id is required"),
      authToken: Yup.string().required("Auth token  is required"),
      twilioFrom: Yup.string().required("Twilio number  is required"),
    }),
  });

  const emailFormik = useFormik({
    initialValues: {
      MAILGUN_API_KEY: "",
      MAILGUN_DOMAIN: "",
      MAILGUN_FROM: "",
    },
    onSubmit: async function (values, { resetForm }) {
      console.log("re");
      dispatch(updateMailConfig(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getBasicConfigData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      MAILGUN_API_KEY: Yup.string().required("MAILGUN_API_KEY  is required"),
      MAILGUN_DOMAIN: Yup.string().required("MAILGUN_DOMAIN  is required"),
      MAILGUN_FROM: Yup.string().required("MAILGUN NUMBER  is required"),
    }),
  });

  const crmFormik = useFormik({
    initialValues: {
      forceMerchantId: "",
    },
    onSubmit: async function (values, { resetForm }) {
      dispatch(updateCRMConfig(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          getBasicConfigData();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    validationSchema: Yup.object({
      forceMerchantId: Yup.string().required("Force Merchant Id  is required"),
    }),
  });

  useEffect(() => {
    if (basicConfig) {
      smsFormik.setValues({
        accountSid: basicConfig?.[0]?.twilio?.accountSid,
        authToken: basicConfig?.[0]?.twilio?.authToken,
        twilioFrom: basicConfig?.[0]?.twilio?.twilioFrom,
      });

      emailFormik.setValues({
        MAILGUN_API_KEY: basicConfig?.[0]?.mailgun?.MAILGUN_API_KEY,
        MAILGUN_DOMAIN: basicConfig?.[0]?.mailgun?.MAILGUN_DOMAIN,
        MAILGUN_FROM: basicConfig?.[0]?.mailgun?.MAILGUN_FROM,
      });

      crmFormik.setValues({
        forceMerchantId: basicConfig?.[0]?.crm?.forceMerchantId,
      });
    }
  }, [basicConfig]);

  return (
    <>
      <section className="dashboard py-2">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className=" card-box rounded">
                <Accordion defaultActiveKey="0" className="cstm-switch2">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="rounded-pill d-inline-flex align-items-center">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                          >
                            <path
                              d="M0.00496506 2.04441C0.00515174 1.74441 0.105307 1.49448 0.305431 1.2946C0.505555 1.09473 0.755618 0.994881 1.05562 0.995068L5.10562 0.997588C5.33895 0.997733 5.54324 1.07286 5.71848 1.22297C5.89305 1.37308 6.00526 1.56481 6.05512 1.79818L6.75297 5.24861C6.78616 5.48197 6.76535 5.71962 6.69053 5.96157C6.61505 6.20286 6.50226 6.39846 6.35216 6.54836L4.0007 8.8969C4.76658 10.164 5.67419 11.3229 6.72354 12.3736C7.77289 13.4243 8.89734 14.3 10.0969 15.0007L12.5484 12.6022C12.6985 12.4523 12.8696 12.3564 13.0616 12.3145C13.2529 12.2733 13.4653 12.2695 13.6986 12.3029L17.1982 12.9551C17.4315 12.9886 17.6231 13.097 17.773 13.2805C17.9229 13.4639 17.9977 13.6723 17.9976 13.9056L17.9951 17.9556C17.9949 18.2556 17.8947 18.5056 17.6946 18.7054C17.4945 18.9053 17.2444 19.0051 16.9444 19.005C14.7944 19.0036 12.699 18.523 10.6583 17.563C8.61691 16.6038 6.80936 15.336 5.23568 13.7597C3.66132 12.184 2.39578 10.3752 1.43905 8.33331C0.481656 6.29071 0.00362717 4.19441 0.00496506 2.04441Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        SMS Configuration
                      </div>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form>
                        <Row>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              Twilio Account SID
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              onChange={smsFormik.handleChange}
                              value={smsFormik.values.accountSid}
                              onBlur={smsFormik.handleBlur}
                              name="accountSid"
                              id="accountSid"
                              className="form-control px-2"
                            />
                            {smsFormik.touched.accountSid &&
                              smsFormik.errors.accountSid && (
                                <span className="text-danger">
                                  {smsFormik.errors.accountSid}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              Twilio Auth Token
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              onChange={smsFormik.handleChange}
                              value={smsFormik.values.authToken}
                              onBlur={smsFormik.handleBlur}
                              name="authToken"
                              id="authToken"
                              className="form-control  px-2"
                            />
                            {smsFormik.touched.authToken &&
                              smsFormik.errors.authToken && (
                                <span className="text-danger">
                                  {smsFormik.errors.authToken}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              Twilio Number
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              onChange={smsFormik.handleChange}
                              value={smsFormik.values.twilioFrom}
                              onBlur={smsFormik.handleBlur}
                              name="twilioFrom"
                              id="twilioFrom"
                              className="form-control px-2"
                            />
                            {smsFormik.touched.twilioFrom &&
                              smsFormik.errors.twilioFrom && (
                                <span className="text-danger">
                                  {smsFormik.errors.twilioFrom}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" className="my-2 align-self-end">
                            <div className=" btn-wrp d-flex algign-items-end h-100">
                              <Button
                                onClick={smsFormik.handleSubmit}
                                className="d-flex align-items-center blackBtn justify-content-center me-2 w-100 cmmn-btn2 btn btn-secondary text-white"
                              >
                                {showButtonLoader ? (
                                  <ButtonLoader color={"text-light"} />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="rounded-pill d-inline-flex align-items-center">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20 8L12 13L4 8V6L12 11L20 6M20 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        MailGun Configuration
                      </div>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form>
                        <Row>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              MailGun API key
                            </label>
                            <input
                              type="text"
                              onChange={emailFormik.handleChange}
                              value={emailFormik.values.MAILGUN_API_KEY}
                              onBlur={emailFormik.handleBlur}
                              name="MAILGUN_API_KEY"
                              id="MAILGUN_API_KEY"
                              placeholder=""
                              className="form-control px-2"
                            />
                            {emailFormik.touched.MAILGUN_API_KEY &&
                              emailFormik.errors.MAILGUN_API_KEY && (
                                <span className="text-danger">
                                  {emailFormik.errors.MAILGUN_API_KEY}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              MAILGUN DOMAIN
                            </label>
                            <input
                              type="text"
                              onChange={emailFormik.handleChange}
                              value={emailFormik.values.MAILGUN_DOMAIN}
                              onBlur={emailFormik.handleBlur}
                              name="MAILGUN_DOMAIN"
                              id="MAILGUN_DOMAIN"
                              placeholder=""
                              className="form-control"
                            />
                            {emailFormik.touched.MAILGUN_DOMAIN &&
                              emailFormik.errors.MAILGUN_DOMAIN && (
                                <span className="text-danger">
                                  {emailFormik.errors.MAILGUN_DOMAIN}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              MAILGUN FROM{" "}
                            </label>
                            <input
                              type="text"
                              onChange={emailFormik.handleChange}
                              value={emailFormik.values.MAILGUN_FROM}
                              onBlur={emailFormik.handleBlur}
                              name="MAILGUN_FROM"
                              id="MAILGUN_FROM"
                              placeholder=""
                              className="form-control"
                            />
                            {emailFormik.touched.MAILGUN_FROM &&
                              emailFormik.errors.MAILGUN_FROM && (
                                <span className="text-danger">
                                  {emailFormik.errors.MAILGUN_FROM}
                                </span>
                              )}
                          </Col>
                          <Col lg="3" className="my-2">
                            <div className="pt-4 btn-wrp d-flex algign-items-end h-100">
                              <Button
                                onClick={emailFormik.handleSubmit}
                                className="d-flex align-items-center justify-content-center me-2 w-100 cmmn-btn2 btn btn-secondary text-white"
                              >
                                {showButtonLoader ? (
                                  <ButtonLoader color={"text-light"} />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="rounded-pill d-inline-flex align-items-center">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-diagram-3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z" />
                          </svg>
                        </span>
                        Konnective CRM Payment Gateway Configuration
                      </div>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form>
                        <Row>
                          <Col lg="3" md="4" sm="6" className="my-2">
                            <label for="" className="form-label2 m-0">
                              Force Merchant Id (MIDs)
                            </label>
                            <input
                              type="text"
                              onChange={crmFormik.handleChange}
                              value={crmFormik.values.forceMerchantId}
                              onBlur={crmFormik.handleBlur}
                              name="forceMerchantId"
                              id="forceMerchantId"
                              placeholder=""
                              className="form-control px-2"
                            />
                            {crmFormik.touched.forceMerchantId &&
                              crmFormik.errors.forceMerchantId && (
                                <span className="text-danger">
                                  {crmFormik.errors.forceMerchantId}
                                </span>
                              )}
                          </Col>

                          <Col lg="3" className="my-2">
                            <div className="pt-4 btn-wrp d-flex algign-items-end h-100">
                              <Button
                                onClick={crmFormik.handleSubmit}
                                className="d-flex align-items-center justify-content-center me-2 w-100 cmmn-btn2 btn btn-secondary text-white"
                              >
                                {showButtonLoader ? (
                                  <ButtonLoader color={"text-light"} />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Installation;
