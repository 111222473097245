import React, { useState } from "react";
import Sidebar from "../components/Common/Header/Sidebar";
import Header from "../components/Common/Header/Header";
import { Outlet } from "react-router-dom";

const NonAuthLayout = ({ children }) => {
  const [sidebarShow, setShowSidebar] = useState("");

  return (
    <>
      <Sidebar sidebarShow={sidebarShow} setShowSidebar={setShowSidebar} />
      <main className="MainBody ms-auto bg-white position-relative">
        <Header sidebarShow={sidebarShow} setShowSidebar={setShowSidebar} />

        <Outlet />
      </main>
    </>
  );
};
export default NonAuthLayout;
