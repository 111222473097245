import React from "react";
import { Form, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomPagination from "../../../../../CustomPagination";

const TopArtistsIndex = (props) => {
  const { setFilter, filter, handlePreviousClick, handleNextClick } = props;
  const tableData = useSelector((state) => state.dashboard)?.topFans;

  return (
    <>
      <div className="CommonTable table2">
        <div className="table-responsive">
          <table className="table">
            <thead className="">
              <tr>
                <th className="border-0">S.No.</th>
                <th className="border-0">Name</th>
                <th className="border-0">UserName</th>

                <th className="border-0">Registered At</th>
                <th className="border-0">Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((data, index) => (
                <tr key={index}>
                  <td className="border-0">{tableData.indexOf(data) + 1}</td>
                  <td className="border-0">
                    <b>
                      {data.firstName
                        ? data.firstName + " " + (data.lastName || " ")
                        : data.displayName || data.name || " "}
                    </b>
                  </td>
                  <td className="border-0">{data?.userName}</td>
                  <td className="border-0">
                    {moment(data?.createdAt).format("MM/DD/YYYY")}
                  </td>
                  <td className="border-0">
                    <span
                      className={
                        data.status == "active"
                          ? "statusLabel successLabel"
                          : data.status == "blocked"
                          ? "statusLabel dangerLabel"
                          : "statusLabel"
                      }
                    >
                      {data.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap paginationWrpper mt-2 bg-white pt-3">
          <div className="left">
            <div className="showResult d-flex align-items-center gap-10">
              <label htmlFor="" className="form-label m-0">
                Show Result:
              </label>
              <Form.Select
                aria-label="Default select example"
                value={filter.limit}
                onChange={(e) => setFilter({ limit: e.target.value })}
              >
                {[10, 20, 30, 50]?.map((itm) => (
                  <option value={itm}>{itm}</option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="right ">
            <Pagination>
              <Pagination.Prev onClick={handlePreviousClick} />
              {Array.from(Array(props.totalPage).keys())?.map((page, index) => {
                var pageNo = page + 1;
                return (
                  <Pagination.Item
                    active={pageNo == filter.page}
                    onClick={() => setFilter({ page: pageNo })}
                  >
                    {pageNo}
                  </Pagination.Item>
                );
              })}
              <Pagination.Next onClick={handleNextClick} />
            </Pagination>
          </div>
        </div> */}

        <CustomPagination
          totalPage={props.totalPage}
          setFilter={setFilter}
          filter={filter}
        />
      </div>
    </>
  );
};

export default TopArtistsIndex;
