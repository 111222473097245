import React , {useState , useEffect} from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Subscriptionindex from "../../../components/SideTab/Subscriptionplan/Index";
import { useDispatch } from "react-redux";
import { allPlan } from "../../../redux/actions";
import {toast} from "react-toastify"

const Subscriptionplan = () => {

  const dispatch = useDispatch();

  const getPlanList = () =>{
    const data = {}
    dispatch(allPlan(data))
    .unwrap()
    .then(res =>{})
    .catch((err) =>{
      toast.error(err)
    })
  }

  useEffect(() => {
    getPlanList()
  }, [])

  return (
    <>
      {/* <NonAuthLayout> */}
        <Subscriptionindex getPlanList={getPlanList}/>
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Subscriptionplan;
