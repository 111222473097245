import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const artists =  createAsyncThunk("/artists/getList" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.artist.getList , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const addArtist =  createAsyncThunk("/artists/add" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.artist.addArtist , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const updateArtist =  createAsyncThunk("/artists/update" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.artist.updateArtist , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

export const deleteArtist =  createAsyncThunk("/artists/delete" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.artist.deleteArtist , payload);
        return response.data;
    }
    catch (error) {
        return Thunk.rejectWithValue(error)
    }
})

