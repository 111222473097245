import React, { useEffect, useState } from "react";
import AuthLayout from "../../layout/authLayout";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../elements/ButtonLoader";
import { resetPassword } from "../../redux/states/auth/thunk";
import { toast } from "react-toastify";
import ShowIcon from "../../images/showw.png";
import HideIcon from "../../images/hidew.png";

const Reset = () => {
  const { showButtonLoader, selectedEmail } = useSelector(
    (state) => state.auth
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async function (values) {
      dispatch(resetPassword(values))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          navigate("/");
        })
        .catch((err) => toast.error(err.message));
    },
    validationSchema: Yup.object({
      password: Yup.string("E-mail must be a string")
        .required("Password is required")
        .min(3, "Password must be greater than 3 character")
        .max(10, "Password must be less than 10 character"),
      confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .min(3, "Password must be greater than 3 character")
        .max(10, "Password must be less than 10 character"),
    }),
  });

  useEffect(() => {
    if (selectedEmail) {
      formik.setFieldValue("email", selectedEmail);
    }
  }, [selectedEmail]);

  return (
    <AuthLayout>
      <div className="accountForm  my-3">
        <h5 className="text-light">Reset Password</h5>
        <Form className="mt-4 text-start">
          <div className="py-2">
            <div className="position-relative authPassInput icon-with-text d-flex align-items-center bg-transparent form-control">
              <span className="position-absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.8333 9.16663H4.16667C3.24619 9.16663 2.5 9.91282 2.5 10.8333V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V10.8333C17.5 9.91282 16.7538 9.16663 15.8333 9.16663Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.83301 9.16663V5.83329C5.83301 4.72822 6.27199 3.66842 7.0534 2.88701C7.8348 2.10561 8.89461 1.66663 9.99967 1.66663C11.1047 1.66663 12.1646 2.10561 12.946 2.88701C13.7274 3.66842 14.1663 4.72822 14.1663 5.83329V9.16663"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="ENTER PASSWORD"
                className=" border-0 "
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                name="password"
                id="password"
              />
              {showPassword ? (
                <img
                  src={ShowIcon}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <img
                  src={HideIcon}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <span className="text-danger">{formik.errors.password}</span>
            )}
          </div>
          <div className="py-2">
            <div className="position-relative authPassInput icon-with-text d-flex align-items-center bg-transparent form-control">
              <span className="position-absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.8333 9.16663H4.16667C3.24619 9.16663 2.5 9.91282 2.5 10.8333V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V10.8333C17.5 9.91282 16.7538 9.16663 15.8333 9.16663Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.83301 9.16663V5.83329C5.83301 4.72822 6.27199 3.66842 7.0534 2.88701C7.8348 2.10561 8.89461 1.66663 9.99967 1.66663C11.1047 1.66663 12.1646 2.10561 12.946 2.88701C13.7274 3.66842 14.1663 4.72822 14.1663 5.83329V9.16663"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type={showConfPassword ? "text" : "password"}
                placeholder="RE-ENTER PASSWORD"
                className=" border-0 "
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                name="confirmPassword"
                id="confirmPassword"
              />
              {showConfPassword ? (
                <img
                  src={ShowIcon}
                  onClick={() => setShowConfPassword(!showConfPassword)}
                />
              ) : (
                <img
                  src={HideIcon}
                  onClick={() => setShowConfPassword(!showConfPassword)}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <span className="text-danger">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>

          <div className="py-2">
            {showButtonLoader ? (
              <div className="btn-wrp mt-3">
                <Link className="SubmitBtn d-flex align-items-center justify-content-center  w-100">
                  <ButtonLoader />
                </Link>
              </div>
            ) : (
              <div className="btn-wrp mt-3">
                <Link
                  className="SubmitBtn d-flex align-items-center justify-content-center  w-100"
                  onClick={formik.handleSubmit}
                >
                  SUBMIT
                </Link>
              </div>
            )}
          </div>
          <div className="py-1">
            <p className="m-0 form-text text-end">
              <Link to="/" className="form-link text-white">
                Go To Login
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Reset;
