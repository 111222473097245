import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { allTickets , addTicket , updateTicket , deleteTicket , ticketDetails  } from "./thunk";


const initialState = {
    allTickets : [] ,
    status : status.IDLE,
    showLoader : false ,
    isUpdate : false,
    showButtonLoader : false ,
    ticketDetails : {} ,
}

const slice = createSlice({
    name : "subAdmin",
    initialState : {...initialState},
    reducers : {
        removeAllTickets : (proxy , action) =>{
            return initialState
        },
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allTickets.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allTickets.fulfilled , (state,action) =>{
            state.allTickets = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTickets.rejected , (state , action) =>{
            state.allTickets = initialState.allTickets;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(addTicket.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(addTicket.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(addTicket.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })

        builder.addCase(updateTicket.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(updateTicket.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
            state.isUpdate = false;
        })
        builder.addCase(updateTicket.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
            state.isUpdate = false;
        })

        builder.addCase(deleteTicket.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(deleteTicket.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(deleteTicket.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })

        builder.addCase(ticketDetails.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(ticketDetails.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
            state.productDetails = action.payload.data;
        })
        builder.addCase(ticketDetails.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
            state.productDetails = initialState.productDetails;
        })
    }
});


export default slice.reducer;
export const {removeAllTickets } = slice.actions;