import { createSlice, current } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {
  forgetPassword,
  login,
  resendOtp,
  resetPassword,
  verifyOtp,
} from "./thunk";
import { persistor } from "../../store";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  isAuthenticated: false,
  status: status.IDLE,
  showLoader: false,
  showButtonLoader: false,
  selectedEmail: "",
};

const slice = createSlice({
  name: "auth",
  initialState: { ...initialState },
  reducers: {
    logout: (state, action) => {
      // try {
      localStorage.removeItem("persist:root");
      toast.success("User Loged out successfully");
      // window.location.href = "/";
      state.user = initialState.user;
      state.isAuthenticated = false;
      state.status = status.IDLE;
      state.showLoader = false;
      state.showButtonLoader = false;
      // } catch (error) {
      state.showLoader = false;

      // toast.success("User Loged out !");
    },
    selectEmail: (state, action) => {
      state.selectedEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.isAuthenticated = true;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = initialState.user;
      state.isAuthenticated = initialState.isAuthenticated;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(forgetPassword.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(verifyOtp.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(resendOtp.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(resendOtp.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(resendOtp.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });
  },
});

export default slice.reducer;
export const { logout, selectEmail } = slice.actions;
