import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {allDashboardData , allTopArtists , allTopFans  } from  "./thunk"

const initialState = {
    dashboardData : {} ,
    topFans : [] ,
    topArtists : [] ,
    status : status.IDLE,
    showLoader : false ,
    selectedTab : "top-fans"
}

const slice = createSlice({
    name : "subAdmin",
    initialState : {...initialState},
    reducers : {
        removeDashboardData : (proxy , action) =>{
            return initialState
        },
        updateSelectedTab : (state , action) =>{
            state.selectedTab = action.payload
        } 
    } ,
    extraReducers : (builder) =>{
        builder.addCase(allDashboardData.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allDashboardData.fulfilled , (state,action) =>{
            state.dashboardData = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allDashboardData.rejected , (state , action) =>{
            state.dashboardData = initialState.dashboardData;
            state.status = status.FAILED;
            state.showLoader = false;
        })
        
        builder.addCase(allTopArtists.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allTopArtists.fulfilled , (state,action) =>{
            state.topArtists = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTopArtists.rejected , (state , action) =>{
            state.topArtists = initialState.topArtists;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(allTopFans.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allTopFans.fulfilled , (state,action) =>{
            state.topFans = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allTopFans.rejected , (state , action) =>{
            state.topFans = initialState.topFans;
            state.status = status.FAILED;
            state.showLoader = false;
        })

    }
})


export default slice.reducer;
export const {removeDashboardData , updateSelectedTab } = slice.actions;