import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { announcements } from "../../../redux/states/announcement/thunk";
import AnnouncementIndex from "../../../components/SideTab/Announcement";

const Announcements = () => {
  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage, setTotalPage] = useState(1);

  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
    status: q.get("status") || "",
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
      status: params.status || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: q.get("page") - 1 });
    }
  };
  const handleNextClick = () => {
    if (filter.page < totalPage) {
      setFilter({ page: Number(q.get("page")) + 1 });
    }
  };

  const getAnnouncementList = (search, pageNo, limit, status) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
      status: status,
    };
    // setQ({ search: search, page: pageNo, limit: limit });
    dispatch(announcements(data))
      .then((response) => {
        const totalCount = response?.payload?.data?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getAnnouncementList(
      filter.search,
      filter.page,
      filter.limit,
      filter.status
    );
  }, [filter]);

  useEffect(() => {
    setQ({ search: filter.search, page: filter.page, limit: filter.limit });
  }, []);

  return (
    <>
      {/* <NonAuthLayout> */}
      <AnnouncementIndex
        filter={filter}
        totalPage={totalPage}
        setFilter={setFilter}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        getAnnouncementList={getAnnouncementList}
      />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Announcements;
