import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import AddUser from "../../../Common/Modals/AddUser";
import { useDispatch } from "react-redux";
import { clearSelected } from "../../../../redux/states/users/slice";

const TableFilter = (props) => {
  const { showAddUserModel, setShowAddUserModel } = props;
  const { filter, setFilter } = props;
  const dispatch = useDispatch();

  const onHide = () => {
    setShowAddUserModel(false);
    dispatch(clearSelected());
  };

  const handleStatus = (e) => {
    const value = e.target.value;
    setFilter({ status: value });
  };

  return (
    <>
      <AddUser show={showAddUserModel} onHide={onHide} {...props} />
      <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4  flex-wrap">
        <div className="left d-flex align-items-center gap-10 flex-wrap">
          <h2 className="m-0 fw-bold heading">Manage Request from Viewer</h2>
          <div className="searchForm position-relative icon-with-text">
            <input
              type="text"
              placeholder="Search by Name, email"
              className="form-control rounded-pill cstmShadow"
              value={filter.search}
              onChange={(e) => setFilter({ search: e.target.value })}
            />
            <span className="position-absolute icn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.75 13.75L12.5 12.5"
                  stroke="#9DA3BB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
          <div className="px-2 d-flex">
            <Form.Select
              aria-label=""
              className="activeSelect mx-3"
              onChange={(e) => handleStatus(e)}
            >
              <option value=""> Status</option>
              <option value="active">Active</option>
              <option value="blocked">Blocked</option>
            </Form.Select>
          </div>

          <div className="px-2"></div>
        </div>
      </div>
    </>
  );
};

export default TableFilter;
