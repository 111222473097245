import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allDashboardData = createAsyncThunk("/dashboard/getList" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.dashboard.getDashboardData , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const allTopFans = createAsyncThunk("/dashboard/topFans" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.dashboard.getTopFan , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

export const allTopArtists = createAsyncThunk("/dashboard/topArtists" , async (payload , Thunk) =>{
    try{
        const response = await axios.post(endpoints.dashboard.getTopArtists , payload)
        return response?.data
    } catch (error) {
        return Thunk.rejectWithValue(error)
    }
});

