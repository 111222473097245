import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AuthLayout = ({ children }) => {
  return (
    <>
      <section
        className="authLayout w-100 text-center d-flex align-items-center justify-content-centerm py-5"
        style={{
          backgroundImage: "url('../assets/images/authbg.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
        }}
      >
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg="4" md="6" sm="10">
              <Link to="/" className="logo">
                <img
                  src="../assets/images/wlogo.png"
                  alt=""
                  className="img-fluid"
                />
              </Link>
              {children}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AuthLayout;
