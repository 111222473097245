
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";


export const allLikePost = createAsyncThunk("/fanAnalytics/likePost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.getLikePost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const allCommentPost = createAsyncThunk("/fanAnalytics/commentPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.getCommentPost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const allSharePost = createAsyncThunk("/fanAnalytics/sharePost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.getSharePost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const allWatchListPost = createAsyncThunk("/fanAnalytics/watchListPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.getWatchListPost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const allUsersWatchList = createAsyncThunk("/fanAnalytics/userWatchListPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.getUserWatchList , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})


// the remove thunk api;

export const removeLikePost = createAsyncThunk("/fanAnalytics/removeLikePost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.removeLikePost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const removeCommentPost = createAsyncThunk("/fanAnalytics/removeCommentPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.removeCommentPost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const removeSharePost = createAsyncThunk("/fanAnalytics/removeSharePost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.removeSharePost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const removeWatchListPost = createAsyncThunk("/fanAnalytics/removeWatchListPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.removeWatchListPost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

export const removeUserWatchListPost = createAsyncThunk("/fanAnalytics/removeUserWatchListPost" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.fanAnalytics.removeUserWatchListPost , payload)
        return response.data;
    }
    catch(error){
        return Thunk.rejectWithValue(error)
    }
})

