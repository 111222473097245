import React from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Pagination,
  Tab,
  Nav,
} from "react-bootstrap";
import Fade from "react-reveal";
import { Link } from "react-router-dom";
import Addproduct from "./Component/Addproduct";
import Editproduct from "./Component/EditProduct";

const Inventoryindex = () => {
  return (
    <Fade>
      <section className="Prodcut-section py-3">
        <Container fluid>
          <Row>
            <div className="my-2 col-lg-12">
              <div className="cardBox h-100 position-relative bg-white">
                <div className="TableFilters d-flex align-items-center justify-content-start gap-10 p-3 p-lg-4  flex-wrap">
                  <div className="left d-flex align-items-center gap-10 flex-wrap">
                    <div className="inventory_heading">
                      <h2 className="m-0 fw-bold heading">Inventory Details</h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>
                  </div>
                  <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper"></div>
                </div>

                <div className="inventory_content commonTabs p-lg-4 p-3">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="addproducts"
                  >
                    <Nav variant="pills" className="">
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="addproducts"
                        >
                          Add Product
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          className="bg-transparent border-0 py-3 px-2 position-relative"
                          eventKey="editproducts"
                        >
                          Edit Product
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="CardBody py-3">
                      <Tab.Content className="">
                        <Tab.Pane eventKey="addproducts">
                          <Addproduct />
                        </Tab.Pane>
                        <Tab.Pane eventKey="editproducts">
                          <Editproduct />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Inventoryindex;
