import React, { useState, useEffect } from "react";
import Settingsindex from "../../../components/SideTab/Settings/Index";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  allContent,
  allEmailTemplate,
  allFaqs,
  getBasicConfig,
  getBasicSettings,
} from "../../../redux/states/settings/thunk";
import { toast } from "react-toastify";

const Settings = () => {
  const [query, setQuery] = useSearchParams();

  const tab = useSelector((state) => state?.settings)?.selectedTab;
  const dispatch = useDispatch();
  const [totalPage, setTotalPage] = useState(1);

  const filter = {
    tab: tab,
    page: query.get("page") || 1,
    limit: query.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQuery({
      tab: params.tab,
      page: params.page || 1,
      limit: params.limit || 10,
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: query.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: query.get("page") + 1 });
    }
  };

  useEffect(() => {
    setFilter({ tab: tab });
  }, []);

  const getBasicSettingData = () => {
    dispatch(getBasicSettings())
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getBasicConfigData = () => {
    dispatch(getBasicConfig())
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getAllFaqList = () => {
    dispatch(allFaqs())
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getContentList = () => {
    dispatch(allContent())
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getEmailTemplateList = () => {
    dispatch(allEmailTemplate())
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getBasicSettingData();
    getAllFaqList();
    getContentList();
    getBasicConfigData();
    getEmailTemplateList();
  }, [query.get("page"), query.get("limit"), tab]);

  return (
    <>
      {/* <NonAuthLayout> */}
      <Settingsindex
        tab={tab}
        filter={filter}
        setFilter={setFilter}
        setQuery={setQuery}
        totalPage={totalPage}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        getContentList={getContentList}
        getAllFaqList={getAllFaqList}
        getBasicConfigData={getBasicConfigData}
        getBasicSettingData={getBasicSettingData}
        getEmailTemplateList={getEmailTemplateList}
      />
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Settings;
