import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { addPlan, allPlan, updatePlan } from "./thunk";

const initialState = {
  allPlan: [],
  status: status.IDLE,
  showLoader: false,
  isUpdate: false,
  showButtonLoader: false,
  selectedPlan : {}
};

const slice = createSlice({
  name: "plan",
  initialState: { ...initialState },
  reducers: {
    removeAllPlan: (proxy, action) => {
      return initialState;
    },
    editPlan : (state,action) =>{
        state.selectedPlan = action.payload;
        state.isUpdate = true
    },
    clearPlanSelection : (state , action) =>{
        state.selectedPlan = initialState.selectedPlan;
        state.isUpdate = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(allPlan.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(allPlan.fulfilled, (state, action) => {
      state.allPlan = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(allPlan.rejected, (state, action) => {
      state.allPlan = initialState.allPlan;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addPlan.pending , (state ,action) =>{
        state.status = status.LOADING;
        state.showButtonLoader = true ;
    });
    builder.addCase(addPlan.fulfilled , (state,action) =>{
        state.status = status.SUCCEDED;
        state.showButtonLoader = false;
    })
    builder.addCase(addPlan.rejected , (state , action) =>{
        state.status = status.FAILED;
        state.showButtonLoader = false;
    })

    builder.addCase(updatePlan.pending , (state ,action) =>{
        state.status = status.LOADING;
        state.showButtonLoader = true ;
    });
    builder.addCase(updatePlan.fulfilled , (state,action) =>{
        state.status = status.SUCCEDED;
        state.showButtonLoader = false;
        state.isUpdate = false;
    })
    builder.addCase(updatePlan.rejected , (state , action) =>{
        state.status = status.FAILED;
        state.showButtonLoader = false;
        state.isUpdate = false;
    })
  },
});


export default slice.reducer;
export const {editPlan , removeAllPlan , clearPlanSelection} = slice.actions;