import React, { useState } from "react";
import { Col, Container, Nav, Row, Tab, Button } from "react-bootstrap";
import Fade from "react-reveal";
import Addsubscription from "../../Common/Modals/addsubscription";
import { useSelector, useDispatch } from "react-redux";
import { editPlan } from "../../../redux/states/plan/slice";
import TextTruncation from "../../../elements/TextTrancation";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FiCheckCircle } from "react-icons/fi";

const Subscriptionindex = (props) => {
  const { getPlanList } = props;
  const [subscribe, setSubscribe] = useState(false);
  const { allPlan, showLoader } = useSelector((state) => state.plan);
  const dispatch = useDispatch();

  const handleEdit = (dta) => {
    dispatch(editPlan(dta));
    setSubscribe(true);
  };

  return (
    <Fade>
      <Addsubscription
        show={subscribe}
        onHide={() => setSubscribe(false)}
        {...props}
      />
      <section className="position-relative fan-analytics py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="px-lg-5 px-md-3">
                <div className="sectionInner h-100 position-relative">
                  <div className="TableFilters d-flex align-items-center justify-content-between gap-10 p-3 p-lg-4 flex-wrap">
                    <div className="left d-flex align-items-center gap-10 flex-wrap">
                      <h2 className="m-0 fw-bold heading">Subscription Plan</h2>
                    </div>
                    <div className="right d-flex align-items-center justify-content-end gap-10 flex-wrap btnWrpper">
                      <div className="px-2">
                        <Button
                          className="d-flex w-100 cstmShadow text-dark align-items-center justify-content-center rounded-pill"
                          onClick={() => setSubscribe(true)}
                        >
                          + Add Subscription
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="subscription_card p-sm-3 p-lg-4">
                    <Row>
                      {allPlan?.map((data, index) => {
                        return (
                          <>
                            <Col
                              lg={4}
                              md={6}
                              sm={12}
                              key={index}
                              className="my-2"
                            >
                              <div className="cardcommon text-center cardbasic bg-transparent h-100">
                                <div className="bage_wrap m-auto border-dark d-flex align-items-center justify-content-center">
                                  <span className="badge_border text-dark">
                                    {data?.name}
                                  </span>
                                </div>
                                {data?.status === "active" ? (
                                  <FiCheckCircle
                                    size={23}
                                    color="green"
                                    className="mt-3"
                                  />
                                ) : (
                                  <IoIosCloseCircleOutline
                                    color="red"
                                    className="mt-3"
                                    size={23}
                                  />
                                )}
                                <div className="py-2">
                                  <h1 className="my-0 text-dark mb-0 pb-2">
                                    ${data?.fee}
                                  </h1>
                                  <h4 className="fw-sbold">{data?.type}</h4>
                                </div>
                                <ul className="plan_list ps-0 list-unstyled pb-3 position-relative">
                                  {/* <li>{data?.description}</li> */}
                                  <li className="position-relative fw-sbold">
                                    <TextTruncation
                                      text={data?.description}
                                      maxLength={90}
                                    />
                                  </li>
                                </ul>

                                <Button
                                  className="btn_edt blackBtn p-2 w-75 fs-5"
                                  onClick={() => handleEdit(data)}
                                >
                                  Edit
                                </Button>
                              </div>
                            </Col>
                          </>
                        );
                      })}

                      {/* <Col lg={4} md={6} sm={12}>
                      <div className="cardcommon cardadvance">
                        <div className="bage_wrap m-auto border-white mb-5">
                        <span className="badge_border text-white">Advance </span>
                        </div>
                        <h1 className="text-white">$9</h1>
                        <p>User/Month</p>


                        <ul className="plan_list">
                          <li>
                            App Management
                          </li>
                          <li>Attendance Management</li>
                          <li>Leave System Management</li>
                          <li>Employee Management</li>
                          <li>Expense Tracking </li>
                          <li>Chat Support</li>
                          <li>Invoice Generate </li>

                        </ul>

                        <Button className="btn_edt p-2 w-75 fs-5 bg-white text-black border-white">Edit</Button>
                      </div>
                    </Col>


                    <Col lg={4} md={6} sm={12}>
                      <div className="cardcommon cardbasic mt-5">
                        <div className="bage_wrap m-auto">
                        <span className="badge_border">Premium </span>
                        </div>
                        <h1>$12</h1>
                        <p>User/Month</p>


                        <ul className="plan_list">
                          <li>
                            App Management
                          </li>
                          <li>Attendance Management</li>
                          <li>Leave System Management</li>
                          <li>Employee Management</li>
                          <li>Expense Tracking </li>
                          <li>Chat Support</li>
                          <li>Invoice Generate </li>

                        </ul>

                        <Button className="btn_edt p-2 w-75 fs-5">Edit</Button>
                      </div>
                    </Col> */}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Subscriptionindex;
