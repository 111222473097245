import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";

const CounterCards = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid className="">
        <Row className="counterCardWrp">
          <Col
            lg="3"
            sm="6"
            className="my-2"
            onClick={() => navigate("/users")}
          >
            <Fade zoom>
              <div className="h-100 p-3 cardCstm d-flex align-items-center gap-10">
                <span className="icnWrp mb-3 d-inline-flex align-items-center justify-content-center">
                  <img
                    src="/assets/images/c1.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <div className="content">
                  <p className="m-0 py-1 fw-sbold">Total Viewers</p>
                  <h4 className="m-0 py-2 fw-sbold">{data?.totalUserCount}</h4>
                </div>
              </div>
            </Fade>
          </Col>
          <Col
            lg="3"
            sm="6"
            className="my-2"
            onClick={() => navigate("/artist")}
          >
            <Fade zoom>
              <div className=" h-100 p-3 cardCstm  d-flex align-items-center gap-10">
                <div className="content">
                  <p className="m-0 py-1 fw-sbold">Total Entertainer</p>
                  <h4 className="m-0 py-2 fw-sbold">
                    {data?.totalArtistCount}
                  </h4>
                </div>
                <span className="icnWrp mb-3 d-inline-flex align-items-center justify-content-center">
                  <img
                    src="/assets/images/c2.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
              </div>
            </Fade>
          </Col>
          <Col lg="3" sm="6" className="my-2">
            <Fade zoom>
              <div className="h-100 p-3 cardCstm  d-flex align-items-center gap-10">
                <div className="content">
                  <p className="m-0 py-1 fw-sbold">Total Revenue</p>
                  <h4 className="m-0 py-2 fw-sbold">
                    ${Number(data?.totalRevenue).toFixed(2)}
                  </h4>
                </div>
                <span className="icnWrp mb-3 d-inline-flex align-items-center justify-content-center">
                  <img
                    src="/assets/images/c4.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
              </div>
            </Fade>
          </Col>
          <Col
            lg="3"
            sm="6"
            className="my-2"
            onClick={() => navigate("/subscription")}
          >
            <Fade zoom>
              <div className="h-100 p-3 cardCstm  d-flex align-items-center gap-10">
                <div className="content">
                  <p className="m-0 py-1 fw-sbold">Paid Subscriptions</p>
                  <h4 className="m-0 py-2 fw-sbold">
                    {data?.paidSubscriptions?.length
                      ? data?.paidSubscriptions?.length
                      : 0}
                  </h4>
                </div>{" "}
                <span className="icnWrp mb-3 d-inline-flex align-items-center justify-content-center">
                  <img
                    src="/assets/images/c3.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CounterCards;
