import React from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Inventoryindex from "../../../components/SideTab/Inventory";

const Inventory = () => {
  return (
    <>
      {/* <NonAuthLayout> */}
         <Inventoryindex/>
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Inventory;
