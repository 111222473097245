import React, { useEffect } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Productindex from "../../../components/SideTab/Products";
import { Container, Row, Col } from "react-bootstrap";
import Editproduct from "../../../components/SideTab/Inventory/Component/EditProduct";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productDetails } from "../../../redux/states/products/thunk";
import { toast } from "react-toastify";

const EditProducts = () => {
  const { slug, productId } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products)?.productDetails;

  const getProductDetails = () => {
    dispatch(productDetails(slug))
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (slug) {
      getProductDetails();
    }
  }, []);

  return (
    <>
      {/* <NonAuthLayout> */}
      <section className="Prodcut-section py-3">
        <Container fluid>
          <Editproduct data={product} />
        </Container>
      </section>
      {/* </NonAuthLayout> */}
    </>
  );
};

export default EditProducts;
