import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import { uploadImg } from "./thunk";

const initialState = {
    imageLoader : false ,
    status : status.IDLE 
}

const slice = createSlice({
    name : "uploadImg",
    initialState : {...initialState},
    reducers : {
        
    },
    extraReducers :(builder) =>{
        builder.addCase(uploadImg.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.imageLoader = true ;
        });
        builder.addCase(uploadImg.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.imageLoader = false;
        })
        builder.addCase(uploadImg.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.imageLoader = false;
        })
    }
});

export default slice.reducer;