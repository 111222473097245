import React, { useEffect } from "react";
import { Button, Offcanvas, Row, Col } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// images
import Uploathumb from "../../../../src/images/upload_thunmnail.png";
import Player from "../../../../src/images/player.png";
import ButtonLoader from "../../../elements/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { addPlan, updatePlan } from "../../../redux/actions";
import { toast } from "react-toastify";
import { clearPlanSelection } from "../../../redux/states/plan/slice";

const Addsubscription = (props) => {
  const { isUpdate, showButtonLoader, selectedPlan } = useSelector(
    (state) => state.plan
  );
  const dispatch = useDispatch();

  const { getPlanList } = props;

  const onHide = () => {
    props.onHide();
    formik.resetForm();
    dispatch(clearPlanSelection());
  };

  const formik = useFormik({
    name: "plan",
    initialValues: {
      name: "",
      type: "",
      description: "",
      fee: 0,
      monthCount: 1,
      status: false,
    },
    onSubmit: async function (values, { resetForm }) {
      if (isUpdate) {
        const data = {
          dta: values,
          planId: selectedPlan?._id,
        };
        dispatch(updatePlan(data))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            getPlanList();
            resetForm();
            onHide();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        dispatch(addPlan(values))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            getPlanList();
            resetForm();
            onHide();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      type: Yup.string().required("Type is required"),
      description: Yup.string().required("Description is required"),
      fee: Yup.number().required("Fee is required").positive("Invalid fee"),
      monthCount: Yup.string().required("mountCount is required"),
    }),
  });

  useEffect(() => {
    formik.setFieldValue(
      "monthCount",
      formik.values.type === "monthly" ? 1 : 12
    );
  }, [formik.values.type]);

  useEffect(() => {
    if (isUpdate) {
      formik.setValues({
        name: selectedPlan?.name,
        type: selectedPlan?.type,
        description: selectedPlan?.description,
        fee: selectedPlan?.fee,
        status: selectedPlan?.status,
        monthCount: selectedPlan.type === "monthly" ? 1 : 12,
      });
    }
  }, [isUpdate]);

  return (
    <section className="addtiketmod_sec">
      <Offcanvas
        {...props}
        show={props.show}
        onHide={onHide}
        placement="end"
        className="tiketcanvas"
      >
        <Offcanvas.Header className="position-relative">
          <Offcanvas.Title>
            {isUpdate ? "Update" : "Add"} Subscription
          </Offcanvas.Title>
          <a href="javascript:void(0)" onClick={onHide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g id="material-symbols:cancel-outline">
                <path
                  id="Vector"
                  d="M9.79967 19.8333L13.9997 15.6333L18.1997 19.8333L19.833 18.2L15.633 14L19.833 9.80001L18.1997 8.16668L13.9997 12.3667L9.79967 8.16668L8.16634 9.80001L12.3663 14L8.16634 18.2L9.79967 19.8333ZM13.9997 25.6667C12.3858 25.6667 10.8691 25.3602 9.44967 24.7473C8.03023 24.1345 6.79551 23.3034 5.74551 22.2542C4.69551 21.2042 3.86445 19.9695 3.25234 18.55C2.64023 17.1306 2.33379 15.6139 2.33301 14C2.33301 12.3861 2.63945 10.8695 3.25234 9.45001C3.86523 8.03057 4.69629 6.79584 5.74551 5.74584C6.79551 4.69584 8.03023 3.86479 9.44967 3.25268C10.8691 2.64057 12.3858 2.33412 13.9997 2.33334C15.6136 2.33334 17.1302 2.63979 18.5497 3.25268C19.9691 3.86557 21.2038 4.69662 22.2538 5.74584C23.3038 6.79584 24.1353 8.03057 24.7482 9.45001C25.3611 10.8695 25.6671 12.3861 25.6663 14C25.6663 15.6139 25.3599 17.1306 24.747 18.55C24.1341 19.9695 23.3031 21.2042 22.2538 22.2542C21.2038 23.3042 19.9691 24.1356 18.5497 24.7485C17.1302 25.3614 15.6136 25.6675 13.9997 25.6667ZM13.9997 23.3333C16.6052 23.3333 18.8122 22.4292 20.6205 20.6208C22.4288 18.8125 23.333 16.6056 23.333 14C23.333 11.3945 22.4288 9.18751 20.6205 7.37918C18.8122 5.57084 16.6052 4.66668 13.9997 4.66668C11.3941 4.66668 9.18717 5.57084 7.37884 7.37918C5.57051 9.18751 4.66634 11.3945 4.66634 14C4.66634 16.6056 5.57051 18.8125 7.37884 20.6208C9.18717 22.4292 11.3941 23.3333 13.9997 23.3333Z"
                  fill="#202020"
                />
              </g>
            </svg>
          </a>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="titket_Add_form ">
            <Form>
              <FormGroup>
                <Label for="exampleEmail">
                  Subscription Name<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="Premium "
                />
                {formik.touched.name && formik.errors.name && (
                  <span className="text-danger">{formik.errors.name}</span>
                )}
              </FormGroup>

              <FormGroup tag="fieldset">
                <h6 className="font-sm">Subscription Type</h6>
                <div className="wrapping d-lg-flex gap-4 justify-content-start align-items-center">
                  <FormGroup check>
                    <Label check className="ml-2">
                      <input
                        type="radio"
                        name="radio"
                        style={{ height: "15px", marginRight: "5px" }}
                        onChange={() => {
                          formik.setFieldValue("type", "monthly");
                        }}
                        checked={formik.values.type === "monthly"}
                      />
                      Monthly
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <input
                        type="radio"
                        name="radio"
                        style={{ height: "15px", marginRight: "5px" }}
                        className="mr-2"
                        onChange={() => {
                          formik.setFieldValue("type", "yearly");
                        }}
                        checked={formik.values.type === "yearly"}
                      />
                      Yearly
                    </Label>
                  </FormGroup>
                </div>
                {formik.touched.type && formik.errors.type && (
                  <span className="text-danger">{formik.errors.type}</span>
                )}
              </FormGroup>

              <div className="description_details mb-3">
                <Label>Description Details</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  placeholder="Enter the details"
                />
                {formik.touched.description && formik.errors.description && (
                  <span className="text-danger">
                    {formik.errors.description}
                  </span>
                )}
              </div>

              <FormGroup>
                <Label for="exampleEmail">
                  Fee<span className="cumplusry">*</span>
                </Label>
                <Input
                  type="number"
                  name="fee"
                  id="fee"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fee}
                  min={0}
                  placeholder="Enter the amount"
                />
                {formik.touched.fee && formik.errors.fee && (
                  <span className="text-danger">{formik.errors.fee}</span>
                )}
              </FormGroup>

              <div className="left d-flex align-items-center gap-10 flex-wrap">
                <h6 className="m-0">Status</h6>

                <div className="switch-btn " style={{ height: "32px" }}>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={formik.values.status === "active"}
                      onChange={() =>
                        formik.setFieldValue(
                          "status",
                          formik.values.status === "blocked"
                            ? "active"
                            : "blocked"
                        )
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="Button_group d-flex align-items-center justify-content-between gap-3 my-4">
                <Button
                  onClick={() => formik.resetForm()}
                  className="rounded w-50 border-dark bg-white py-3 text-dark fw-bold"
                >
                  Reset
                </Button>
                {showButtonLoader ? (
                  <Button className="rounded bg-dark w-50 py-3 border-dark text-white fw-bold">
                    <ButtonLoader color={"text-light"} />
                  </Button>
                ) : (
                  <Button
                    onClick={formik.handleSubmit}
                    className="rounded bg-dark w-50 py-3 border-dark text-white fw-bold"
                  >
                    {isUpdate ? "Update" : "Submit"}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Addsubscription;
