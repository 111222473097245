import React, { useEffect, useState } from "react";
import NonAuthLayout from "../../../../layout/NonAuthLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  approveViewerForEntertainer,
  getProfile,
  rejectViewerToBeEntertainer,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import ButtonLoader from "../../../../elements/ButtonLoader";
import classNames from "classnames";
import moment from "moment";

const ViewUserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [viewerDetails, setViewerDetails] = useState({});
  const { buttonLoader } = useSelector((s) => s.viewerRequest);
  const navigate = useNavigate();
  const [buttonType, setButtonType] = useState("");

  const getViewerDetails = () => {
    dispatch(getProfile(id))
      .unwrap()
      .then((res) => {
        setViewerDetails(res?.data);
      })
      .catch((err) => {
        console.log(err, "viewer response here");
      });
  };

  useEffect(() => {
    if (id) {
      getViewerDetails();
    }
  }, []);

  const handleApprove = () => {
    const data = {
      userId: id,
    };
    setButtonType("approve");
    dispatch(approveViewerForEntertainer(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        navigate("/viewer-request");
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        if (id) {
          getViewerDetails();
        }
      });
  };

  const handleReject = () => {
    const data = {
      userId: id,
    };
    setButtonType("reject");
    dispatch(rejectViewerToBeEntertainer(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        navigate("/viewer-request");
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        if (id) {
          getViewerDetails();
        }
      });
  };

  console.log("Viewer Details ", viewerDetails);

  return (
    <>
      <section className="users">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div
                className="DetailView rounded py-3 px-lg-5 px-3"
                style={{ background: "#f3f3f3" }}
              >
                <Row>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      First Name:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.firstName}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Last Name:{" "}
                      <span className="text-muted d-block py-1">
                        {" "}
                        {viewerDetails?.lastName}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Country:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.country}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Date of Birth:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.dob}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="12" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Address:{" "}
                      <span className="text-muted d-block py-1">
                        {" "}
                        {viewerDetails?.address}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      City:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.city}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Zip/Postal Code:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.zipCode}
                      </span>
                    </h6>
                  </Col>
                  {/* <Col lg="4" sm="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Zip/Postal Code:{" "}
                      <span className="text-muted d-block py-1">160055</span>
                    </h6>
                  </Col> */}
                  <Col lg="12" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Others:{" "}
                      <span className="text-muted d-block py-1">
                        {viewerDetails?.others}
                      </span>
                    </h6>
                  </Col>
                  <Col lg="12" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      ShuftiPro Verification Status:{" "}
                      <span
                        className={classNames({
                          "text-success": ["accepted"]?.includes(
                            viewerDetails?.shuftyStatus
                          ),
                          "text-warning": ["pending"]?.includes(
                            viewerDetails?.shuftyStatus
                          ),
                          "text-danger": ["declined", "cancelled"]?.includes(
                            viewerDetails?.shuftyStatus
                          ),
                        })}
                      >
                        {viewerDetails?.shuftyStatus?.toUpperCase()}
                      </span>
                    </h6>

                    <h6 className="m-0 fw-bold">
                      Submission Date:{" "}
                      <span>{moment(viewerDetails?.createdAt).format('L')}</span>
                    </h6>
                  </Col>
                  <Col lg="12" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      Verification Report:{" "}
                      <a
                        className="text-info d-block py-1"
                        href={viewerDetails?.verificationReport}
                        download={viewerDetails?.name + "-report.pdf"}
                      >
                        Download Report
                      </a>
                    </h6>
                  </Col>
                  <Col lg="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      PHOTO OF YOUR ID:{" "}
                      <div className="imgWrp mt-2 bg-light p-2">
                        {viewerDetails?.documentProof && (
                          <img
                            src={viewerDetails?.documentProof}
                            alt=""
                            className="img-fluid w-100 h-100"
                          />
                        )}
                      </div>
                    </h6>
                  </Col>
                  <Col lg="6" className="my-2 py-md-3">
                    <h6 className="m-0 fw-bold">
                      PHOTO OF HOLDING YOUR ID:{" "}
                      <div className="imgWrp mt-2 bg-light p-2">
                        {viewerDetails?.faceProof && (
                          <img
                            src={viewerDetails?.faceProof}
                            alt=""
                            className="img-fluid w-100 h-100"
                          />
                        )}
                      </div>
                    </h6>
                  </Col>
                  <Col lg="12" className="my-2">
                    {viewerDetails?.becomeAcreator === "pending" && (
                      <div className="btnWrp mt-4 d-flex align-items-center gap-10">
                        {buttonLoader && buttonType === "approve" ? (
                          <Button className="d-inline-flex align-items-center justify-content-center common-btn">
                            <ButtonLoader />
                          </Button>
                        ) : (
                          <Button
                            className="d-inline-flex align-items-center justify-content-center common-btn"
                            onClick={handleApprove}
                          >
                            Approve
                          </Button>
                        )}
                        {buttonLoader && buttonType === "reject" ? (
                          <Button className="d-inline-flex align-items-center justify-content-center common-btn">
                            <ButtonLoader />
                          </Button>
                        ) : (
                          <Button
                            className="d-inline-flex align-items-center justify-content-center common-btn"
                            onClick={handleReject}
                          >
                            Reject
                          </Button>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ViewUserDetail;
