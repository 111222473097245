import { createSlice } from "@reduxjs/toolkit";
import { allPosts, deletePost } from "./thunk";
import status from "../../../constants/status";

const tableData = [
    {
      title: "publishing and graphic design, Lorem ipsum...",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      title: "publishing and graphic design, Lorem ipsum...",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      title: "publishing and graphic design, Lorem ipsum...",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      title: "publishing and graphic design, Lorem ipsum...",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
  ];

const initialState = {
    allPosts : [],
    showLoader : false ,
    status : status.IDLE ,
    showButtonLoader : false
}

const slice = createSlice({
    name : "allPosts",
    initialState : {...initialState},
    reducers : {
        removeAllPosts : (proxy , action) => {
            return initialState;
        }
    },
    extraReducers : (builder) =>{
        builder.addCase(allPosts.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(allPosts.fulfilled , (state,action) =>{
            state.allPosts = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(allPosts.rejected , (state , action) =>{
            state.allPosts = initialState.allUsers;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(deletePost.pending , (state , action) =>{
            state.status = status.LOADING;
            state.showButtonLoader = true ;
        });
        builder.addCase(deletePost.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showButtonLoader = false;
        })
        builder.addCase(deletePost.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showButtonLoader = false;
        })
    }
})  

export default slice.reducer;
export const {removeAllPosts} = slice.actions;