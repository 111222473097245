import { artists, addArtist, updateArtist, deleteArtist } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";

const initialState = {
  allArtists: [],
  showLoader: false,
  status: status.IDLE,
  isUpdate: false,
  showButtonLoader: false,
  selectedArtist: {},
};

const slice = createSlice({
  name: "artists",
  initialState: { ...initialState },
  reducers: {
    removeAllArtists: (proxy, action) => {
      return initialState;
    },
    editArtist: (state, action) => {
      state.selectedArtist = action.payload;
      state.isUpdate = true;
    },
    clearSelected: (state, action) => {
      state.selectedArtist = initialState.selectedArtist;
      state.isUpdate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(artists.pending, (state, action) => {
      state.showLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(artists.fulfilled, (state, action) => {
      state.allArtists = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(artists.rejected, (state, action) => {
      state.allArtists = initialState.allUsers;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addArtist.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(addArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(addArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateArtist.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
      state.isUpdate = true;
    });
    builder.addCase(updateArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.isUpdate = false;
      state.selectedArtist = {};
    });
    builder.addCase(updateArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.isUpdate = false;
      state.selectedArtist = {};
    });

    builder.addCase(deleteArtist.pending, (state, action) => {
      state.showButtonLoader = true;
      state.status = status.LOADING;
    });
    builder.addCase(deleteArtist.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(deleteArtist.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });
  },
});

export default slice.reducer;
export const { removalAllArtists, editArtist, clearSelected } = slice.actions;
