import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'
import { endpoints } from "../../../../endpoints";


export const postDetails = createAsyncThunk("/posts/selectedPost" , async (slug , Thunk) =>{
    try {
        const response = await axios.get(endpoints.posts.getPostById + slug)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})


export const allLikes = createAsyncThunk("/posts/allLikes" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.posts.getLikesList , payload)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})

export const allComments = createAsyncThunk("/posts/allComments" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.posts.getCommentsList , payload)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})

export const allShares = createAsyncThunk("/posts/allShares" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.posts.getShareList , payload)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})

export const allTips = createAsyncThunk("/posts/allTips" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.posts.getTipList , payload)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})

