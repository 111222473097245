import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoints } from "../../../endpoints";

export const announcements = createAsyncThunk(
  "/announcements/getList",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.announcements.getList,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const addAnnouncements = createAsyncThunk(
  "/announcements/add",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.announcements.addAnnouncements,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateAnnouncements = createAsyncThunk(
  "/announcements/update",
  async (payload, Thunk) => {
    try {
      const response = await axios.post(
        endpoints.announcements.updateAnnouncements + payload.id,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteAnnouncements = createAsyncThunk(
  "/announcements/delete",
  async (payload, Thunk) => {
    try {
      const response = await axios.delete(
        endpoints.announcements.deleteAnnouncements + payload.id,
        payload
      );
      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
