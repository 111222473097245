

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";

function SampleNextArrow(props) {
  const { className, style, onClick, storyimg, data } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31a.996.996 0 0 0 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.62 2.99a1.25 1.25 0 0 0-1.77 0L6.54 11.3a.996.996 0 0 0 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
        />
      </svg>
    </div>
  );
}

const PostViewer = (props) => {
  const { media } = props;
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [showViewer, setShowViewer] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});

  const handleShowMedia = (dta) => {
    setSelectedMedia({
      url: dta?.url,
      mediaType: dta?.type,
    });
    setShowViewer(true);
  };

  console.log(media , "media here")
  return (
    <>
      <div className="postCard Post">
        <div className="my-2 rounded overflow-hidden">
          <Slider
            {...settings}
            className="border-1 d-flex justify-content-center align-items-center"
          >
            {media?.map((mediaItem, index) => (
              <div className="inner">
                <div
                  className="PostMedia d-flex align-items-center position-relative"
                  style={{ aspectRatio: "1/1" }}
                >
                  {mediaItem?.type === "image" && (
                    <>
                      <div
                        className="MediaBg position-absolute  w-100 h-100 d-none"
                        onClick={() => handleShowMedia(mediaItem)}
                      >
                        <img
                          src={mediaItem?.url}
                          alt=""
                          className="rounded img-fluid  w-100 h-100"
                        />
                      </div>
                      <div
                        className=" px-2 my-1 overflow-hidden rounded bg-transparent  w-100 position-relative"
                        style={{ zIndex: "9" }}
                        key={index}
                        onClick={() => handleShowMedia(mediaItem)}
                      >
                        <img
                          src={mediaItem?.url}
                          alt=""
                          className="rounded img-fluid"
                        />
                      </div>
                    </>
                  )}
                  <>
                    {mediaItem?.type === "video" && (
                      <ReactPlayer
                        url={mediaItem?.url}
                        width={"100%"}
                        controls
                        volume={true}
                        onClick={() => handleShowMedia(mediaItem)}
                      />
                    )}
                  </>
                  <>
                    {mediaItem?.type === "audio" && (
                      <audio src={mediaItem?.url} controls />
                    )}
                  </>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      
    </>
  );
};

export default PostViewer;
