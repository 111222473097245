import React from "react";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import Fade from "react-reveal";
import TableFilter from "./Component/TableFilter";
import User from "../../../images/user.png";
import DummyImg from "../../../images/dummy.jpg";
import moment from "moment";
import TextTruncation from "../../../elements/TextTrancation";
import { Player, ControlBar } from "video-react";
import ReactPlayer from "react-player";
import PostViewer from "../../Common/PostViewer/PostViewer";

const PostDetailIndex = (props) => {
  const {
    data,
    search,
    setSearch,
    allLikesList,
    allCommentsList,
    allShareList,
    allTipsList,
  } = props;

  console.log(data, "data here");

  return (
    <Fade>
      <section className="position-relative postDetailWrpp py-3">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="px-lg-5 px-md-3">
                <div className="sectionInner h-100 position-relative">
                  <TableFilter />
                  <div className="PostDetail p-3 p-lg-4">
                    <Row className="PostDetailMain align-items-start">
                      <Col lg="4" className="my-2 sticky-top">
                        <div className="postMediaMain">
                          <div className="postMediaMaim bg-light p-2 mb-2 rounded-1">
                          <div className="NewsFeed ">
                            <PostViewer media={data?.media} />
                          </div>
                          </div>
                          <div className="ProfileContent">
                            <div className="d-flex align-items-center gap-10 profile mb-3">
                              <div className="imgWrp">
                                <img
                                  src={
                                    data?.userDetails?.profileImage
                                      ? data?.userDetails?.profileImage
                                      : User
                                  }
                                  alt=""
                                  className="img-fluid rounded-circle"
                                />
                              </div>
                              <div className="content">
                                <h6 className="m-0 fw-sbold">
                                  {data?.userDetails?.name} <br />
                                  <span>{data?.userDetails?.userName}</span>
                                </h6>
                              </div>
                            </div>
                            <div className="Content border-bottom pb-2">
                              <h6 className="m-0 py-2">Post Description</h6>
                              <p className="m-0">
                                <TextTruncation
                                  text={data?.caption || data?.description}
                                  maxLength={100}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="8" className="my-2">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="TotalLikes"
                        >
                          <Nav variant="pills" className="">
                            <Nav.Item className="w-25 px-2">
                              <Nav.Link
                                className="bg-transparent text-center p-0"
                                eventKey="TotalLikes"
                              >
                                <p className="m-0 pb-2">Total Likes</p>
                                <div className="d-flex align-items-center justify-content-center position-relative">
                                  <span className="icn position-absolute">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_2611)">
                                        <path
                                          d="M3.69737 0.849609C2.7628 0.966797 1.98057 1.35059 1.31846 2.01855C0.723734 2.61621 0.366312 3.24902 0.161234 4.07227C-0.202047 5.49902 0.061625 6.90527 0.934672 8.22656C1.49424 9.07324 2.21788 9.84961 3.54502 11.0332C4.38877 11.7861 6.96397 13.9834 7.08995 14.0566C7.20713 14.127 7.25108 14.1357 7.5001 14.1357C7.74913 14.1357 7.79307 14.127 7.91026 14.0566C8.03623 13.9834 10.6173 11.7832 11.4552 11.0332C12.7882 9.84375 13.506 9.07031 14.0655 8.22656C14.9386 6.90527 15.2022 5.49902 14.839 4.07227C14.6339 3.24902 14.2765 2.61621 13.6817 2.01855C13.0899 1.42383 12.4571 1.08105 11.628 0.905273C11.212 0.817383 10.4444 0.808594 10.0812 0.890625C9.06748 1.11035 8.29112 1.61719 7.58213 2.51953L7.5001 2.62207L7.421 2.51953C6.7208 1.63477 5.95616 1.12793 4.98057 0.905273C4.69053 0.837891 4.02256 0.808594 3.69737 0.849609ZM4.67588 1.74316C5.50791 1.88965 6.22569 2.37012 6.79405 3.16406C6.89073 3.30176 7.01084 3.46582 7.05772 3.5332C7.27452 3.83789 7.72569 3.83789 7.94248 3.5332C7.98936 3.46582 8.10948 3.30176 8.20616 3.16406C8.98252 2.08008 10.0636 1.57031 11.2413 1.73145C12.548 1.91016 13.5675 2.82422 13.9571 4.16895C14.2647 5.23242 14.13 6.34863 13.5704 7.35352C13.1017 8.19434 12.3194 9.07617 10.9571 10.2979C10.298 10.8867 7.52647 13.2715 7.5001 13.2715C7.4708 13.2715 4.71104 10.8955 4.04307 10.2979C1.94834 8.41992 1.12217 7.24805 0.922953 5.87402C0.794047 4.98633 0.978617 4.03125 1.42686 3.27832C2.10069 2.14453 3.41319 1.52344 4.67588 1.74316Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2611">
                                          <rect
                                            width="15"
                                            height="15"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                  {allLikesList?.length}
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="w-25 px-2">
                              <Nav.Link
                                className="bg-transparent text-center p-0"
                                eventKey="TotalComments"
                              >
                                <p className="m-0 pb-2">Total Comments</p>
                                <div className="d-flex align-items-center justify-content-center position-relative">
                                  <span className="icn position-absolute">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                    >
                                      <path
                                        d="M3.82324 1.78711C2.54883 1.88379 1.38867 2.55762 0.688477 3.61816C0.404297 4.04883 0.234375 4.44433 0.09375 5.00976L0.0175781 5.31738L0.00878906 9.26367C0.00292969 11.4375 0.00585938 13.2129 0.0175781 13.2129C0.0263672 13.2129 0.972656 12.7764 2.11816 12.2461L4.19824 11.2793H7.59082C9.73242 11.2793 11.0918 11.2676 11.2822 11.25C12.7002 11.1064 13.9248 10.2598 14.5693 8.97949C14.707 8.70117 14.8359 8.3291 14.918 7.96582C14.9824 7.67578 14.9854 7.6084 14.9854 6.51855C14.9854 5.25 14.9678 5.10644 14.7627 4.52051C14.335 3.31055 13.3477 2.35547 12.1289 1.96875C11.4932 1.7666 11.6777 1.77539 7.70508 1.76953C5.73047 1.7666 3.98438 1.77539 3.82324 1.78711ZM11.4316 2.73926C12.2256 2.87988 12.958 3.33105 13.4502 3.98144C13.7637 4.40039 14.001 5.00391 14.0596 5.5459C14.1006 5.90918 14.1006 7.12793 14.0596 7.49121C13.9131 8.80078 13.0049 9.86719 11.7129 10.2451L11.4404 10.3271L7.73438 10.3359L4.03125 10.3447L2.4873 11.0596C1.6377 11.4551 0.9375 11.7773 0.925781 11.7773C0.893555 11.7773 0.905273 5.84766 0.94043 5.5459C1.08984 4.20703 2.05664 3.10547 3.36328 2.7832C3.48633 2.75391 3.62988 2.72168 3.67676 2.71582C3.72656 2.70996 5.44043 2.70117 7.48535 2.70117C10.5322 2.69824 11.2471 2.7041 11.4316 2.73926Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M4.12199 5.87109C3.93156 5.9414 3.74699 6.1084 3.63859 6.31347C3.57121 6.43652 3.55949 6.49511 3.56242 6.69433C3.56242 6.88769 3.57707 6.95508 3.64152 7.07812C3.93449 7.64941 4.71964 7.72558 5.10343 7.22168C5.38175 6.85254 5.33488 6.33984 4.9921 6.03808C4.91886 5.97363 4.79875 5.90039 4.7255 5.87695C4.56144 5.82129 4.27433 5.81836 4.12199 5.87109Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M7.18652 5.87988C7.00781 5.94141 6.78809 6.14941 6.70605 6.33398C6.65625 6.44824 6.63867 6.54492 6.63574 6.69434C6.63574 7.03711 6.80273 7.30664 7.12793 7.47656C7.22754 7.53223 7.29785 7.54395 7.5 7.54395C7.71387 7.54395 7.76953 7.53223 7.89844 7.46484C8.31445 7.24512 8.48438 6.73828 8.28223 6.31934C8.18262 6.12305 7.98633 5.93848 7.80176 5.87695C7.63477 5.81836 7.35059 5.82129 7.18652 5.87988Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M10.2628 5.87695C10.087 5.94141 9.89074 6.12891 9.79406 6.32227C9.63586 6.64746 9.71496 7.09277 9.97863 7.32129C10.1779 7.49707 10.3038 7.54395 10.5763 7.54395C10.7902 7.54395 10.8458 7.53223 10.9718 7.46484C11.2853 7.30078 11.4405 7.03711 11.4376 6.67969C11.4376 6.50098 11.423 6.43066 11.3585 6.31055C11.2648 6.12305 11.0568 5.93848 10.878 5.87695C10.7111 5.81836 10.4269 5.82129 10.2628 5.87695Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                  {allCommentsList?.length || 0}
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="w-25 px-2">
                              <Nav.Link
                                className="bg-transparent text-center p-0"
                                eventKey="TotalTips"
                              >
                                <p className="m-0 pb-2">Total Tips</p>
                                <div className="d-flex align-items-center justify-content-center position-relative">
                                  <span className="icn position-absolute">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M12.6451 11.3516C12.6451 8.94791 10.6764 8.67396 9.0946 8.45416C7.37064 8.21458 6.39512 8.00625 6.39512 6.52187C6.39512 5.27604 7.70085 4.83333 8.81908 4.83333C9.37634 4.81533 9.9299 4.93005 10.4341 5.16803C10.9383 5.40601 11.3788 5.76043 11.7191 6.20208L12.5295 5.54791C12.165 5.07925 11.7116 4.68724 11.1951 4.39443C10.6786 4.10161 10.1094 3.91376 9.52012 3.84166V2.22916H8.47845V3.80312C6.59564 3.91771 5.35345 4.98021 5.35345 6.52187C5.35345 8.98541 7.34824 9.26302 8.95085 9.48541C10.6451 9.72135 11.6035 9.92396 11.6035 11.3516C11.6035 12.9307 9.97168 13.1667 8.99928 13.1667C7.21283 13.1667 6.45866 12.6646 5.75866 11.7979L4.94824 12.4521C5.36114 12.9962 5.89517 13.4366 6.50797 13.7383C7.12076 14.0399 7.79543 14.1947 8.47845 14.1901V15.7708H9.52012V14.1849C11.4607 14.0266 12.6451 12.9729 12.6451 11.3516Z"
                                        fill="#111111"
                                      />
                                    </svg>
                                  </span>
                                  {allTipsList?.length}
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="w-25 px-2">
                              <Nav.Link
                                className="bg-transparent text-center p-0"
                                eventKey="TotalShare"
                              >
                                <p className="m-0 pb-2">Total Shares</p>
                                <div className="d-flex align-items-center justify-content-center position-relative">
                                  <span className="icn position-absolute">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_2622)">
                                        <path
                                          d="M7.65823 2.05079C3.3633 3.33985 0.799827 4.125 0.706077 4.17774C0.509788 4.28321 0.234397 4.57911 0.137717 4.78125C-0.184548 5.46973 0.0967019 6.27833 0.77346 6.60352C0.914085 6.6709 1.91604 6.97266 3.82619 7.51465L6.67385 8.32618L7.48537 11.1738C8.02151 13.0576 8.32912 14.0889 8.39358 14.2236C8.7217 14.9004 9.53323 15.1846 10.2188 14.8623C10.4326 14.7627 10.7227 14.4844 10.8311 14.2764C10.9307 14.0889 15 0.568363 15 0.427738C15 0.210941 14.7744 -0.00292587 14.5547 0.0029335C14.502 0.00586319 11.3965 0.925785 7.65823 2.05079ZM9.94338 4.42676L6.89651 7.47364L4.05471 6.66211C2.49319 6.2168 1.18069 5.83301 1.13674 5.8125C1.00783 5.74512 0.899436 5.58106 0.887717 5.42579C0.873069 5.26758 0.96096 5.0625 1.08401 4.97461C1.1426 4.93067 12.8496 1.38868 12.9639 1.37989C12.9785 1.37696 11.6221 2.74805 9.94338 4.42676ZM11.8506 7.93946C10.878 11.1856 10.0547 13.875 10.0254 13.916C9.9551 14.0156 9.7383 14.1211 9.6094 14.1211C9.48635 14.1211 9.30178 14.0215 9.21975 13.9131C9.18752 13.8662 8.8301 12.665 8.34377 10.9688L7.52639 8.10352L10.5645 5.06543C12.2373 3.39258 13.6084 2.02735 13.6143 2.03028C13.6172 2.03614 12.8233 4.69336 11.8506 7.93946Z"
                                          fill="black"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2622">
                                          <rect
                                            width="15"
                                            height="15"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                  {allShareList?.length}
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content className="pt-4 px-2">
                            <Tab.Pane eventKey="TotalLikes">
                              <Form className="searchForm mb-3">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="form-control"
                                  value={search.like}
                                  onChange={(e) =>
                                    setSearch((rest) => ({
                                      ...rest,
                                      like: e.target.value,
                                    }))
                                  }
                                />
                              </Form>
                              <ul className="list-unstyled ps-0 mb-0 userList">
                                {allLikesList.length != 0 &&
                                  allLikesList?.map((item, index) => {
                                    return (
                                      <li
                                        className="py-3 d-flex align-items-center justify-content-between gap-10"
                                        key={index}
                                      >
                                        <div className="profile d-flex align-items-center gap-10">
                                          <div className="imgWrp">
                                            <img
                                              src={
                                                item?.userDetails?.profileImage
                                                  ? item?.userDetails
                                                      ?.profileImage
                                                  : User
                                              }
                                              alt=""
                                              className="img-fluid rounded-circle"
                                            />
                                          </div>
                                          <div className="content">
                                            <h6 className="m-0 fw-sbold">
                                              {item?.userDetails?.name}
                                            </h6>
                                            <p className="m-0">
                                              {item?.userDetails?.userName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="right">
                                          <p className="m-0 fw-sbold">
                                            {moment(data?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="TotalComments">
                              <Form className="searchForm mb-3">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="form-control"
                                  value={search.comment}
                                  onChange={(e) =>
                                    setSearch((rest) => ({
                                      ...rest,
                                      comment: e.target.value,
                                    }))
                                  }
                                />
                              </Form>
                              <ul className="list-unstyled ps-0 mb-0 userList">
                                {allCommentsList.length != 0 &&
                                  allCommentsList?.map((item, index) => {
                                    return (
                                      <li
                                        className="py-3 d-flex align-items-center justify-content-between gap-10"
                                        key={index}
                                      >
                                        <div className="profile d-flex align-items-center gap-10">
                                          <div className="imgWrp">
                                            <img
                                              src={
                                                item?.userDetails?.profileImage
                                                  ? item?.userDetails
                                                      ?.profileImage
                                                  : User
                                              }
                                              alt=""
                                              className="img-fluid rounded-circle"
                                            />
                                          </div>
                                          <div className="content">
                                            <h6 className="m-0 fw-sbold">
                                              {item?.userDetails?.name}
                                            </h6>
                                            <p className="m-0">
                                              {item?.userDetails?.userName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="right">
                                          <p className="m-0 fw-sbold">
                                            {moment(data?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="TotalTips">
                              <Form className="searchForm mb-3">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="form-control"
                                  value={search.tip}
                                  onChange={(e) =>
                                    setSearch((rest) => ({
                                      ...rest,
                                      tip: e.target.value,
                                    }))
                                  }
                                />
                              </Form>
                              <ul className="list-unstyled ps-0 mb-0 userList">
                                {allTipsList.length != 0 &&
                                  allTipsList?.map((item, index) => {
                                    return (
                                      <li
                                        className="py-3 d-flex align-items-center justify-content-between gap-10"
                                        key={index}
                                      >
                                        <div className="profile d-flex align-items-center gap-10">
                                          <div className="imgWrp">
                                            <img
                                              src={
                                                item?.userDetails?.profileImage
                                                  ? item?.userDetails
                                                      ?.profileImage
                                                  : User
                                              }
                                              alt=""
                                              className="img-fluid rounded-circle"
                                            />
                                          </div>
                                          <div className="content">
                                            <h6 className="m-0 fw-sbold">
                                              {item?.userDetails?.name}
                                            </h6>
                                            <p className="m-0">
                                              {item?.userDetails?.userName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="right">
                                          <p className="m-0 fw-sbold">
                                            {moment(data?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="TotalShare">
                              <Form className="searchForm mb-3">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="form-control"
                                  value={search.share}
                                  onChange={(e) =>
                                    setSearch((rest) => ({
                                      ...rest,
                                      share: e.target.value,
                                    }))
                                  }
                                />
                              </Form>
                              <ul className="list-unstyled ps-0 mb-0 userList">
                                {allShareList.length != 0 &&
                                  allShareList?.map((item, index) => {
                                    return (
                                      <li
                                        className="py-3 d-flex align-items-center justify-content-between gap-10"
                                        key={index}
                                      >
                                        <div className="profile d-flex align-items-center gap-10">
                                          <div className="imgWrp">
                                            <img
                                              src={
                                                item?.userDetails?.profileImage
                                                  ? item?.userDetails
                                                      ?.profileImage
                                                  : User
                                              }
                                              alt=""
                                              className="img-fluid rounded-circle"
                                            />
                                          </div>
                                          <div className="content">
                                            <h6 className="m-0 fw-sbold">
                                              {item?.userDetails?.name}
                                            </h6>
                                            <p className="m-0">
                                              {item?.userDetails?.userName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="right">
                                          <p className="m-0 fw-sbold">
                                            {moment(data?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default PostDetailIndex;
