import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {
  allSubAdmin,
  addSubAdmin,
  updateSubAdmin,
  deleteSubAdmin,
  subAdminDetails,
} from "./thunk";

const initialState = {
  allSubAdmin: [],
  status: status.IDLE,
  showLoader: false,
  selectedSubAdmin: {},
  isUpdate: false,
  showButtonLoader: false,
  subAdminDetailsdta: {},
};

const slice = createSlice({
  name: "subAdmin",
  initialState: { ...initialState },
  reducers: {
    removeAllSubAdmin: (proxy, action) => {
      return initialState;
    },
    selectSubAdmin: (state, action) => {
      state.selectedSubAdmin = action.payload;
      state.isUpdate = true;
    },
    removeSelectedSubAdmin: (state, action) => {
      state.selectedSubAdmin = {};
      state.isUpdate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allSubAdmin.pending, (state, action) => {
      state.status = status.LOADING;
      state.showLoader = true;
    });
    builder.addCase(allSubAdmin.fulfilled, (state, action) => {
      state.allSubAdmin = action.payload.data;
      state.status = status.SUCCEDED;
      state.showLoader = false;
    });
    builder.addCase(allSubAdmin.rejected, (state, action) => {
      state.allSubAdmin = initialState.allSubAdmin;
      state.status = status.FAILED;
      state.showLoader = false;
    });

    builder.addCase(addSubAdmin.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(addSubAdmin.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
    });
    builder.addCase(addSubAdmin.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
    });

    builder.addCase(updateSubAdmin.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(updateSubAdmin.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.isUpdate = false;
    });
    builder.addCase(updateSubAdmin.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.selectedSubAdmin = initialState.selectedSubAdmin;
      state.isUpdate = false;
    });

    builder.addCase(deleteSubAdmin.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
      state.selectedSubAdmin = initialState.selectedSubAdmin;
    });
    builder.addCase(deleteSubAdmin.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.selectedSubAdmin = initialState.selectedSubAdmin;
    });
    builder.addCase(deleteSubAdmin.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.selectedSubAdmin = initialState.selectedSubAdmin;
    });

    builder.addCase(subAdminDetails.pending, (state, action) => {
      state.status = status.LOADING;
      state.showButtonLoader = true;
    });
    builder.addCase(subAdminDetails.fulfilled, (state, action) => {
      state.status = status.SUCCEDED;
      state.showButtonLoader = false;
      state.subAdminDetailsdta = action.payload.data;
    });
    builder.addCase(subAdminDetails.rejected, (state, action) => {
      state.status = status.FAILED;
      state.showButtonLoader = false;
      state.subAdminDetailsdta = initialState.subAdminDetailsdta;
    });
  },
});

export default slice.reducer;
export const { removeAllSubAdmin, selectSubAdmin, removeSelectedSubAdmin } =
  slice.actions;
