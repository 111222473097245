import React , {useState , useEffect} from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Ticketsindex from "../../../components/SideTab/Tickets";
import { useDispatch } from "react-redux";
import { allTickets } from "../../../redux/states/tickets/thunk";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const Tickets = () => {

  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage, setTotalPage] = useState(1);

  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: q.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: q.get("page") + 1 });
    }
  };

  const getTicketList = (search, pageNo, limit) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
    };

    dispatch(allTickets(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getTicketList(filter.search, filter.page, filter.limit);
  }, [filter]);
  return (
    <>
      {/* <NonAuthLayout> */}
         <Ticketsindex 
          filter={filter}
          totalPage={totalPage}
          setFilter={setFilter}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
          getTicketList={getTicketList}/>
      {/* </NonAuthLayout> */}
    </>
  );
};

export default Tickets;
