import React, { useEffect, useState } from "react";
import NonAuthLayout from "../../../layout/NonAuthLayout";
import Trasactionsindex from "../../../components/SideTab/Trasactions";
import { useDispatch } from "react-redux";
import { allTransactions } from "../../../redux/states/transactions/thunk";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import CategoryIndex from "../../../components/SideTab/Products/CategoryIndex";
import { allCategory } from "../../../redux/actions";

const ProductCategory = (props) => {
  const dispatch = useDispatch();
  const [q, setQ] = useSearchParams();
  const [totalPage, setTotalPage] = useState(1);

  const filter = {
    search: q.get("search") || "",
    page: q.get("page") || 1,
    limit: q.get("limit") || 10,
  };

  const setFilter = (args) => {
    const params = {
      ...filter,
      ...args,
    };
    setQ({
      limit: params.limit || 10,
      orderBy: "createdAt",
      order: -1,
      page: params.page || 1,
      search: params.search || "",
    });
  };

  const handlePreviousClick = () => {
    if (filter.page > 1) {
      setFilter({ page: q.get("page") - 1 });
    }
  };

  const handleNextClick = () => {
    if (filter.page < filter.totalPage) {
      setFilter({ page: q.get("page") + 1 });
    }
  };

  const getCategoryList = (search, pageNo, limit) => {
    const data = {
      limit: limit,
      orderBy: "createdAt",
      order: -1,
      page: pageNo,
      search: search,
    };

    dispatch(allCategory(data))
      .then((response) => {
        const totalCount = response?.payload?.totalcount;
        if (totalCount > 10) {
          const totalpage = totalCount / filter.limit;
          setTotalPage(Math.ceil(totalpage));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getCategoryList(filter.search, filter.page, filter.limit);
  }, [filter]);

  useEffect(() => {
    setQ({ search: filter.search, page: filter.page, limit: filter.limit });
  }, []);

  return (
    <>
      <CategoryIndex
        filter={filter}
        totalPage={totalPage}
        setFilter={setFilter}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        getCategoryList={getCategoryList}
      />
    </>
  );
};

export default ProductCategory;
