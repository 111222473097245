import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./states/auth/slice";
import usersReducer from "./states/users/slice";
import artistsReducer from "./states/artists/slice";
import announcementsReducer from "./states/announcement/slice";
import postsReducer from "./states/posts/allPosts/slice";
import selectedPostReducer from "./states/posts/selectedPost/slice";
import fanAnalyticsReducer from "./states/fanAnalytics/slice";
import transactionsReducer from "./states/transactions/slice";
import fanLoyalityReducer from "./states/fanLoyality/slice";
import subAdminReducer from "./states/subAdmin/slice";
import trophiesReducer from "./states/trophies/slice";
import productReducer from "./states/products/slice";
import commonReducer from "./states/common/slice";
import ticketReducer from "./states/tickets/slice";
import dashboardReducer from "./states/dashboard/slice";
import settingReducer from "./states/settings/slice";
import planReducer from "./states/plan/slice";
import productCategoryReducer from "./states/productCategory/slice";
import profileReducer from "./states/profile/slice";
import viewerRequestReducer from "./states/viewerRequest/slice";
import earningsReducer from "./states/earnings/slice";

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  artists: artistsReducer,
  posts: postsReducer,
  selectedPostDetails: selectedPostReducer,
  fanAnalytics: fanAnalyticsReducer,
  transactions: transactionsReducer,
  fanLoyality: fanLoyalityReducer,
  subAdmin: subAdminReducer,
  trophies: trophiesReducer,
  products: productReducer,
  common: commonReducer,
  tickets: ticketReducer,
  dashboard: dashboardReducer,
  settings: settingReducer,
  plan: planReducer,
  categories: productCategoryReducer,
  profile: profileReducer,
  viewerRequest: viewerRequestReducer,
  earnings: earningsReducer,
  announcement: announcementsReducer,
});

export default rootReducer;
