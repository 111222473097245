import React from "react";
import { Form, Pagination, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import User from "../../../../images/user.png";
import CustomPagination from "../../../CustomPagination";

const TableData = (props) => {
  const tableData = useSelector((state) => state.fanLoyality)?.fanLoyalityList;
  const { filter, setFilter, totalPage } = props;
  const {
    handleNextClick,
    handlePreviousClick,
    approveLoyality,
    declineLoyality,
  } = props;

  const tableDatas = [
    {
      DOB: "15/02/1996",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      DOB: "15/02/1996",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      DOB: "15/02/1996",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
    {
      DOB: "15/02/1996",
      reels:
        "https://image.shutterstock.com/image-photo/full-hd-image-ladybird-on-260nw-1952398060.jpg",
      UserImg:
        "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_1280.jpg",
      UserName: "Jane Cooper",
      UserEmail: "suffescom@gmail.com",
      Date: "June 1, 2020, 08:22 AM",
    },
  ];
  return (
    <>
      <div className="CommonTable">
        <div className="table-responsive">
          <table className="table">
            <thead className="">
              <tr>
                <th className="border-0">S.No.</th>
                <th className="border-0">Fan Details</th>
                <th className="border-0">DOB</th>
                <th className="border-0">
                  <Button variant="transparent" className="border-0 p-0">
                    Points
                    <span className="ms-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="9"
                        viewBox="0 0 6 9"
                        fill="none"
                      >
                        <path d="M3 0L0 4H6L3 0Z" fill="#505470" />
                        <path d="M3 9L6 5L1.27146e-07 5L3 9Z" fill="#505470" />
                      </svg>
                    </span>
                  </Button>
                </th>
                <th className="border-0">Date</th>
                <th className="border-0">Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((data) => {
                const userDetails = data?.userDetails;
                return (
                  <tr>
                    <td className="border-0">
                      <span className="fw-sbold text-dark">
                        {tableData.indexOf(data) + 1}
                      </span>
                    </td>
                    <td className="border-0">
                      <div className="d-flex align-items-center gap-10 tableProfile">
                        <div className="imgWrp">
                          <img
                            src={
                              userDetails?.profileImage
                                ? userDetails?.profileImage
                                : User
                            }
                            alt=""
                            className="img-fluid rounded-circle"
                          />
                        </div>
                        <div className="content">
                          <h6 className="m-0 fw-bold">
                            {userDetails?.firstName +
                              " " +
                              userDetails?.lastName}
                          </h6>
                          <p className="m-0">{userDetails?.userName}</p>
                        </div>
                      </div>
                    </td>
                    <td className="border-0 ws-normal">{data.DOB}</td>
                    <td className="border-0">
                      <span className="fw-bold text-dark">
                        # {data?.points}
                      </span>
                    </td>

                    <td className="border-0">
                      {moment(data?.createdAt).format("MM/DD/YYYY")}
                    </td>
                    <td
                      className="border-0 px-0"
                      style={{ marginLeft: "-5px" }}
                    >
                      <div className="d-flex align-items-center justify-content-center gap-10 ActnBtn">
                        {data?.status === "pending" ? (
                          <>
                            <Button
                              className="border-0 p-0 successBtn w-100"
                              onClick={() => approveLoyality(data)}
                            >
                              Approve
                            </Button>
                            <Button
                              className="border-0 p-0 dangerBtn w-100"
                              onClick={() => declineLoyality(data)}
                            >
                              Decline
                            </Button>
                          </>
                        ) : (
                          <>
                            {data.status === "approved" && (
                              <h6 className="bg-success text-light p-1 text-capitalize">
                                {data?.status}
                              </h6>
                            )}
                            {data?.status === "declined" && (
                              <h6 className="bg-success text-light p-1 text-capitalize">
                                {data?.status}
                              </h6>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap paginationWrpper mt-2 bg-white pt-3">
          <div className="left">
            <div className="showResult d-flex align-items-center gap-10">
              <label htmlFor="" className="form-label m-0">
                Show Result:
              </label>
              <Form.Select
                aria-label="Default select example"
                value={filter.limit}
                onChange={(e) => setFilter({ limit: e.target.value })}
              >
                {[10, 20, 30, 50]?.map((itm) => (
                  <option value={itm}>{itm}</option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="right ">
            <Pagination>
              <Pagination.Prev onClick={handlePreviousClick} />
              {Array.from(Array(totalPage).keys())?.map((page, index) => {
                var pageNo = page + 1;
                return (
                  <Pagination.Item
                    active={pageNo == filter.page}
                    onClick={() => setFilter({ page: pageNo })}
                  >
                    {pageNo}
                  </Pagination.Item>
                );
              })}

              <Pagination.Next onClick={handleNextClick} />
            </Pagination>
          </div>
        </div> */}

        <CustomPagination
          totalPage={totalPage}
          setFilter={setFilter}
          filter={filter}
        />
      </div>
    </>
  );
};

export default TableData;
