import React, { useState } from "react";
import { Col, Container, Row, Button, Form, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import Productcategory from "./Productcategory";

const Productindex = (props) => {
  return (
    <section className="Prodcut-section py-3">
      <Container fluid>
        <Productcategory {...props} />
      </Container>
    </section>
  );
};

export default Productindex;
