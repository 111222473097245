import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'
import { endpoints } from "../../../endpoints";


export const allTransactions = createAsyncThunk("allTransaction/getList" , async (payload , Thunk) =>{
    try {
        const response = await axios.post(endpoints.transactions.getAllTranctions , payload)
        return response.data;
    }
    catch(err){
        return Thunk.rejectWithValue(err)
    }
})

