import { createSlice } from "@reduxjs/toolkit";
import status from "../../constants/status";
import {fanLoyalityList , approveFanLoyality ,  declineFanLoyality } from "./thunk";


const initialState = {
    fanLoyalityList : [] ,
    status : status.IDLE,
    showLoader : false ,
}

const slice = createSlice({
    name : "users",
    initialState : {...initialState},
    reducers : {
        removeAllFanLoyality : (proxy , action) =>{
            return initialState
        }
    } ,
    extraReducers : (builder) =>{
        builder.addCase(fanLoyalityList.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(fanLoyalityList.fulfilled , (state,action) =>{
            state.fanLoyalityList = action.payload.data;
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(fanLoyalityList.rejected , (state , action) =>{
            state.fanLoyalityList = initialState.fanLoyalityList;
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(approveFanLoyality.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(approveFanLoyality.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(approveFanLoyality.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false;
        })

        builder.addCase(declineFanLoyality.pending , (state ,action) =>{
            state.status = status.LOADING;
            state.showLoader = true ;
        });
        builder.addCase(declineFanLoyality.fulfilled , (state,action) =>{
            state.status = status.SUCCEDED;
            state.showLoader = false;
        })
        builder.addCase(declineFanLoyality.rejected , (state , action) =>{
            state.status = status.FAILED;
            state.showLoader = false;
        })
    }
});

export default slice.reducer;
export const {removeAllFanLoyality} = slice.actions;